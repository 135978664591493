import React from "react"
import {
    showNotification,
    showWaiting,
    hideWaiting,
    mapErrors
} from "../../../util/Constants"
import Print from "@material-ui/icons/Print"
import logradouroStyle from "../../../assets/css/layout/logradouroStyle"
import {withStyles} from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "../../../components/CustomButtons/Button"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import {getFields} from "../../../util/imovel"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Grid from "@material-ui/core/Grid"
import AdBlockDetect from "react-ad-block-detect"
import {getRelatorio, getResumoLancamentoIPTU, getRelatorioPercentualValor} from "../../../util/carnelancado"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import {edit, search as searchImovel} from "../../../util/imovel"
import {search as searchContribuinte} from "../../../util/contribuinte"
import AsyncSelect from "../../../components/AsyncSelect"
import InputMask from "react-input-mask"
import MenuItem from "@material-ui/core/MenuItem";
class CarneLancadoRelatorio extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {},
            ano: "",
          posicao_fiscal_id:"",
            ano_quantitativo: "",
            group: "id",
            modal: false,
            modalPercentualValor: false,
            id_imovel: [],
            id_contribuinte: [],
          posicoes_fiscais:[]
        }
        this.handleChange = this.handleChange.bind(this)
        this.gerarRelatorio = this.gerarRelatorio.bind(this)
        this.loadOptions = this.loadOptions.bind(this)
        this.loadImovel = this.loadImovel.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.dialogQuantitativo = this.dialogQuantitativo.bind(this)
        this.dialogPercentualValor = this.dialogPercentualValor.bind(this)
        this.gerarQuantitativo = this.gerarQuantitativo.bind(this)
        this.gerarPercentualValor = this.gerarPercentualValor.bind(this)
    }

    componentWillMount() {
        this.getFields()
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        errors[name] = undefined
        if (name === "group") {
            this.setState({
                [name]: event.target.checked,
                errors: errors
            })
        }
        this.setState({
            [name]: event.target.value,
            errors: errors
        })
    }

    getFields() {

        let response = (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
                console.log(error);
            } else {

                this.setState({
                    posicoes_fiscais: data.posicoes_fiscais,
                })
            }
        }

        getFields(response)
    }

    loadImovel(inputValue, callback) {
        searchImovel(inputValue, "", undefined, (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
                callback([])
            } else {
                let imoveis = data.data.map(imovel => {
                    return {
                        value: imovel.id,
                        label: `${imovel.id} - ${imovel.logradouro_correspondencia ||
                        ""} - ${imovel.bairro_correspondencia ||
                        ""} - ${imovel.cidade_correspondencia ||
                        ""} - ${imovel.cep_correspondencia || ""}`
                    }
                })
                callback(imoveis)
            }
        })
    }

    gerarRelatorio(event) {
        event.preventDefault()
        showWaiting()
        let response = (error, data) => {
            if (error) {
                console.log(error.response)
                hideWaiting()
                if (error.response.status === 422) {
                    const reader = new FileReader()
                    reader.addEventListener("loadend", e => {
                        const text = e.srcElement.result
                        let json = JSON.parse(text)
                        this.setState({
                            errors: mapErrors({response: {data: json}})
                        })
                    })
                    reader.readAsText(error.response.data)

                } else if (error.response.status === 400) {
                    const reader = new FileReader()
                    reader.addEventListener("loadend", e => {
                        const text = e.srcElement.result
                        let error = JSON.parse(text)
                        showNotification(
                            "top",
                            "center",
                            `Ocorreram erros! ${error.message}`,
                            "danger"
                        )
                    })
                    reader.readAsText(error.response.data)
                } else {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique o formulário e tente novamente",
                        "danger"
                    )
                }

            } else {
                hideWaiting()
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
      let posicaoFiscal = this.state.posicao_fiscal_id == 'selecione'?'':this.state.posicao_fiscal_id
        getRelatorio(
            this.state.ano,
            this.state.group,
            this.state.id_imovel.value,
            this.state.id_contribuinte.value,
            posicaoFiscal,
            response
        )
    }

    loadOptions(inputValue, callback) {
        searchContribuinte(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let contribuintes = data.data.map(contribuinte => {
                        return {
                            value: contribuinte.id,
                            label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                            ""} - ${contribuinte.cpf_cnpj || ""}`
                        }
                    })
                    callback(contribuintes)
                }
            }
        )
    }

    handleClose = () => {
        this.setState({modal: false, ano_quantitativo: ""})
    }

    dialogQuantitativo(event) {
        this.setState({modal: true})
    }

    gerarQuantitativo(event) {
        event.preventDefault()
        showWaiting()
        let response = (error, data) => {
            if (error) {
                console.log(error.response)
                hideWaiting()
                if (error.response.status === 422) {
                    const reader = new FileReader()
                    reader.addEventListener("loadend", e => {
                        const text = e.srcElement.result
                        let json = JSON.parse(text)
                        this.setState({
                            errors: mapErrors({response: {data: json}})
                        })
                    })
                    reader.readAsText(error.response.data)

                } else if (error.response.status === 400) {
                    const reader = new FileReader()
                    reader.addEventListener("loadend", e => {
                        const text = e.srcElement.result
                        let error = JSON.parse(text)
                        showNotification(
                            "top",
                            "center",
                            `Ocorreram erros! ${error.message}`,
                            "danger"
                        )
                    })
                    reader.readAsText(error.response.data)
                } else {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique o formulário e tente novamente",
                        "danger"
                    )
                }

            } else {
                hideWaiting()
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        getResumoLancamentoIPTU(
            this.state.ano_quantitativo,
            response
        )
    }

    handleClosePercentualValor = () => {
        this.setState({modalPercentualValor: false, ano_quantitativo: ""})
    }

    dialogPercentualValor(event) {
        this.setState({modalPercentualValor: true})
    }

    gerarPercentualValor(event) {
        event.preventDefault()
        showWaiting()
        let response = (error, data) => {
            if (error) {
                console.log(error.response)
                hideWaiting()
                if (error.response.status === 422) {
                    const reader = new FileReader()
                    reader.addEventListener("loadend", e => {
                        const text = e.srcElement.result
                        let json = JSON.parse(text)
                        this.setState({
                            errors: mapErrors({response: {data: json}})
                        })
                    })
                    reader.readAsText(error.response.data)

                } else if (error.response.status === 400) {
                    const reader = new FileReader()
                    reader.addEventListener("loadend", e => {
                        const text = e.srcElement.result
                        let error = JSON.parse(text)
                        showNotification(
                            "top",
                            "center",
                            `Ocorreram erros! ${error.message}`,
                            "danger"
                        )
                    })
                    reader.readAsText(error.response.data)
                } else {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique o formulário e tente novamente",
                        "danger"
                    )
                }

            } else {
                hideWaiting()
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        getRelatorioPercentualValor(
            this.state.ano_quantitativo,
            response
        )
    }

    render() {
        const {classes} = this.props
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid spacing={24} container direction="row">
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <h2 className={classes.cardTitleWhite}>Relatórios Carnês Lançados</h2>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3}>
                                <Button onClick={e => this.dialogPercentualValor(e)} block color="info" round>
                                    <Print/> Percentual Valor
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3}>
                                <Button onClick={e => this.dialogQuantitativo(e)} block color="info" round>
                                    <Print/> Resumo Lançamento de IPTU
                                </Button>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <InputMask
                                    mask="9999"
                                    value={this.state.ano}
                                    onChange={this.handleChange}
                                >
                                    {() => (
                                        <TextField
                                            id="outlined-name"
                                            label="Ano"
                                            name="ano"
                                            helperText={this.state.errors.ano}
                                            fullWidth
                                            error={this.state.errors.ano}
                                            value={this.state.ano}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            variant="outlined"
                                        />
                                    )}
                                </InputMask>
                            </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <TextField
                                id="outlined-name"
                                label="Posição Fiscal"
                                select
                                name="posicao_fiscal_id"
                                helperText={this.state.errors.posicao_fiscal_id}
                                fullWidth
                                error={this.state.errors.posicao_fiscal_id}
                                value={this.state.posicao_fiscal_id}
                                onChange={this.handleChange}
                                margin="normal"
                                variant="outlined"
                            >

                              <MenuItem key={'selecione'} value={'selecione'}>

                              </MenuItem>
                              {this.state.posicoes_fiscais.map((option, key) => (
                                  <MenuItem key={key} value={option.id}>
                                    {option.nome || "Nome não cadastrado"}
                                  </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                        <Grid container direction='row'>

                        </Grid>
                        <Grid container direction="row" style={{marginTop: "2%"}}>
                            <Divider/>
                            <Typography
                                className={classes.dividerFullWidth}
                                color="textSecondary"
                                variant="caption"
                            >
                                Filtrar relatório por Imóveis ou deixe vazio para a opção de
                                TODOS
                            </Typography>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <AsyncSelect
                                    classStyle={`form-control form-control-alternative form-select Select-menu-outer ${
                                        this.props.error ? "has-danger" : ""
                                    }`}
                                    style={{position: "relative"}}
                                    value={this.state.id_imovel}
                                    onChange={this.handleChange}
                                    loadOptions={this.loadImovel}
                                    name="id_imovel"
                                    placeholder="Imóvel"
                                    message="Pesquise o imóvel"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <AsyncSelect
                                    classStyle={`form-control form-control-alternative form-select Select-menu-outer ${
                                        this.props.error ? "has-danger" : ""
                                    }`}
                                    style={{position: "relative"}}
                                    value={this.state.id_contribuinte}
                                    onChange={this.handleChange}
                                    loadOptions={this.loadOptions}
                                    name="id_contribuinte"
                                    placeholder="Contribuinte"
                                    message="Pesquise o contribuinte"
                                />
                            </Grid>
                        </Grid>

                        <Grid container direction="row" style={{marginTop: "2%"}}>
                            <Divider/>
                            <Typography
                                className={classes.dividerFullWidth}
                                color="textSecondary"
                                variant="caption"
                            >
                                Ordernar por
                            </Typography>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <RadioGroup
                                    aria-label="position"
                                    name="group"
                                    value={this.state.group}
                                    onChange={this.handleChange}
                                    row
                                >
                                    <FormControlLabel
                                        value="id"
                                        control={<Radio color="primary"/>}
                                        label="Código Imóvel"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="inscricao_cartografica"
                                        control={<Radio color="primary"/>}
                                        label="Inscrição Cartográfica"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="nome_razao_social"
                                        control={<Radio color="primary"/>}
                                        label="Contribuinte"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="valor_iptu"
                                        control={<Radio color="primary"/>}
                                        label="Valor Lançado"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="valor_venal"
                                        control={<Radio color="primary"/>}
                                        label="Valor Venal"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </CardBody>
                    <CardFooter>
                        <Grid container justify="center">
                            <Grid item lg={2}>
                                <Button block color="info" round onClick={this.gerarRelatorio}>
                                    Gerar
                                </Button>
                            </Grid>
                        </Grid>
                    </CardFooter>
                </Card>
                {/* MODAL */}

                <AdBlockDetect>
                    <Dialog open={true} aria-labelledby="form-dialog-title">
                        <form onSubmit={this.handleSave}>
                            <DialogContent>
                                <Grid
                                    container
                                    justify="center"
                                    alignItems="center"
                                    direction="row"
                                >
                                    <Grid item xs={12} sm={12} md={8} lg={8}>
                                        <Typography variant="h5" gutterBottom>
                                            Por favor, desabilite o ADBlock para que o relatório seja
                                            emitido.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </form>
                    </Dialog>
                </AdBlockDetect>

                <Dialog
                    open={this.state.modal}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    style={{zIndex: "902"}}
                >
                    <form onSubmit={this.gerarQuantitativo}>
                        <DialogTitle id="form-dialog-title">Resumo do Lançamento de IPTU</DialogTitle>
                        <DialogContent>

                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <InputMask
                                        mask="9999"
                                        value={this.state.ano_quantitativo}
                                        onChange={this.handleChange}
                                    >
                                        {() => (
                                            <TextField
                                                id="date"
                                                label="Ano"
                                                margin="normal"
                                                variant="outlined"
                                                required
                                                value={this.state.ano_quantitativo}
                                                fullWidth
                                                helperText={this.state.errors.ano}
                                                error={this.state.errors.ano}
                                                name="ano_quantitativo"
                                                onChange={this.handleChange}
                                            />
                                        )}
                                    </InputMask>

                                </Grid>
                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="danger">
                                Voltar
                            </Button>
                            <Button type="submit" color="info">
                                Gerar
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    open={this.state.modalPercentualValor}
                    onClose={this.handleClosePercentualValor}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    style={{zIndex: "902"}}
                >
                    <form onSubmit={this.gerarPercentualValor}>
                    {console.log("EVENT: ", this)}
                        <DialogTitle id="form-dialog-title">Carnês Percentual valor</DialogTitle>
                        <DialogContent>

                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <InputMask
                                        mask="9999"
                                        value={this.state.ano_quantitativo}
                                        onChange={this.handleChange}
                                    >
                                        {() => (
                                            <TextField
                                                id="date"
                                                label="Ano"
                                                margin="normal"
                                                variant="outlined"
                                                required
                                                value={this.state.ano_quantitativo}
                                                fullWidth
                                                helperText={this.state.errors.ano}
                                                error={this.state.errors.ano}
                                                name="ano_quantitativo"
                                                onChange={this.handleChange}
                                            />
                                        )}
                                    </InputMask>

                                </Grid>
                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClosePercentualValor} color="danger">
                                Voltar
                            </Button>
                            <Button type="submit" color="info">
                                Gerar
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        )
    }
}

export default withStyles(logradouroStyle)(CarneLancadoRelatorio)

import React from "react";

import Grid from "@material-ui/core/Grid";
import Fade from "react-reveal/Fade";
import TextField from "@material-ui/core/TextField";
import { MenuItem, withStyles } from "@material-ui/core";

import logradouroStyle from "../../assets/css/layout/logradouroStyle";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { showNotification, mapErrors, hideWaiting, showWaiting } from "../../util/Constants";
import AsyncSelect from "../../components/AsyncSelect";
import { search } from "../../util/alvara";
import { post, getField, searchBaixa, destroy } from "../../util/baixaManualAlvara";
import SearchFields from "../../components/SearchFields";
import Typography from "@material-ui/core/Typography";
import CardFooter from "../../components/Card/CardFooter"
import moment from "moment"
import numeral from "../../util/numeral"

import DialogCustom from "../DialogCustom"
import Delete from "@material-ui/icons/Delete"
import NumberFormatCustom from "../../components/NumberFormatCustom";
const initialState = {
  alvaras: [],
  id_alvaras: [],
  errors: {},
  filterField: "",
  tipo: [],
  id_tipo: 0,
  desc_tipo: "",
  contribuinte: {},
  id_contribuinte: 0,
  dam: [],
  id_dam: 0,
  data_vencimento: new Date(),
  valor_parcela: "",
  data_deposito: new Date(),
  data_pagamento: new Date(),
  valor_pago: "",
  multa: "",
  agentes: [],
  id_agente: 0,
  agencia: '',
  conta: '',

  modal: false,
  openConfirm: false
}

class BaixaManual extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState
    
    this.loadOptions = this.loadOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeFilters = this.handleChangeFilters.bind(this);
    this.getAlvaraByid = this.getAlvaraByid.bind(this);
    this.getDamById = this.getDamById.bind(this);
    this.init = this.init.bind(this);
    this.getAgenciaConta = this.getAgenciaConta.bind(this)
    this.handleSave = this.handleSave.bind(this)

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.loadBaixa = this.loadBaixa.bind(this)
    this.handleOpenConfirm = this.handleOpenConfirm.bind(this)
    this.handleCloseConfirm = this.handleCloseConfirm.bind(this)
    
  }

  componentWillMount() {
    this.init();
  }

  loadOptions(inputValue, callback) {
    search(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          );
          callback([]);
        } else {
          let alvaras = data.data.filter(alvara => !alvara.cancelado).map(alvara => {
            return {
              value: alvara.id,
              label: `${alvara.id} - ${alvara.contribuinte.nome_razao_social ||
                ""} - ${alvara.contribuinte.cpf_cnpj || ""}`
            };
          });
          this.setState({
            alvaras: data.data
          });
          callback(alvaras);
        }
      }
    );
  }

  getAlvaraByid(id) {
    return this.state.alvaras.filter(item => {
      return id === item.id;
    })[0];
  }

  getDamById(id) {
    return this.state.dam.filter(item => {
      return id === item.id;
    })[0];
  }

  handleChangeFilters = event => {
    this.setState({ filterField: event.target.value });
  };

  init() {
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        );
      } else {
        this.setState({
          agentes: data.agentes
        });

        this.setState(data);
      }
    };
    getField(response);
  }

  getAgenciaConta(id) {
    return this.state.agentes.filter(item => {
      return id === item.id
    })[0]
  }

  handleChange(event) {
    let name = event.target.name;
    let errors = this.state.errors;
    errors[name] = undefined;

    this.setState(
      {
        [name]: event.target.value,
        errors: errors
      },
      () => {
        if (this.state.id_alvaras && name === "id_alvaras") {
          let alvara = this.getAlvaraByid(this.state.id_alvaras.value);
          this.setState({
            contribuinte: alvara.contribuinte,
            dam: alvara.dams,
            tipo: alvara.tipo,
            id_tipo: alvara.tipo_id
          });
        } else if (this.state.dam && name === "id_dam") {
          let dam = this.getDamById(this.state.id_dam);
          this.setState({
            data_vencimento: dam.vencimento,
            valor_parcela: dam.valor,
            juros: dam.juros,
            multa: dam.multa
          });
        }
      }
    );
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      dam_id: this.state.id_dam,
      agente_recebedor_id: this.state.id_agente,
      data_pagamento: moment(this.state.data_pagamento).format("YYYY-MM-DD") + "",
      data_deposito: moment(this.state.data_deposito).format("YYYY-MM-DD") + "",
      valor_pago: this.state.valor_pago
    }
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      }  else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          `Baixa de Alvará realizada com sucesso`,
          "success"
        )
        this.setState(initialState)
        this.init()
      }
    }
    post(params, response)
  }

  handleOpenConfirm() {
    this.setState({
      openConfirm: true
    })
  }

  handleCloseConfirm() {
    this.setState({
      openConfirm: false
    })
  }

  handleOpen() {
    this.setState({
      modal: true
    })
  }

  handleDelete() {
    showWaiting()
    let response = error => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
        this.setState({
          errors: mapErrors(error)
        });
      } else {
        showNotification(
          "top",
          "center",
          `Baixa Manual deletada com sucesso`,
          "success"
        );
        this.handleClose()
        this.handleCloseConfirm()
      }
    };
    destroy(this.state.codigo.value, response)
  }

  handleClose() {
    this.setState({
      modal: false
    })
  }

  loadBaixa(inputValue, callback) {
    searchBaixa(inputValue, this.state.filterFieldDialog, undefined, (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
        callback([])
      } else {
        let baixa = data.data.map(item => {
          return {
            value: item.id,
            label: `Código Baixa: ${item.dam_id} - 
            Agência: ${item.agencia} Conta: ${item.conta} - 
            Dt.Pagamento: ${moment(item.data_pagamento).format("DD/MM/YYYY")} - 
            Dt.Deposito: ${moment(item.data_deposito).format("DD/MM/YYYY")}`
          }
        })
        callback(baixa)
      }
    })
  }

  render() {
    const { classes } = this.props;
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do Alvará" },
      { value: "contribuinte_id", label: "Código do Contribuinte" },
      { value: "contribuinte_nome_fantasia", label: "Nome Fantasia" },
      { value: "contribuinte_nome_razao_social", label: "Razão Social" },
      {
        value: "dados_estabelecimento_endereco",
        label: "Endereço Estabelecimento"
      },
      { value: "dados_veiculo_numero_da_vaga", label: "Nº da Vaga" }
    ];

    const fieldsDialog = [
      { value: "", label: "Todos os campos" },
      { value: "dam_id", label: "Código Baixa" },
      { value: "data_pagamento", label: "Data Pagamento" },
      { value: "data_deposito", label: "Data Depósito" }
    ]
    return (
      <div>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={10}>
                <h2 className={classes.cardTitleWhite}>Baixa Manual</h2>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <Button onClick={this.handleOpen} block color="warning" round>
                  <Delete /> Deletar Baixa
                </Button>
              </Grid>
            </Grid>
          </CardHeader>
          <Fade bottom>
          <form onSubmit={this.handleSave}>
            <CardBody>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography color="textSecondary" variant="caption">
                    Pesquise Alvará
                  </Typography>
                  <SearchFields
                    name="filterField"
                    value={this.state.filterField}
                    onChangeFields={this.handleChangeFilters}
                    fields={fields}
                  />
                  <AsyncSelect
                  className={
                    `form-control form-control-alternative form-select Select-menu-outer ${
                      this.props.error ? "has-danger" : ""
                      }`
                  }
                    value={this.state.id_alvaras}
                    onChange={this.handleChange}
                    loadOptions={this.loadOptions}
                    name="id_alvaras"
                    placeholder="Alvará"
                    message="Pesquise o Alvará"
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    id="outlined-name"
                    label="Razão Social"
                    disabled
                    fullWidth
                    value={this.state.contribuinte.nome_razao_social}
                    defaultValue=""
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    id="outlined-name"
                    label="Nome Fantasia"
                    disabled
                    fullWidth
                    value={this.state.contribuinte.nome_fantasia}
                    defaultValue=""
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    id="outlined-name"
                    label="CPF/CNPJ/DOC"
                    disabled
                    fullWidth
                    value={this.state.contribuinte.cpf_cnpj}
                    defaultValue=""
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                    id="outlined-name"
                    label="Tipo de Alvará"
                    disabled
                    fullWidth
                    value={this.state.tipo.nome}
                    defaultValue=""
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <TextField
                    id="outlined-name"
                    label="Descrição de atividade"
                    disabled
                    fullWidth
                    value={this.state.tipo.descricao}
                    defaultValue=""
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    required
                    name="id_dam"
                    label="DAM"
                    fullWidth
                    value={this.state.id_dam}
                    error={this.state.errors.id_dam}
                    helperText={this.state.errors.id_dam}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                  >
                    {this.state.dam.map((option, key) => (
                      <MenuItem key={key} value={option.id}>
                        Competência: {option.competencia}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    id="outlined-select-currency"
                    label="Valor Parcela"
                    fullWidth
                    disabled
                    value={ "R$" + 
                      numeral(parseFloat(this.state.valor_parcela))
                      .format("0.0,00")
                      .slice(0, -2)}
                    error={this.state.errors.valor_parcela}
                    helperText={this.state.errors.valor_parcela}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    defaultValue=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    id="outlined-select-currency"
                    label="Juros"
                    fullWidth
                    disabled
                    value={ "R$" + 
                      numeral(parseFloat(this.state.juros))
                      .format("0.0,00")
                      .slice(0, -2)}
                    error={this.state.errors.juros}
                    helperText={this.state.errors.juros}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    defaultValue=""
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <TextField
                    id="outlined-select-currency"
                    label="Multa"
                    fullWidth
                    disabled
                    value={ "R$" + 
                      numeral(parseFloat(this.state.multa))
                      .format("0.0,00")
                      .slice(0, -2)}
                    error={this.state.errors.multa}
                    helperText={this.state.errors.multa}
                    onChange={this.handleChange}
                    margin="normal"
                    variant="outlined"
                    defaultValue=""
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                      id="outlined-select-currency"
                      label="Data do Vencimento"
                      fullWidth
                      disabled
                      type="date"
                      value={moment(this.state.data_vencimento).format("YYYY-MM-DD")}
                      error={this.state.errors.data_vencimento}
                      name="data_vencimento"
                      helperText={this.state.errors.data_vencimento}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                      defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                      id="outlined-select-currency"
                      label="Data do Depósito"
                      fullWidth
                      type="date"
                      name="data_deposito"
                      error={this.state.errors.data_deposito}
                      helperText={this.state.errors.data_deposito}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                      defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <TextField
                      id="outlined-select-currency"
                      label="Data do Pagamento"
                      fullWidth
                      type="date"
                      name="data_pagamento"
                      error={this.state.errors.data_pagamento}
                      helperText={this.state.errors.data_pagamento}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                      defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                      id="outlined-select-currency"
                      label="Valor Pago"
                      fullWidth
                      name="valor_pago"
                      value={this.state.valor_pago}
                      error={this.state.errors.valor_pago}
                      helperText={this.state.errors.valor_pago}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                      defaultValue=""
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom
                      }}
                    />
                  </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                      id="outlined-select-currency"
                      select
                      required
                      name="id_agente"
                      label="Agente Recebedor"
                      fullWidth
                      value={this.state.id_agente}
                      error={this.state.errors.id_agente}
                      helperText={this.state.errors.id_agente}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                    >
                      {this.state.agentes.map((option, key) => (
                        <MenuItem key={key} value={option.id}>
                          {option.nome}
                        </MenuItem>
                      ))}
                    </TextField>
                </Grid>
              </Grid>
            </CardBody>
            <CardFooter>
            <Grid container justify="center">
              <Grid item lg={2}>
                <Button block color="info" round type="submit">
                  Gerar
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
          </form>
          </Fade>
        </Card>

        <DialogCustom
          open={this.state.modal}
          nameSearch="filterFieldDialog"
          valueSearch={this.state.filterFieldDialog || ""}
          onChangeFields={this.handleChange}
          fields={fieldsDialog}
          onChange={this.handleChange}
          optionsAsync={this.loadBaixa}
          nameAsync="codigo"
          message="Pesquise a Baixa Manual Alvará"
          placeholder="Baixa Manual"
          handleClose={this.handleClose}
          isDisabled={this.state.codigo ? false : true}
          handleSave={this.handleDelete}
          handleCloseConfirm={this.handleCloseConfirm}
          handleOpenConfirm={this.handleOpenConfirm}
          openConfirm={this.state.openConfirm}
        />

      </div>
    );
  }
}

export default withStyles(logradouroStyle)(BaixaManual);

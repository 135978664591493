import React from "react"

import { Link, withRouter } from "react-router-dom"
import { withStyles, Paper } from "@material-ui/core"
import logradouroStyle from "../../../assets/css/layout/logradouroStyle"
import Button from "../../../components/CustomButtons/Button"
import ChromeReaderMode from "@material-ui/icons/ChromeReaderMode"
import TablePagination from "@material-ui/core/TablePagination"
import Print from "@material-ui/icons/Print"
import Delete from "@material-ui/icons/Delete"
import Receipt from "@material-ui/icons/Receipt"
import Dialog from "@material-ui/core/Dialog"
import Typography from "@material-ui/core/Typography"
import DialogContent from "@material-ui/core/DialogContent"
import Card from "../../../components/Card/Card"
import Lens from "@material-ui/icons/Lens"
import CardHeader from "../../../components/Card/CardHeader"
import AdBlockDetect from "react-ad-block-detect"
import CardBody from "../../../components/Card/CardBody"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import Table from "../../../components/Table/Table"
import { get, search, imprimirRefinanciamento, imprimirCarne, removeRef } from "../../../util/refinanciamento"
import Search from "../../../components/Search"
import { showNotification, showWaiting, hideWaiting } from "../../../util/Constants"
import LoadingContent from "../../../components/LoadingContent"
import housecall from "housecall"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
class Refinanciamento extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      errors: {},
      nome: "",
      motivos: [],
      pageCount: 0,
      perPage: 0,
      page: 0,
      field: 0,
      modal: false,
      isLoading: true,
      id: 0,
      searchText: "",
      modalRemover: false
    }
    this.getData = this.getData.bind(this)
    this.filter = this.filter.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.toggleLoading = this.toggleLoading.bind(this)
    this.handleChangeFilters = this.handleChangeFilters.bind(this)
    this.setLoadingFalse = this.setLoadingFalse.bind(this)
    this.imprimirRefinanc = this.imprimirRefinanc.bind(this)
    this.imprimirCarneRef = this.imprimirCarneRef.bind(this)
    this.openRemover = this.openRemover.bind(this)
    this.closeRemover = this.closeRemover.bind(this)
    this.remover = this.remover.bind(this)
  }

  imprimirCarneRef(key) {
    showWaiting()
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        const reader = new FileReader()
        reader.addEventListener("loadend", e => {
          const text = e.srcElement.result
          try {
            let error = JSON.parse(text)

            let status = error.status
            if (status === "pago") {
              showNotification(
                "top",
                "center",
                `Ocorreram erros! ${error.message}!`,
                "danger"
              )
            }
          } catch (e) {
            showNotification(
              "top",
              "center",
              `Ocorreram erros! ${error.message}`,
              "danger"
            )
          }
        })
        reader.readAsText(error.response.data)
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    if(this.state.motivos[key].deleted_at){
      hideWaiting()
      showNotification(
        "top",
        "center",
        `Ocorreram erros! Refinanciamento inválido!`,
        "danger"
      )
      return 
    }
    imprimirCarne(this.state.motivos[key].id, response)
  }

  imprimirRefinanc(key) {
    showWaiting()
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        const reader = new FileReader()
        reader.addEventListener("loadend", e => {
          const text = e.srcElement.result
          try {
            let error = JSON.parse(text)

            let status = error.status
            if (status === "pago") {
              showNotification(
                "top",
                "center",
                `Ocorreram erros! ${error.message}!`,
                "danger"
              )
            }
          } catch (e) {
            showNotification(
              "top",
              "center",
              `Ocorreram erros! ${error.message}`,
              "danger"
            )
          }
        })
        reader.readAsText(error.response.data)
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    if(this.state.motivos[key].deleted_at){
      hideWaiting()
      showNotification(
        "top",
        "center",
        `Ocorreram erros! Refinanciamento inválido!`,
        "danger"
      )
      return 
    }
    imprimirRefinanciamento(this.state.motivos[key].id, response)
  }

  handleChangeFilters = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } })
      }
    })
  }

  filter(event,pagina) {
    let searchText = event.target.value
    let newState = {
      isLoading:true,
      searchText: searchText,
      searchActive: searchText !== ""
    }
    if(pagina == null){
      newState["page"] = 0
    }
    this.setState(newState
      ,
      () => {
        if (this.state.searchActive) {
          // if (!this.state.isLoading) {
          //   this.toggleLoading()
          // }
          let filtersCheckbox = this.state.filterField || ""
          // this.queue.push(() =>
          search(searchText, filtersCheckbox, undefined, (error, data) => {
            this.setState({isLoading:false})
            if (error) {
              showNotification(
                "top",
                "center",
                "Ocorreram erros, verifique sua conexão com a internet",
                "danger"
              )
            } else {
              this.setState({
                motivos: data.data,
                pageCount: data.total,
                perPage: data.per_page,
              })
            }
          },pagina)
          // )
        } else {
          this.getData()
        }
      }
    )
  }
  setLoadingFalse() {
    this.setState({
      isLoading: false
    })
  }
  componentDidMount() {
    this.getData()
    this.queue.on("idle", this.setLoadingFalse)
  }
  handlePageClick(data) {
    //this.getData(data.selected + 1);
  }

  handleChangePage = (event, page) => {
    var pagina;
    pagina = page + 1;
    this.setState({page: page})
    if(page < 0){
      return;
    }
    if (this.state.searchText.toString().trim() != "") {
      this.filter({target: {value: this.state.searchText}}, pagina, page)
      return;
    }
    this.getData(pagina)
  };

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    })
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  getData(page) {
    this.setState({isLoading:true})
    get(page, (error, data) => {
      this.setState({isLoading:false})
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        this.setState({
          motivos: data.data,
          pageCount: data.total,
          perPage: data.per_page,
        })
      }
    })
  }

  handleEdit(key) {
    this.props.history.push(
      "/divida/refinanciamento/edit/" + this.state.motivos[key].id
    )
  }

  openRemover(key) {
    if(this.state.motivos[key].deleted_at){
      hideWaiting()
      showNotification(
        "top",
        "center",
        `Ocorreram erros! Refinanciamento inválido!`,
        "danger"
      )
      return 
    }
    this.setState({
      modalRemover: true,
      id: this.state.motivos[key].id
    })
  }

  closeRemover() {
    this.setState({
      modalRemover: false
    })
  }

  remover() {
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          `Ocorreram erros! ${error.response.data.message}`,
          "danger"
        )
      } else {
        showNotification(
          "top",
          "center",
          "Refinanciamento removido com sucesso!",
          "success"
        )
        this.closeRemover()
        this.getData()
      }
    }
    removeRef(this.state.id, response)
  }

  render() {
    const { classes } = this.props

    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código de baixa" },
      { value: "contribuinte_id", label: "Código do Contribuinte" },
      { value: "contribuinte_nome_razao_social", label: "Razão Social" },
      { value: "refis_id", label: "Código Refis" },
    ]
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row" spacing={8}>
              <Grid item lg={8} xs={12}>
                <h2 className={classes.cardTitleWhite}>Refinanciamento</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                  <Link
                    to="/divida/refinanciamento/restore"
                    style={{ textDecoration: "none" }}  
                  >
                    <Button block color="warning" round>
                      <Receipt /> Retornar dívida
                    </Button>
                  </Link>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Link
                  to="/divida/refinanciamento/create"
                  style={{ textDecoration: "none" }}
                >
                  <Button block color="success" round>
                    <AddIcon /> Adicionar
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Search
              label="Pesquise aqui"
              value={this.state.filterField}
              onChangeText={this.filter}
              onChangeFields={this.handleChangeFilters}
              fields={fields}
            />
            <Grid className={classes.legend} container direction="row">
              <Lens className={classes.dot + " " + classes.dotSuccess} />
              Refinanciamento Pago
              <Lens className={classes.dot + " " + classes.dotWarning} />
              Refinanciamento inválido
            </Grid>
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  getRowColor={(key, classes) => {
                    if (this.state.motivos[key].pago) {
                      return classes.successTableRow
                    } else if (this.state.motivos[key].pago) {
                      return classes.dangerTableRow
                    } else if(this.state.motivos[key].deleted_at){
                      return classes.warningTableRow
                    } else {
                      return ""
                    }
                  }
                  }
                  tableHeaderColor="primary"
                  actions={[
                    {
                      title: "Imprimir Carnê",
                      icon: (
                        <Print
                          className={
                            classes.tableActionButtonIcon +
                            " " +
                            classes.book
                          }
                        />
                      ),
                      onClick: key => this.imprimirRefinanc(key)
                    },
                    {
                      title: "Imprimir Termo",
                      icon: (
                        <ChromeReaderMode
                          className={
                            classes.tableActionButtonIcon +
                            " " +
                            classes.description+" "+classes.rowDisabled
                          }
                        />
                      ),
                      onClick: key => this.imprimirCarneRef(key),
                    },
                    {
                      title: "Remover",
                      icon: (
                        <Delete
                          className={
                            classes.tableActionButtonIcon +
                            " " +
                            classes.close
                          }
                        />
                      ),
                      onClick: key => this.openRemover(key)
                    }

                  ]}
                  tableHead={["Código", "Código Refis", "Contribuinte", "Histórico"]}
                  tableData={this.state.motivos.map((item, key) => {
                    return [item.id + "", item.refis_id + "", item.contribuinte.nome_razao_social, item.historico ? item.historico : "", key]
                  })}
                />
                {(
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={this.state.pageCount}
                    rowsPerPage={this.state.perPage?this.state.perPage:0}
                    page={this.state.page?this.state.page:0}
                    backIconButtonProps={{
                      "aria-label": "Previous Page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page"
                    }}
                    SelectProps={{
                      native: true
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>

        {/* adblock */}
        <AdBlockDetect>
          <Dialog open={true} aria-labelledby="form-dialog-title">
            <form onSubmit={this.handleSave}>
              <DialogContent>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Typography variant="h5" gutterBottom>
                      Por favor, desabilite o ADBlock para impressão de carnês.
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
            </form>
          </Dialog>
        </AdBlockDetect>

        {/* dialog confirmacao */}
        <Dialog
          open={this.state.modalRemover}
          onClose={this.closeRemover}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{zIndex: "4"}}
        >
          <DialogTitle id="alert-dialog-title">Você tem certeza que deseja remover esse Refinanciamento?</DialogTitle>

          <DialogActions>
            <Button onClick={this.closeRemover} color="danger">
              Cancelar
              </Button>
            <Button onClick={this.remover} color="success">
              Confirmar
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
Refinanciamento = withRouter(Refinanciamento)
export default withStyles(logradouroStyle)(Refinanciamento)
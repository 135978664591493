import axios from "axios"
import { defaultHeaders } from "./Constants"

export function post(params, callBack) {
  let defaultHeader = defaultHeaders()
  if (!typeof params === "object") {
    defaultHeader["Content-Type"] = "multipart/form-data"
  }
  let config = { headers: defaultHeader }
  let url = process.env.REACT_APP_API + "baixasOcupacao"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getField(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "baixasOcupacao/create"
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function searchBaixa(searchText, field, onStart, callBack) {
  let config = {
    headers: defaultHeaders(),
    data: { a: "a" }
  }
  let url =
    process.env.REACT_APP_API +
    `search/baixasOcupacao?text=${searchText}&fields=${field}`
  if (onStart) {
    onStart()
  }
  return axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function destroy(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "baixasOcupacao/" + id
  axios
    .delete(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

import React from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import loginStyle from "../../assets/css/layout/loginStyle"
import Button from "../../components/CustomButtons/Button"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import Grid from "@material-ui/core/Grid"
import GridContainer from "../../components/Grid/GridContainer"
import { Typography } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import {
    showNotification,
    showWaiting,
    hideWaiting,
    mapErrors
} from "../../util/Constants"
import { imprimirPublico } from "../../util/certidaonegativa"
import Mask from "./MaskCpfCnpj"

export class CNDContribuinte extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {},
            documento: "",
            imoveis: [],
            imovel_id: {}
        }
        this.handleChange = this.handleChange.bind(this)
        this.imprimir = this.imprimir.bind(this)
    }
    toggleLoad() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }
    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        let valor = event.target.value
        errors[name] = undefined
        errors.contribuinte_id = undefined
        this.setState({
            documento: Mask.CpfCnpjMask(valor),
            errors: errors
        }, () => {

        })
    }
    imprimir() {
        showWaiting()
        let val = this.state.documento
        imprimirPublico(Mask.limparMask(val), (error, data) => {
            hideWaiting()
            if (error) {
                const reader = new FileReader()
                reader.addEventListener("loadend", e => {
                    const text = e.srcElement.result
                    try {
                        let error = JSON.parse(text)
                        if (error.message) {
                            showNotification("top", "center", error.message, "warning")
                        }

                        this.setState({
                            errors: mapErrors({ response: { data: error } })
                        })
                    } catch (e) {   
                    }
                })

                reader.readAsText(error.response.data)
            } else {
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)

            }

        })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.content}>
                <div className={classes.background} />
                <div>
                    <form>
                        <GridContainer alignItems="center" justify="center">
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <Card>
                                    <CardHeader className={classes.textCenter} color="primary">
                                        <h2 className={classes.cardTitleWhite}>
                                            Emitir Certidão Negativa de Débitos
                                        </h2>
                                        <h4 className={classes.cardTitleWhite}>
                                            Insira seu CPF ou CNPJ sem pontos ou espaços
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Typography variant="caption" color="error">
                                            Para o funcionamento correto desta ferramenta, é recomendado que você desabilite o AdBlocker do seu navegador.
                                                    </Typography>
                                        <Grid container spacing={16} alignItems="center">
                                            <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
                                                <TextField
                                                    id="outlined-name"
                                                    placeholder="CPF ou CNPJ"
                                                    fullWidth
                                                    name="documento"
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    error={this.state.errors.documento}
                                                    helperText={this.state.errors.documento}
                                                    value={this.state.documento}
                                                    margin="normal"
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                                                <Button onClick={this.imprimir} color="info" fullWidth>
                                                    Procurar e imprimir
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        {
                                            this.state.errors.contribuinte_id && <Typography variant="h6" color="error">
                                                {this.state.errors.contribuinte_id}
                                            </Typography>
                                        }

                                    </CardBody>
                                </Card>
                            </Grid>
                        </GridContainer>
                    </form>
                </div>
            </div>
        )
    }
}

export default withStyles(loginStyle)(CNDContribuinte)

import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles, Paper } from "@material-ui/core";
import logradouroStyle from "../../assets/css/layout/logradouroStyle";
import Button from "../../components/CustomButtons/Button";

import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import { showNotification, mapErrors } from "../../util/Constants";
import Table from "../../components/Table/Table";
import Edit from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { get, post, put } from "../../util/distrito";
import LoadingContent from "../../components/LoadingContent";

class Distrito extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch:false,
      searchText:'',
      value: "",
      error: "",
      errors: {},
      nome: "",
      distritos: [],
      field: 0,
      modal: false,
      id: 0,
      distritoFiltered: [],
      search: false,
    };

    this.getData = this.getData.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.searchFilter = this.searchFilter.bind(this)
  }

  componentWillMount() {
    this.getData();
  }

  handlePageClick(data) {
    //this.getData(data.selected + 1);
  }

  handleChange(event) {
    let name = event.target.name;
    let errors = this.state.errors;
    errors[name] = undefined;
    this.setState({
      [name]: event.target.value,
      errors: errors
    });
  }

  handleOpen = () => {
    this.setState({ modal: true });
  };

  handleClose = () => {
    this.setState({ modal: false }, () => {
      this.getData(this.state.page);
    });
  };
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }));
  }
  handleSave(event) {
    event.preventDefault();
    let params = {
      nome: this.state.nome
    };
    let response = error => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        );
        this.setState({
          errors: mapErrors(error)
        });
      } else {
        showNotification(
          "top",
          "center",
          `Distrito ${this.state.id ? "editado" : "incluído"} com sucesso`,
          "success"
        );
        this.handleClose();
      }
    };
    this.state.id
      ? put(this.state.id, params, response)
      : post(params, response);
  }

  searchFilter(value) {
    let filtered = this.state.distritos
    if(value !== ''){
      this.setState({
        search: true
      })
    } else {
      this.setState({
        search: false
      })
    }
    filtered = filtered.filter((filter) => {
      if(filter.nome !== null) {
        return filter.nome.indexOf(value.toUpperCase()) !== -1;
      } else {
        return null
      }
    })
    this.setState({ distritoFiltered: filtered })
  }

  getData(page) {
    if (!page) {
      this.toggleLoading();
    }
    get(page, (error, data) => {
      if (!page) {
        this.toggleLoading();
      }
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        this.setState({
          distritos: data,
          distritoFiltered: data
        });
      }
    });
  }

  handleEdit(key) {
    this.setState(
      {
        nome: this.state.distritos[key].nome,
        id: this.state.distritos[key].id
      },
      () => {
        this.handleOpen();
      }
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>Distritos</h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Button block color="success" round onClick={this.handleOpen}>
                  <AddIcon /> Adicionar
                </Button>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <form noValidate autoComplete="off">
              <TextField
                id="standard-search"
                label="Pesquisar"
                fullWidth
                type="search"
                name="search"
                onChange={(event) => this.searchFilter(event.target.value)}
                className={classes.textField}
                margin="normal"
              />
            </form>
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  actions={[
                    {
                      title: "Editar",
                      icon: (
                        <Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: this.handleEdit
                    }
                  ]}
                  tableHead={["#", "Nome"]}
                  tableData={(this.state.search ? this.state.distritoFiltered : this.state.distritos).map((item, key) => {
                    return [item.id + "", item.nome, key];
                  })}
                />
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>

        {/* MODAL */}
        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={this.handleSave}>
            <DialogTitle id="form-dialog-title">
              {this.state.id ? "Atualizar" : "Adicionar"} Distrito
            </DialogTitle>
            <DialogContent>
              <TextField
                id="outlined-select-currency"
                name="nome"
                label="Nome"
                required
                fullWidth
                error={this.state.errors.nome}
                helperText={this.state.errors.nome}
                multiline
                rowsMax="3"
                value={this.state.nome}
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="danger">
                Cancelar
              </Button>
              <Button type="submit" color="success">
                {this.state.id ? "Atualizar" : "Adicionar"}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(logradouroStyle)(Distrito);

import React from "react"
import {Link, withRouter} from "react-router-dom"
import {get, search, declaracao} from "../../../util/isencaoiptu"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import LoadingContent from "../../../components/LoadingContent"
import Button from "../../../components/CustomButtons/Button"
import Search from "../../../components/Search"
import Table from "../../../components/Table/Table"
import logradouroStyle from "../../../assets/css/layout/logradouroStyle"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Typography from "@material-ui/core/Typography"
import AdBlockDetect from "react-ad-block-detect"
import {
    showNotification,
    showWaiting,
    hideWaiting
} from "../../../util/Constants"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import TablePagination from "@material-ui/core/TablePagination"
import {withStyles, Paper} from "@material-ui/core"
import Edit from "@material-ui/icons/Edit"

import housecall from "housecall"
import moment from "moment"
import Print from "@material-ui/icons/Print"

class IsencaoIptu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSearch: false,
            isencoes: [],
            pageCount: 0,
            perPage: 0,
            page: 0,
            searchText: "",
            filterField: ""
        }
        this.getData = this.getData.bind(this)
        this.getDeclaracao = this.getDeclaracao.bind(this)
        this.filter = this.filter.bind(this)
        this.queue = housecall({concurrency: 1, cooldown: 0})
        this.handleClose = this.handleClose.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeFilters = this.handleChangeFilters.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
    }
  handleClose = () => {
    this.setState({modal: false})
  }

    componentWillMount() {
        this.getData()
    }


    handleChangeFilters = event => {
        this.setState({filterField: event.target.value}, () => {
            if (this.state.searchActive) {
                this.filter({target: {value: this.state.searchText}})
            }
        })
    }

    componentDidMount() {
        this.queue.on("idle", this.toggleLoading)
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    handleEdit(key) {
        this.props.history.push("/isencao/edit/" + this.state.isencoes[key].id)
    }

    handleChangePage = (event, page) => {
        var pagina;
        pagina = page + 1;
        this.setState({page: page})
        if (page < 0) {
            return;
        }
        if (this.state.searchText.toString().trim() != "") {
            this.filter({target: {value: this.state.searchText}}, pagina, page)
            return;
        }
        this.getData(pagina)
    };

    getDeclaracao(index) {
        showWaiting()
        let response = (error, data) => {
            if (error) {
                hideWaiting()
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {
                hideWaiting()
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        declaracao(this.state.isencoes[index].id, response)
    }

    filter(event, pagina) {
        let searchText = event.target.value
        let newState = {
            isLoading: true,
            searchText: searchText,
            searchActive: searchText !== ""
        }
        if (pagina == null) {
            newState['page'] = 0
        }
        this.setState(newState
            ,
            () => {
                if (this.state.searchActive) {

                    let filtersCheckbox = this.state.filterField || ""

                    search(searchText, filtersCheckbox, undefined, (error, data) => {
                        this.setState({isLoading: false})
                        if (error) {
                            showNotification(
                                "top",
                                "center",
                                "Ocorreram erros, verifique sua conexão com a internet",
                                "danger"
                            )
                        } else {

                            this.setState({
                                isencoes: data.data,
                                pageCount: data.total,
                                perPage: data.per_page
                            })
                        }
                    }, pagina)

                } else {
                    this.getData()
                }
            }
        )
    }

    getData(page) {

        this.setState({isLoading: true})

        get(page, (error, data) => {
            this.setState({isLoading: false})
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {
                this.setState({
                    isencoes: data.data,
                    pageCount: data.total,
                    perPage: data.per_page
                })
            }
        })
    }

    render() {
        const {classes} = this.props
        const fields = [
            {value: "", label: "Todos os campos"},
            {value: "imovel_id", label: "Inscrição do Imóvel"},
            {value: "numero_processo", label: "Numero do processo"},
            {value: "data_processo", label: "Data do Processo (dd/MM/yyyy)"},
            {value: "exercicio", label: "Exercício"},
            {value: "motivo_descricao", label: "Motivo"}
        ]
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>Isenções de IPTU</h2>
                            </Grid>
                            <Grid item lg={2} xs={12}>
                                <Link to="/isencao/create" style={{textDecoration: "none"}}>
                                    <Button block color="success" round>
                                        <AddIcon/> Adicionar
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Search
                            label="Pesquise aqui"
                            value={this.state.filterField}
                            onChangeText={this.filter}
                            onChangeFields={this.handleChangeFilters}
                            fields={fields}
                        />
                        <LoadingContent isLoading={this.state.isLoading}>
                            <Paper className={classes.tableWrapper}>
                                <Table
                                    tableHeaderColor="primary"
                                    actions={[
                                        {
                                            title: "Gerar declaração",
                                            icon: (
                                                <Print
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.print
                                                    }
                                                />
                                            ),
                                            onClick: this.getDeclaracao
                                        },
                                        {
                                            title: "Editar",
                                            icon: (
                                                <Edit
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: this.handleEdit
                                        }
                                    ]}
                                    tableHead={[
                                        "#",
                                        "Nº Processo",
                                        "Ano do Processo",
                                        "Exercício",
                                        "Data do Processo",
                                        "Observações",
                                        "Motivo"
                                    ]}
                                    tableData={this.state.isencoes.map((item, key) => {
                                        return [
                                            item.id + "",
                                            item.numero_processo,
                                            item.ano_processo,
                                            item.exercicio,
                                            moment(item.data_processo).format("DD/MM/YYYY"),
                                            item.observacoes,
                                            item.motivo.descricao,
                                            key
                                        ]
                                    })}
                                />

                                {(
                                    <TablePagination
                                        component="div"
                                        rowsPerPageOptions={[]}
                                        count={this.state.pageCount}
                                        rowsPerPage={this.state.perPage ? this.state.perPage : 0}
                                        page={this.state.page ? this.state.page : 0}
                                        backIconButtonProps={

                                            {"aria-label": "Previous Page"}}
                                        nextIconButtonProps={{
                                            "aria-label": "Next Page",
                                        }}
                                        SelectProps={{
                                            native: true
                                        }}

                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                )}
                            </Paper>
                        </LoadingContent>
                    </CardBody>
                </Card>

                {/* adblock */}
                <AdBlockDetect>
                    <Dialog open={true} aria-labelledby="form-dialog-title">
                        <DialogContent>
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                                direction="row"
                            >
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <Typography variant="h5" gutterBottom>
                                        Por favor, desabilite o ADBlock para geração de BCI e
                                        impressão de carnês.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </AdBlockDetect>
            </div>
        )
    }
}

IsencaoIptu = withRouter(IsencaoIptu)
export default withStyles(logradouroStyle)(IsencaoIptu)

import React from "react"

import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"

import { withStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"

import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import CardFooter from "../../components/Card/CardFooter"
import Button from "../../components/CustomButtons/Button"
import AsyncSelect from "../../components/AsyncSelect"
import SearchFields from "../../components/SearchFields"
import numeral from "../../util/numeral"
import moment from "moment"
import Warning from "@material-ui/icons/Warning"
import Done from "@material-ui/icons/Done"
import AddIcon from "@material-ui/icons/Add"
import { searchDamProjeto, mudarVencimento } from "../../util/projeto"
import {
  showNotification,
  mapErrors,
  hideWaiting,
  showWaiting
} from "../../util/Constants"

const newState = {
  errors: {},
  damProjeto: [],
  dam_id: [],
  data_vencimento: new Date(),
  nova_data_vencimento: new Date(),
  valor: "",
  juros: "",
  multa: "",
  localizacao: ""
}

class MudarVencimentoProjeto extends React.Component {
  constructor(props) {
    super(props)
    this.state = newState
    this.loadOptions = this.loadOptions.bind(this)
    this.getDamById = this.getDamById.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  loadOptions(inputValue, callback) {
    searchDamProjeto(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let projetos = data.data.map(projeto => {
            console.log(projeto)
            return {
              value: projeto.id,
              label: `${projeto.projeto.proprietario_id} - ${
                projeto.projeto.proprietario.nome_razao_social
                } - Projeto Nº ${projeto.projeto_id} - ${projeto.tipo.nome}`
            }
          })
          this.setState({
            damProjeto: data.data
          })
          callback(projetos)
        }
      }
    )
  }

  getDamById(id) {
    return this.state.damProjeto.filter(item => {
      return id === item.id
    })[0]
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let response = error => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Data de vencimento alterada realizada com sucesso",
          "success"
        )
        this.setState(newState)
      }
    }
    mudarVencimento(
      this.state.dam_id.value,
      moment(this.state.nova_data_vencimento).format("YYYY-MM-DD"),
      response
    )
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined

    this.setState(
      {
        [name]: event.target.value,
        errors: errors
      },
      () => {
        if (this.state.dam_id && name === "dam_id") {
          let dam = this.getDamById(this.state.dam_id.value)
          this.setState({
            data_vencimento: dam.vencimento,
            valor: dam.valor,
            juros: dam.juros,
            multa: dam.multa,
            localizacao: dam.projeto.localizacao,
            lancadoNaDivida: dam.lancado_na_divida,
            pago: dam.pago === true,
            divida_id: dam.divida_id
          })
        }
      }
    )
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "projeto_id", label: "Código do Projeto" },
      { value: "contribuinte_id", label: "Código do Proprietário" },
      { value: "contribuinte_cpf_cnpj", label: "Documento" },
      { value: "competencia", label: "Competência" },
      { value: "contribuinte_nome_razao_social", label: "Razão Social" }
    ]
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>
                  Alterar Vencimento de DAM Projeto
                </h2>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <SearchFields
                  name="filterField"
                  value={this.state.filterField || ""}
                  onChangeFields={this.handleChange}
                  fields={fields}
                />
                <AsyncSelect
                  className={
                    `form-control form-control-alternative form-select Select-menu-outer ${
                    this.props.error ? "has-danger" : ""
                    }`
                  }
                  value={this.state.dam_id}
                  onChange={this.handleChange}
                  loadOptions={this.loadOptions}
                  error={this.state.errors.dam_id}
                  helperText={this.state.errors.dam_id}
                  name="dam_id"
                  placeholder="DAM"
                  message="Pesquise o DAM"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  name="localizacao"
                  label="Localização"
                  fullWidth
                  disabled
                  value={this.state.localizacao || ""}
                  margin="normal"
                  variant="outlined"
                  defaultValue={this.state.localizacao || ""}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="outlined-select-currency"
                  label="Valor"
                  fullWidth
                  disabled
                  value={
                    "R$" +
                    numeral(parseFloat(this.state.valor))
                      .format("0.0,00")
                      .slice(0, -2) || ""
                  }
                  error={this.state.errors.valor}
                  helperText={this.state.errors.valor}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  defaultValue=""
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="outlined-select-currency"
                  label="Juros"
                  fullWidth
                  disabled
                  value={
                    "R$" +
                    numeral(parseFloat(this.state.juros))
                      .format("0.0,00")
                      .slice(0, -2) || ""
                  }
                  error={this.state.errors.juros}
                  helperText={this.state.errors.juros}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  defaultValue=""
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <TextField
                  id="outlined-select-currency"
                  label="Multa"
                  fullWidth
                  disabled
                  value={
                    "R$" +
                    numeral(parseFloat(this.state.multa))
                      .format("0.0,00")
                      .slice(0, -2) || ""
                  }
                  error={this.state.errors.multa}
                  helperText={this.state.errors.multa}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  defaultValue=""
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="vencimento"
                  label="Data do Vencimento"
                  fullWidth
                  type="date"
                  disabled
                  value={this.state.data_vencimento || ""}
                  error={this.state.errors.data_vencimento}
                  helperText={this.state.errors.data_vencimento}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="nova_data_vencimento"
                  label="Nova data do Vencimento"
                  fullWidth
                  type="date"
                  error={this.state.errors.vencimento}
                  helperText={this.state.errors.vencimento}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  defaultValue={moment(this.state.nova_data_vencimento).format(
                    "YYYY-MM-DD"
                  )}
                />
              </Grid>
            </Grid>
          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={(this.state.lancadoNaDivida || this.state.pago) ? 6 : 2}>
                <Button
                  block
                  color={this.state.pago ? "success" : (this.state.lancadoNaDivida ? "danger" : "info")}
                  round
                  disabled={this.state.pago || this.state.lancadoNaDivida}
                  onClick={this.handleSave}
                >
                  {(this.state.lancadoNaDivida || this.state.pago) ? (


                    this.state.pago ? (<div>
                      <Done /> O DAM Selecionado já está pago
                      </div>
                    ) : (<div>
                      <div>
                        < Warning /> O DAM Selecionado foi lançado na dívida
                      </div>
                      <div> Código da dívida ativa: {this.state.divida_id}</div>
                    </ div >)
                  ) : (
                      <div>
                        <AddIcon /> Alterar
                    </div>
                    )}
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>
      </div>
    )
  }
}

export default withStyles(contribuinteStyle)(MudarVencimentoProjeto)

import React from "react"
import { Link, withRouter } from "react-router-dom"
import { withStyles, Paper, TextField } from "@material-ui/core"
import logradouroStyle from "../../../assets/css/layout/logradouroStyle"
import Button from "../../../components/CustomButtons/Button"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import AddIcon from "@material-ui/icons/Add"
import SearchIcon from "@material-ui/icons/Search"
import DeleteIcon from "@material-ui/icons/Delete"
import Grid from "@material-ui/core/Grid"
import Table from "../../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import Edit from "@material-ui/icons/Edit"
import Close from "@material-ui/icons/Close"
import Print from "@material-ui/icons/Print"
import Description from "@material-ui/icons/Description"
import { get, bci, search, bciVazio, carne, getDivida, cancelar, advancedImovelSearchRequest } from "../../../util/imovel"
import LoadingContent from "../../../components/LoadingContent"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import Lens from "@material-ui/icons/Lens"
import DialogTitle from "@material-ui/core/DialogTitle"
import Typography from "@material-ui/core/Typography"
import AdBlockDetect from "react-ad-block-detect"
import AsyncSelect from "../../../components/AsyncSelect"
import { search as searchContribuinte } from "../../../util/contribuinte"
import { search as searchLogradouro } from "../../../util/logradouro"
import {
    showNotification,
    showWaiting,
    hideWaiting
} from "../../../util/Constants"
import Search from "../../../components/Search"
import AlterarVencimentoParcela from "../Movimento/AlterarVencimentoParcela"
import housecall from "housecall"
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import { IconLacancamento } from "../../Divida/Relatorios/icons";
import { IconInfo } from "../../../components/icons/icon-info";


class Imoveis extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            value: "",
            error: "",
            errors: {},
            nome: "",
            searchText: "",
            pageCount: 0,
            perPage: 0,
            page: 0,
            imoveis: [],
            field: 0,
            isSearch: false,
            modal: false,
            id: 0,
            id_logradouro: [],
            id_contribuintes: [],
            isCancelaInsc: false,
            search: {}
        }
        this.handleFieldChange = this.handleFieldChange.bind(this)
        this.getBCI = this.getBCI.bind(this)
        this.getData = this.getData.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.getCarne = this.getCarne.bind(this)
        this.cancelarImovel = this.cancelarImovel.bind(this)
        this.filter = this.filter.bind(this)
        this.queue = housecall({ concurrency: 1, cooldown: 0 })
        this.loadOptions = this.loadOptions.bind(this)
        this.loadLogradouro = this.loadLogradouro.bind(this)
        this.setLoadingFalse = this.setLoadingFalse.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.advancedSearch = this.advancedSearch.bind(this)
        this.cleanAdvancedSearch = this.cleanAdvancedSearch.bind(this)
    }

    setLoadingFalse() {
        this.setState({
            isLoading: false
        })
    }

    loadOptions(inputValue, callback) {
        searchContribuinte(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let contribuintes = data.data.map(contribuinte => {
                        return {
                            value: contribuinte.id,
                            label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                                ""} - ${contribuinte.cpf_cnpj || ""}`
                        }
                    })
                    callback(contribuintes)
                }
            }
        )
    }

    loadLogradouro(inputValue, callback) {
        searchLogradouro(inputValue, undefined, undefined, (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
                callback([])
            } else {
                let lograd = data.data.map(logradouro => {
                    return { value: logradouro.id, label: logradouro.nome }
                })
                callback(lograd)
            }
        })
    }

    handleEdit(key) {
        this.props.history.push("/imoveis/edit/" + this.state.imoveis[key].id)
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //   return true
    // }

    componentWillMount() {
        this.getData()
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        errors[name] = undefined
        this.setState({
            [name]: event.target.value,
            errors: errors
        })
    }
    async handleSearchChange(event) {
        const name = event.target.name.replace("search_", "")
        if (event.target.value) {
            await this.setState({
                search: { ...this.state.search, [name]: event.target.value }
            })
        } else {
            delete this.state.search[name]
            await this.setState({
                search: { ...this.state.search }
            })
        }
    }
    async cleanAdvancedSearch() {
        await this.setState({
            search: {}
        })
        this.advancedSearch()
    }
    advancedSearch(page) {
        showWaiting()
        if (!page || typeof page === 'object') {
            page = 0
            this.setState({
                page: 0
            })
        }
        const params = { ...this.state.search, "page": page }
        let response = (error, data) => {
            if (error) {
                if (error.response.status == 409) {
                    showNotification(
                        "top",
                        "center",
                        error.response.data,
                        "danger"
                    )
                } else if (error.response.status == 422) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique o formulário e tente novamente",
                        "warning"
                    )
                } else {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreu um erro inesperado, entre em contato com o suporte",
                        "danger"
                    )
                }
            } else {
                this.setState({
                    imoveis: data.data,
                    pageCount: data.total,
                    perPage: data.per_page,
                })
            }
            hideWaiting()
        }
        advancedImovelSearchRequest(params, response)
    }

    componentDidMount() {
        this.queue.on("idle", this.setLoadingFalse)
    }

    handleChangeFilters = event => {
        this.setState({ filterField: event.target.value }, () => {
            if (this.state.searchActive) {

                this.filter({ target: { value: this.state.searchText } })
            }

        })
    }

    filter(event, pagina) {
        let searchText = event.target.value
        let newState = {
            isLoading: true,
            searchText: searchText,
            searchActive: searchText !== "",

        }
        if (pagina == null) {
            newState["page"] = 0
        }
        this.setState(state => (newState
        ),
            () => {
                if (this.state.searchActive) {
                    let filtersCheckbox = this.state.filterField || ""
                    search(searchText, filtersCheckbox, pagina, (error, data) => {
                        this.setState({ isLoading: false })
                        if (error) {
                            showNotification(
                                "top",
                                "center",
                                "Ocorreram erros, verifique sua conexão com a internet",
                                "danger"
                            )
                        } else {


                            this.setState({
                                imoveis: data.data,
                                pageCount: data.total,
                                perPage: data.per_page,
                                loadingPage: false
                            })
                        }
                    })
                } else {
                    this.getData()
                }
            }
        )
    }

    getData(page) {
        this.setState({ isLoading: true })

        get(page, (error, data) => {

            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
                this.setState({ loadingPage: false })
            } else {
                this.setState({
                    imoveis: data.data,
                    isLoading: false,
                    pageCount: data.total,
                    perPage: data.per_page,
                    loadingPage: false
                })
            }
        })
    }

    handleChangePage = (event, page) => {
        var pagina;
        pagina = page + 1;
        this.setState({ page: page })
        if (page < 0) {
            return;
        }
        if (this.state.searchText.toString().trim() != "") {
            this.filter({ target: { value: this.state.searchText } }, pagina, page)
            return;
        } else if (Object.keys(this.state.search).length > 0) {
            this.advancedSearch(pagina)
            return
        }
        this.getData(pagina)
    };

    handleFieldChange(value) {
        this.setState({
            field: value
        })
    }
    getDividaImovel(key) {
        //TODO
        const imovel = this.state.imoveis[key]
        showWaiting()
        getDivida(imovel.id, (error, data) => {
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {


                this.setState({ cancelamento_com_debito: data.possui_debito_aberto, imovel_id: imovel.id })
            }
            hideWaiting()
            this.toggleModal("showCancelamentoModal")
        })

    }

    getBCI(index) {
        showWaiting()
        let response = (error, data) => {
            if (error) {
                hideWaiting()
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {
                hideWaiting()
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        index !== undefined
            ? bci(this.state.imoveis[index].id, response)
            : bciVazio(response)
    }

    getCarne(index) {
        showWaiting();
        debugger;
        this.setState({ showModal: false }, () => {
            hideWaiting();
            //showWaiting(); // Exibe o loading
    
            carne(
                index !== undefined ? this.state.imoveis[index].id : "",
                this.state.id_logradouro.value,
                this.state.id_contribuintes.value,
                this.state.email || "",
                this.state.lancamento, // Certifique-se que este campo � v�lido
                (error, data) => {
                    hideWaiting(); // Esconde o loading assim que o callback � executado
    
                    if (error) {
                        debugger;
                        const reader = new FileReader();
                        reader.addEventListener("loadend", (e) => {
                            const text = e.srcElement.result;
                            try {
                                let errorData = JSON.parse(text);
    
                                // Verifica��o tradicional para erros
                                if (errorData && errorData.status) {
                                    if (errorData.status === "pago" || errorData.status === "vencido") {
                                        showNotification(
                                            "top",
                                            "center",
                                            errorData.message,
                                            "warning"
                                        );
                                    }
                                } else if (
                                    errorData.errors && 
                                    errorData.errors.imovel_id && 
                                    errorData.errors.imovel_id.length > 0
                                ) {
                                    showNotification(
                                        "top",
                                        "center",
                                        "Este im�vel n�o tem IPTU lan�ado no ano atual!",
                                        "warning"
                                    );
                                }
                            } catch (e) {
                                showNotification(
                                    "top",
                                    "center",
                                    "Ocorreram erros, verifique sua conex�o com a internet",
                                    "danger"
                                );
                            }
                        });
                        reader.readAsText(error.response.data);
                    } else {
                        debugger;
                        if (index !== undefined) {
                            const url = URL.createObjectURL(data);
                            const tempLink = document.createElement("a");
                            tempLink.setAttribute("target", "_blank");
                            tempLink.setAttribute("href", `${url}`);
                            tempLink.setAttribute("style", "display:none;");
                            document.body.appendChild(tempLink);
                            tempLink.click();
                            document.body.removeChild(tempLink);
                        } else {
                            const reader = new FileReader();
                            reader.addEventListener("loadend", (e) => {
                                const text = e.srcElement.result;
                                const json = JSON.parse(text);
                                showNotification("top", "center", `${json.message}`, "success");
                            });
                            reader.readAsText(data);
                        }
                    }
                }
            );
        });
        hideWaiting();
    }

    cancelarImovel(key) {
        //TODO
        const imovel = this.state.imoveis[key]
        if (!this.state.isCheckedInscr && this.state.cancelamento_com_debito) {
            showNotification(
                "top",
                "center",
                "Precisa aceitar o termo para dar continuidade ao cancelamento.",
                "danger"
            )
            return
        }
        showWaiting()

        cancelar(imovel.id, this.state.motivoDeCancelamento, this.state.cancelamento_com_debito, (error, data) => {
            hideWaiting()
            if (error) {
                if (error.response) {
                    showNotification(
                        "top",
                        "center",
                        error.response.data.message,
                        "danger"
                    )
                } else {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique o formulário e tente novamente",
                        "danger"
                    )
                }
            } else {
                showNotification(
                    "top",
                    "center",
                    "Imóvel cancelado com sucesso",
                    "success"
                )
                this.toggleModal("showCancelamentoModal", null, () => {
                    this.getData(this.state.page)
                })

            }
        })
    }
    toggleModal(name, status, callback) {
        this.setState(
            state => ({
                [name]: !state[name]

            }),
            () => {
                if (callback) {
                    callback()
                }
                if (status === "success") {
                    let imovelID = this.state.selectedLancamento.imovel_id
                    var index
                    this.state.imoveis.every((imovel, key) => {
                        if (imovel.id === imovelID) {
                            index = key
                            return false
                        }
                        return true
                    })
                    this.getData()

                }
            }
        )

    }

    render() {
        const { classes } = this.props
        const { loadingPage } = this.state
        const fields = [
            // { value: "", label: "Todos os campos" },
            // { value: "id", label: "Inscrição" },
            // { value: "inscricao_cartografica", label: "Inscrição Cartográfica" },
            // //{ value: "matricula_cartorio", label: "Matrícula do Cartório" },
            // { value: "contribuinte_id", label: "Código do contribuinte" },
            // {
            //     value: "contribuinte_nome_razao_social",
            //     label: "Nome/Razão Social do Contribuinte"
            // },
            // { value: "contribuinte_cpf_cnpj", label: "Documento do Contribuinte" },
            // { value: "bairro", label: "Bairro" },
            // { value: "contribuinte_endereco", label: "Endereço do Contribuinte" },
            // { value: "logradouro_id", label: "Código do logradouro" },
            // { value: "secao_id", label: "Código da Seção" },
            // { value: "logradouro", label: "Logradouro" },
            // { value: "complemento", label: "Complemento" },
            // { value: "quadra", label: "Quadra" },
            // { value: "lote", label: "Lote" },
            // { value: "loteamento", label: "Loteamento" }
        ]
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid spacing={24} container direction="row">
                            <Grid item xs={12} sm={12} md={12} lg={5}>
                                <h2 className={classes.cardTitleWhite}>Imóveis</h2>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={2}>
                                <Button onClick={e => this.getBCI()} block color="info" round>
                                    <Print /> BCI
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3}>
                                <Button
                                    onClick={e => this.toggleModal("showModal")}
                                    block
                                    color="rose"
                                    round
                                >
                                    <Print /> Imprimir carnês
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={2}>
                                <Link to="/imoveis/create" style={{ textDecoration: "none" }}>
                                    <Button block color="success" round fullWidth>
                                        <AddIcon /> Adicionar
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Search
                            label="Pesquise aqui"
                            value={this.state.filterField}
                            onChangeText={this.filter}
                            onChangeFields={this.handleChangeFilters}
                            fields={fields}
                        />
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Inscrição do Imóvel"
                                    fullWidth
                                    name="search_id"
                                    value={this.state.search.id || ""}
                                    onChange={this.handleSearchChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Inscrição Cartográfica"
                                    fullWidth
                                    name="search_inscricao_cartografica"
                                    value={this.state.search.inscricao_cartografica || ""}
                                    onChange={this.handleSearchChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={9} md={6} lg={5}>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Logradouro do Imóvel"
                                    fullWidth
                                    name="search_logradouro"
                                    value={this.state.search.logradouro || ""}
                                    onChange={this.handleSearchChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={4} lg={2}>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Número do Imóvel"
                                    fullWidth
                                    name="search_numero"
                                    value={this.state.search.numero || ""}
                                    onChange={this.handleSearchChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Seção"
                                    fullWidth
                                    name="search_secao"
                                    value={this.state.search.secao || ""}
                                    onChange={this.handleSearchChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Bairro"
                                    fullWidth
                                    name="search_bairro"
                                    value={this.state.search.bairro || ""}
                                    onChange={this.handleSearchChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Loteamento"
                                    fullWidth
                                    name="search_loteamento"
                                    value={this.state.search.loteamento || ""}
                                    onChange={this.handleSearchChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Distrito"
                                    fullWidth
                                    name="search_distrito"
                                    value={this.state.search.distrito || ""}
                                    onChange={this.handleSearchChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Lote"
                                    fullWidth
                                    name="search_lote"
                                    value={this.state.search.lote || ""}
                                    onChange={this.handleSearchChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Quadra"
                                    fullWidth
                                    name="search_quadra"
                                    value={this.state.search.quadra || ""}
                                    onChange={this.handleSearchChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Matrícula"
                                    fullWidth
                                    name="search_matricula_cartorio"
                                    value={this.state.search.matricula_cartorio || ""}
                                    onChange={this.handleSearchChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={5}>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Complemento"
                                    fullWidth
                                    name="search_complemento"
                                    value={this.state.search.complemento || ""}
                                    onChange={this.handleSearchChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Código Contribuinte"
                                    fullWidth
                                    name="search_codigo_contribuinte"
                                    value={this.state.search.codigo_contribuinte || ""}
                                    onChange={this.handleSearchChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={3}>
                                <TextField
                                    id="outlined-select-currency"
                                    label="CPF/CNPJ Contribuinte"
                                    fullWidth
                                    name="search_cpf_cnpj_contribuinte"
                                    value={this.state.search.cpf_cnpj_contribuinte || ""}
                                    onChange={this.handleSearchChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <TextField
                                    id="outlined-select-currency"
                                    label="Nome/Razão Social Contribuinte"
                                    fullWidth
                                    name="search_nome_contribuinte"
                                    value={this.state.search.nome_contribuinte || ""}
                                    onChange={this.handleSearchChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue=""
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={16} justify={'flex-end'}>
                            <Grid item xs={12} sm={4} md={3} lg={2}>
                                <Button block color="danger" fullWidth onClick={this.cleanAdvancedSearch}>
                                    <DeleteIcon /> Limpar Busca
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={2}>
                                <Button block color="primary" fullWidth onClick={this.advancedSearch}>
                                    <SearchIcon /> Buscar
                                </Button>
                            </Grid>
                        </Grid>
                        <LoadingContent isLoading={this.state.isLoading}>
                            <Grid
                                className={classes.legend}
                                container
                                direction="row"
                                style={{ marginBottom: "2%" }}
                            >
                                <Lens className={classes.dot + " " + classes.dotDanger} />
                                Cancelado
                                <Lens className={classes.dot + " " + classes.dotWarning} />
                                Isento
                            </Grid>
                            <Paper className={classes.tableWrapper}>
                                <Table
                                    getRowColor={(key, classes) => {
                                        const imovel = this.state.imoveis[key]
                                        if (imovel.cancelamento) {
                                            return classes.dangerTableRow
                                        } else if (imovel.isencoes.filter(item => item.exercicio == "2020").length > 0 && imovel.isencoes.length > 0) {
                                            return classes.warningTableRow
                                        }
                                        return ""

                                    }}
                                    tableHeaderColor="primary"
                                    imoveis={this.state.imoveis}
                                    filter={true}
                                    actions={[
                                        {
                                            title: "Imprimir carnê de IPTU",
                                            icon: (
                                                <Description
                                                    className={
                                                        classes.tableActionButtonIcon +
                                                        " " +
                                                        classes.description
                                                    }
                                                />
                                            ),
                                            onClick: this.getCarne,
                                            id: 0
                                        },
                                        {
                                            title: "Gerar BCI",
                                            icon: (
                                                <Print
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.print
                                                    }
                                                />
                                            ),
                                            onClick: this.getBCI,
                                            id: 1
                                        },
                                        {
                                            title: "Editar",
                                            icon: (
                                                <Edit
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: this.handleEdit,
                                            id: 2
                                        },
                                        {
                                            title: "Cancelar",
                                            icon: (
                                                <Close
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.close
                                                    }
                                                />
                                            ),
                                            onClick: (key) => {
                                                this.setState({
                                                    selectedIndex: key
                                                }, () => {
                                                    this.getDividaImovel(key)
                                                })

                                            },
                                            id: 3
                                        },
                                        {
                                            title: "Cancelar",
                                            icon: (
                                                <IconInfo bg='#fff' size={30} />
                                            ),
                                            onClick: () => {

                                            },
                                            id: 10
                                        }
                                    ]}
                                    tableHead={[
                                        "Inscrição",
                                        "Inscrição cartográfica",
                                        //"Matrícula do Cartório",
                                        "Contribuinte",
                                        "Logradouro",
                                        "Numero",
                                        "Complemento"
                                    ]}
                                    tableData={this.state.imoveis.map((item, key) => {
                                        return [
                                            item.id + "",
                                            item.inscricao_cartografica,
                                            //item.matricula_cartorio,
                                            item.contribuinte
                                                ? item.contribuinte_id + " - " + item.contribuinte.nome_razao_social
                                                : "",
                                            item.logradouro ? item.logradouro.nome : "",
                                            item.numero,
                                            item.complemento,
                                            key
                                        ]
                                    })}
                                />

                                <TablePagination
                                    component="div"
                                    rowsPerPageOptions={[]}
                                    count={this.state.pageCount ? this.state.pageCount : 0}
                                    rowsPerPage={this.state.perPage ? this.state.perPage : 0}
                                    page={this.state.page ? this.state.page : 0}
                                    backIconButtonProps={
                                        { "aria-label": "Previous Page" }}
                                    nextIconButtonProps={{
                                        "aria-label": "Next Page",
                                    }}
                                    SelectProps={{
                                        native: true
                                    }}

                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </Paper>
                        </LoadingContent>
                    </CardBody>
                </Card>
                <Dialog
                    open={this.state.showModal || false}
                    onClose={e => this.toggleModal("showModal")}
                    fullWidth
                    maxWidth="lg"
                    aria-labelledby="form-dialog-title"
                >
                    <form onSubmit={e => {
                        e.preventDefault();
                        this.getCarne()
                    }}>
                        <DialogTitle id="form-dialog-title">
                            Selecione as opções de filtro ou vazio para TODOS
                        </DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Typography color="textSecondary" variant="caption">
                                        Selecione as opções de filtro ou vazio para TODOS
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <AsyncSelect
                                        className={
                                            `form-control form-control-alternative form-select Select-menu-outer ${
                                            this.props.error ? "has-danger" : ""
                                            }`
                                        }
                                        style={{ zIndex: "905 !important" }}
                                        value={this.state.id_contribuintes}
                                        onChange={this.handleChange}
                                        loadOptions={this.loadOptions}
                                        name="id_contribuintes"
                                        placeholder="Contribuinte"
                                        message="Pesquise o contribuinte"
                                        error={this.state.errors.contribuinte_id}
                                        helperText={this.state.errors.contribuinte_id}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <AsyncSelect
                                        classStyle={`form-control form-control-alternative form-select Select-menu-outer ${
                                            this.props.error ? "has-danger" : ""
                                            }`}
                                        style={{ position: "relative" }}
                                        value={this.state.id_logradouro}
                                        onChange={this.handleChange}
                                        loadOptions={this.loadLogradouro}
                                        error={this.state.errors.logradouro_id}
                                        helperText={this.state.errors.logradouro_id}
                                        name="id_logradouro"
                                        placeholder="Logradouro"
                                        message="Pesquise o logradouro"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <TextField
                                    id="outlined-select-currency"
                                    name="email"
                                    label="Email para enviar o arquivo"
                                    required
                                    fullWidth
                                    error={this.state.errors.email}
                                    helperText={this.state.errors.email}
                                    rowsMax="3"
                                    type="email"
                                    value={this.state.email || ""}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                        </DialogContent>

                        <DialogActions>
                            <Button

                                onClick={e => this.toggleModal("showModal")}
                                color="danger"

                            >
                                Cancelar
                            </Button>
                            <Button type="submit" color="success">
                                Gerar
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Dialog
                    open={this.state.showCancelamentoModal || false}
                    onClose={e => this.toggleModal("showCancelamentoModal")}
                    fullWidth
                    maxWidth="sm"
                    aria-labelledby="form-dialog-title"
                >
                    <form onSubmit={e => { e.preventDefault(); this.cancelarImovel(this.state.selectedIndex) }}>
                        <DialogTitle >
                            Cancelamento de inscrição de IPTU
                        </DialogTitle>
                        <DialogContent>

                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        name="motivoDeCancelamento"
                                        label="Motivo do cancelamento"
                                        required
                                        fullWidth
                                        error={this.state.errors.motivo}
                                        helperText={this.state.errors.motivo}
                                        rowsMax="3"
                                        multiline
                                        value={this.state.motivoDeCancelamento || ""}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <FormControl
                                component="fieldset"
                                className={classes.formControl}
                            >
                                {
                                    this.state.cancelamento_com_debito ? <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.isCheckedInscr}
                                                    color="secondary"
                                                    onChange={e => {
                                                        this.setState({
                                                            isCheckedInscr: e.target.checked
                                                        })

                                                    }}
                                                    name="cancelamento"
                                                />
                                            }
                                            label={`Imóvel de inscrição ${this.state.imovel_id}, possui débito em aberto ou dívida não paga, deseja cancelar a inscrição do imóvel mesmo assim?`}
                                        />
                                    </FormGroup> : null}
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={e => this.toggleModal("showCancelamentoModal")}
                                color="danger"
                            >
                                Voltar
                            </Button>
                            <Button type="submit" color="success" disabled={!this.state.isCheckedInscr && this.state.cancelamento_com_debito}>

                                Cancelar
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                <Dialog
                    open={this.state.showAlterarParcelaModal || false}
                    onClose={e => this.toggleModal("showAlterarParcelaModal")}
                    fullWidth
                    maxWidth="lg"
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        O Carnê deste imóvel contém parcelas vencidas, deseja atualizá-las?
                    </DialogTitle>
                    <DialogContent>
                        <AlterarVencimentoParcela
                            fromModal={true}
                            handleClose={status =>
                                this.toggleModal("showAlterarParcelaModal", status)
                            }
                            lancamento={this.state.selectedLancamento}
                        />
                    </DialogContent>
                </Dialog>
                {/* adblock */}
                <AdBlockDetect>
                    <Dialog open={true} aria-labelledby="form-dialog-title">
                        <DialogContent>
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                                direction="row"
                            >
                                <Grid item xs={12} sm={12} md={8} lg={8}>
                                    <Typography variant="h5" gutterBottom>
                                        Por favor, desabilite o ADBlock para geração de BCI e
                                        impressão de carnês.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </AdBlockDetect>
            </div>
        )
    }
}

Imoveis = withRouter(Imoveis)
export default withStyles(logradouroStyle)(Imoveis)

import axios from "axios"
import { defaultHeaders } from "./Constants"

export function get(page, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + `damsDeAlvara${page ? `?page=${page}` : ""}`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function search(searchText, field, onStart, callBack,pagina) {
    let config = {
        headers: defaultHeaders(),
        data: { a: "a" }
    }
    let url =
        process.env.REACT_APP_API +
        `search/damsDeAlvara?text=${searchText}&fields=${field}`
    if(pagina != null){
        url += `&page=${pagina}`
    }
    if (onStart) {
        onStart()
    }
    return axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}
export function put(id, params, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "changeHistoricoDamsDeAlvara/" + id
    axios
        .put(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}
import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont,
  purpleColor,
  blackColor,
  secondaryColor
} from "../material-dashboard-react"

const tableStyle = theme => ({
  warningTableHeader: {
    color: warningColor
  },
  primaryTableHeader: {
    color: primaryColor
  },
  dangerTableHeader: {
    color: dangerColor
  },
  successTableHeader: {
    color: successColor
  },
  infoTableHeader: {
    color: infoColor
  },
  roseTableHeader: {
    color: roseColor
  },
  grayTableHeader: {
    color: grayColor
  },
  warningTableRow: {
    background: "linear-gradient(to right , #ff9900, #FFAB2E)",
    color: "white",
    fontWeight: "bolder"
  },
  primaryTableRow: {
    backgroundColor: primaryColor,
    color: "white"
  },
  dangerTableRow: {
    backgroundColor: dangerColor,
    color: "white",
    fontWeight: "bolder"
  },
  successTableRow: {
    background: "linear-gradient(to right , #0ba360, #3cba92)",
    color: "white",
    fontWeight: "bolder"
  },
  infoTableRow: {
    backgroundColor: infoColor,
    color: "white"
  },
  secondaryTableRow: {
    backgroundColor: secondaryColor,
    color: "white"
  },
  roseTableRow: {
    backgroundColor: roseColor,
    color: "white"
  },
  grayTableRow: {
    backgroundColor: grayColor
  },
  purpleTableRow: {
    backgroundColor: purpleColor,
    color: "white"
  },
  blackTableRow: {
    backgroundColor: blackColor,
    color: "white"
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse"
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    fontSize: "1em",
    fontWeight: "800 !important"
  },
  tableCell: {
    ...defaultFont,
    fontWeight: "inherit",
    color: "inherit",
    border: "none",
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle"
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  tableActions: {
    display: "flex",
    border: "none",
    padding: "12px 8px !important",
    verticalAlign: "middle"
  },
  tableActionButton: {
    width: "27px",
    height: "27px",
    padding: "0"
  },
  tableActionButtonIcon: {
    width: "17px",
    height: "17px"
  },
  tableRow: {
    position: "relative",
    borderBottom: "1px solid #dddddd"
  },
  
})

export default tableStyle

import axios from "axios"
import { defaultHeaders } from "./Constants"

export function getRelatorio(ano, order, imovel, contribuinte,posicaoFiscal, callBack) {
  let config = {
    headers: defaultHeaders(),
    responseType: "blob"
  }
  let url =
    process.env.REACT_APP_API +
    `relatorios/carnesLancados${order ? `?order=${order}` : ""}&ano=${ano || ""}&imovel_id=${imovel || ""}&contribuinte_id=${contribuinte || ""}&posicao_fiscal_id=${posicaoFiscal}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getRelatorioQuantitativo(ano, callBack) {
  let config = {
    headers: defaultHeaders(),
    responseType: "blob"
  }
  let url =
    process.env.REACT_APP_API +
    `relatorios/carnesLancadosQuantitativo${ano ? `?ano=${ano}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getResumoLancamentoIPTU(ano, callBack) {
  let config = {
    headers: defaultHeaders(),
    responseType: "blob"
  }
  let url =
    process.env.REACT_APP_API +
    `relatorios/resumoLancamentoIPTU${ano ? `?ano=${ano}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getRelatorioPercentualValor(ano, callBack) {
  let config = {
    headers: defaultHeaders(),
    responseType: "blob"
  }
  let url =
    process.env.REACT_APP_API +
    `relatorios/carnesLancadosPercentualvalor${ano ? `?ano=${ano}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

import React from "react"
import { withStyles } from "@material-ui/core"

import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"

import { search, alterarVencimento } from "../../util/emissaoitbi"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import CardFooter from "../../components/Card/CardFooter"
import AsyncSelect from "../../components/AsyncSelect"
import SearchFields from "../../components/SearchFields"
import Button from "../../components/CustomButtons/Button"
import {
  showNotification,
  mapErrors,
  showWaiting,
  hideWaiting
} from "../../util/Constants"
import moment from "moment"
import Warning from "@material-ui/icons/Warning"
import Done from "@material-ui/icons/Done"
import AddIcon from "@material-ui/icons/Add"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import { damItbi } from "../../util/dam"

const initialState = {
  errors: {},
  dam: [],
  id_dam: "",
  novo_vencimento: moment(new Date()).format("YYYY-MM-DD"),
  antigo_vencimento: new Date(),
  dam_itbi: [],
  modal: false
}

class AlterarVencimento extends React.Component {
  constructor(props) {
    super(props)
    if (Object.keys(props).includes("dam")) {
      initialState.id_dam = { value: props.dam.id }
      initialState.antigo_vencimento = props.dam.vencimento
    }
    this.state = initialState
    this.handleChange = this.handleChange.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.getDamById = this.getDamById.bind(this)
    this.handleSave = this.handleSave.bind(this)

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.imprimirDam = this.imprimirDam.bind(this)
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let response = error => {
      if (error) {
        hideWaiting()
        showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique o formulário e tente novamente",
            "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
            "top",
            "center",
            "Data de vencimento alterada realizada com sucesso",
            "success"
        )
        if (this.props.fromModal) {
          this.props.handleClose("success")
        } else {
          this.handleOpen()
        }
      }
    }
    alterarVencimento(
        this.state.id_dam.value,
        this.state.novo_vencimento,
        response
    )
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState(
        {
          [name]: event.target.value,
          errors: errors
        },
        () => {
          if (this.state.id_dam && name === "id_dam") {
            let dam = this.getDamById(this.state.id_dam.value)
            this.setState({
              antigo_vencimento: dam.vencimento,
              lancadoNaDivida: dam.lancado_na_divida,
              divida_id: dam.divida_id,
              pago: dam.pago === true,

            })
          }
        }
    )
  }

  loadOptions(inputValue, callback) {
    search(
        inputValue,
        this.state.filterField || "",
        undefined,
        (error, data) => {
          if (error) {
            showNotification(
                "top",
                "center",
                "Ocorreram erros, verifique sua conexão com a internet",
                "danger"
            )
            callback([])
          } else {
            let dam_itbi = data.data.map(dam => {
              return {
                value: dam.id,
                label: `${dam.id} - Transmitente: ${dam.transmitente
                    .nome_razao_social || ""} Adquirente: ${dam.adquirente
                    .nome_razao_social || ""}`
              }
            })
            this.setState({
              dam_itbi: data.data
            })
            callback(dam_itbi)
          }
        }
    )
  }

  getDamById(id) {
    return this.state.dam_itbi.filter(item => {
      return id === item.id
    })[0]
  }

  handleOpen() {
    this.setState({
      modal: true
    })
  }

  handleClose() {
    this.setState(initialState)
  }

  imprimirDam(event) {
    event.preventDefault()
    showWaiting()
    let response = (error, data) => {
      hideWaiting()
      if (error) {
        const reader = new FileReader()
        reader.addEventListener("loadend", e => {
          const text = e.srcElement.result
          try {
            let error = JSON.parse(text)

            let status = error.status
            if (status === "vencido") {
              this.setState({
                showAlterarVencimentoModal: true,
                modal: false
              })
            }
          } catch (e) {
            showNotification(
                "top",
                "center",
                `Ocorreram erros! ${error.message}`,
                "danger"
            )
          }
        })

        reader.readAsText(error.response.data)
      } else {
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)

        this.handleClose()
      }
    }
    damItbi(
        this.state.id_dam.value,
        this.state.historico,
        response
    )
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código DAM ITBI" },
      { value: "transmitente_id", label: "Código do Transmitente" },
      { value: "adquirente_id", label: "Código do Adquirente" },
      { value: "transmitente_nome_razao_social", label: "Nome Transmitente" },
      { value: "adquirente_nome_razao_social", label: "Nome Adquirente" }
    ]
    return (
        <div className={classes.container}>
          <Card>
            <CardHeader color="primary">
              <Grid container direction="row">
                <Grid item lg={10} xs={12}>
                  <h2 className={classes.cardTitleWhite}>
                    Alteração de vencimento
                  </h2>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              {!this.props.fromModal && (
                  <Grid container spacing={16}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <SearchFields
                          name="filterField"
                          value={this.state.filterField || ""}
                          onChangeFields={this.handleChange}
                          fields={fields}
                      />
                      <AsyncSelect
                          className={
                            `form-control form-control-alternative form-select select-custom ${
                                this.props.error ? "has-danger" : ""
                            }`
                          }
                          value={this.state.id_dam}
                          onChange={this.handleChange}
                          loadOptions={this.loadOptions}
                          error={this.state.errors.id_dam}
                          helperText={this.state.errors.id_dam || "Escolha o DAM"}
                          name="id_dam"
                          placeholder="DAM"
                          message="Pesquise o DAM"
                      />
                    </Grid>
                  </Grid>
              )}

              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                      id="outlined-select-currency"
                      name="antigo_vencimento"
                      label="Data do Vencimento"
                      fullWidth
                      type="date"
                      disabled
                      value={this.state.antigo_vencimento}
                      error={this.state.errors.data_vencimento}
                      helperText={this.state.errors.data_vencimento}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                      defaultValue={moment(this.state.antigo_vencimento).format(
                          "YYYY-MM-DD"
                      )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                      id="outlined-select-currency"
                      name="novo_vencimento"
                      label="Nova Data do Vencimento"
                      fullWidth
                      type="date"
                      error={this.state.errors.vencimento}
                      helperText={this.state.errors.vencimento}
                      onChange={this.handleChange}
                      margin="normal"
                      variant="outlined"
                      defaultValue={this.state.novo_vencimento}
                  />
                </Grid>
              </Grid>
            </CardBody>
            <CardFooter>
              <Grid container justify="center">
                <Grid item lg={(this.state.lancadoNaDivida || this.state.pago) ? 6 : 2}>
                  <Button
                      block
                      color={this.state.pago ? "success" : (this.state.lancadoNaDivida ? "danger" : "info")}
                      round
                      disabled={this.state.pago || this.state.lancadoNaDivida || !(this.state.id_dam)}
                      onClick={this.handleSave}
                  >
                    {(this.state.lancadoNaDivida || this.state.pago) ? (


                        this.state.pago ? (<div>
                              <Done /> O DAM Selecionado já está pago
                            </div>
                        ) : (<div>
                          <div>
                            < Warning /> O DAM Selecionado foi lançado na dívida
                          </div>
                          <div> Código da dívida ativa: {this.state.divida_id}</div>
                        </ div >)
                    ) : (
                        <div>
                          <AddIcon /> Alterar
                        </div>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardFooter>
          </Card>

          <Dialog
              open={this.state.modal}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
              fullWidth
              style={{ zIndex: "6" }}
          >
            <DialogTitle id="form-dialog-title">Você deseja imprimir o DAM atualizado?</DialogTitle>
            <DialogContent>
              <Grid container justify="center">
                <Grid item lg={4}>
                  <Button
                      block
                      color="info"
                      round
                      onClick={this.imprimirDam}
                  >
                    Imprimir DAM
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="danger">
                Voltar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
    )
  }
}

export default withStyles(contribuinteStyle)(AlterarVencimento)

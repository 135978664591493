import React from "react"
import './App.css';
import ReactDOM from "react-dom"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"

import "./assets/css/material-dashboard-react"
import "./assets/css/material-dashboard-react.css"
import "./assets/css/argon.css"
import "./assets/css/nucleo.css"
import "./assets/css/index.css"
import "./assets/css/animate.css"
import "./assets/css/react-confirm-alert.css"
import { tabLayoutReducer, userReducer, contribuintePreloadReducer } from "./reducers"
import { Provider } from "react-redux"
import Routes from "./routes/Routes"
import { combineReducers, createStore } from "redux"

const reducers = combineReducers({
  tabLayout: tabLayoutReducer,
  user: userReducer,
  contribuinte: contribuintePreloadReducer
})

const store = createStore(reducers)
const hist = createBrowserHistory()
ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Routes />
    </Router>
  </Provider>,
  document.getElementById("root")
)

import React from "react"
import Warning from "@material-ui/icons/Warning"
import Done from "@material-ui/icons/Done"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import moment from "moment"
import AsyncSelect from "../../../components/AsyncSelect"
import { MenuItem, withStyles, DialogContent } from "@material-ui/core"
import Button from "../../../components/CustomButtons/Button"
import Card from "../../../components/Card/Card"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import SearchFields from "../../../components/SearchFields"
import secaoStyle from "../../../assets/css/layout/secaoStyle"
import Typography from "@material-ui/core/Typography"
import numeral from "../../../util/numeral"
import { search, alterarVencimento, imprimirCarne, imprimirRefinanciamento } from "../../../util/refinanciamento"
import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../../util/Constants"
import housecall from "housecall"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"

let initialSt = {
    data_vencimentoAntiga: new Date(),
    data_vencimentoNova: new Date(),
    id_parcela: "",
    parcelas: [],
    errors: {},
    id_refinanciamento: "",
    refinanciamentos: [],
    modal: false

}

class AlterarVencimentoParcelaPag extends React.Component {

    constructor(props) {
        super(props)
        if (props.refinanciamento) {
            initialSt.refinanciamentos = [{
                label: `Código de baixa: ${props.refinanciamento.id} - ${props.refinanciamento.contribuinte.nome_razao_social} - ${props.refinanciamento.contribuinte.cpf_cnpj || ""}`
                , value: props.refinanciamento.id
            }]
            initialSt.id_refinanciamento = initialSt.refinanciamentos[0]
            initialSt.data_vencimentoAntiga = props.refinanciamento.vencimento_original
            initialSt.parcelas = props.refinanciamento.parcelas
        }
        this.state = initialSt
        this.loadOptions = this.loadOptions.bind(this)
        this.queue = housecall({ concurrency: 1, cooldown: 0 })
        this.handleChange = this.handleChange.bind(this)
        this.getParcelaCont = this.getParcelaCont.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.getInfoParcela = this.getInfoParcela.bind(this)
        this.imprimirRefinanc = this.imprimirRefinanc.bind(this)
        this.imprimirCarneRef = this.imprimirCarneRef.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.callDialog = this.callDialog.bind(this)
        this.getRefinanciamentoById = this.getRefinanciamentoById.bind(this)
    }

    handleClose = () => {
        this.setState({ modal: false, ...initialSt })
    }

    callDialog() {
        this.setState({ modal: true })
    }

    imprimirCarneRef() {
        showWaiting()
        let response = (error, data) => {
            if (error) {
                hideWaiting()
                const reader = new FileReader()
                reader.addEventListener("loadend", e => {
                    const text = e.srcElement.result
                    try {
                        let error = JSON.parse(text)

                        let status = error.status
                        if (status === "pago") {
                            showNotification(
                                "top",
                                "center",
                                `Ocorreram erros! ${error.message}!`,
                                "danger"
                            )
                        }
                    } catch (e) {
                        showNotification(
                            "top",
                            "center",
                            `Ocorreram erros! ${error.message}`,
                            "danger"
                        )
                    }
                })

                reader.readAsText(error.response.data)
            } else {
                hideWaiting()
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        imprimirCarne(this.state.id_refinanciamento.value, response)
    }

    imprimirRefinanc() {
        showWaiting()
        let response = (error, data) => {
            if (error) {
                hideWaiting()
                const reader = new FileReader()
                reader.addEventListener("loadend", e => {
                    const text = e.srcElement.result
                    try {
                        let error = JSON.parse(text)

                        let status = error.status
                        if (status === "pago") {
                            showNotification(
                                "top",
                                "center",
                                `Ocorreram erros! ${error.message}!`,
                                "danger"
                            )
                        }
                    } catch (e) {
                        showNotification(
                            "top",
                            "center",
                            `Ocorreram erros! ${error.message}`,
                            "danger"
                        )
                    }
                })

                reader.readAsText(error.response.data)
            } else {
                hideWaiting()
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        imprimirRefinanciamento(this.state.id_refinanciamento.value, response)
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        errors[name] = undefined
        this.setState({
            [name]: event.target.value,
            errors: errors
        }, () => {
            if (this.state.id_refinanciamento && name === "id_refinanciamento") {
                let pag = this.getParcelaCont(this.state.id_refinanciamento.value)
                let refinanciamento = this.getRefinanciamentoById(this.state.id_refinanciamento.value)
                this.setState({
                    parcelas: pag.parcelas,
                    vencido: refinanciamento.vencido,
                })
            }
            else if (this.state.id_parcela && name === "id_parcela") {
                let parc = this.getInfoParcela(this.state.id_parcela)
                this.setState({
                    data_vencimentoAntiga: parc.vencimento,
                    pago: parc.pago === true
                })
            }
        })
    }

    getInfoParcela(id) {
        return this.state.parcelas.filter(item => {
            return id === item.id
        })[0]
    }

    getParcelaCont(id) {
        return this.state.refinanciamentos.filter(item => {
            return id === item.id
        })[0]
    }

    loadOptions(inputValue, callback) {
        // this.queue.push(() =>
        search(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let pagamento = data.data.map(pag => {
                        return {
                            value: pag.id,
                            label: `Código de baixa: ${pag.id} - ${pag.contribuinte.nome_razao_social} - ${pag.contribuinte.cpf_cnpj || ""}`
                        }
                    })

                    this.setState({
                        refinanciamentos: data.data
                    }, () => {

                    })
                    callback(pagamento)
                }
            }
        )
        // )
    }

    handleSave(event) {
        event.preventDefault()
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
                this.setState({
                    errors: mapErrors(error)
                })
            } else {
                showNotification(
                    "top",
                    "center",
                    "Data de vencimento alterada realizada com sucesso",
                    "success"
                )
                this.callDialog()
            }
        }
        alterarVencimento(this.state.id_parcela, moment(this.state.data_vencimentoNova).format("YYYY-MM-DD"), response)
    }

    getRefinanciamentoById(id) {
        return this.state.refinanciamentos.filter((refinanciamento) => refinanciamento.id === id)[0]
    }

    render() {
        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "contribuinte_id", label: "Código do Contribuinte" },
            { value: "contribuinte_nome_fantasia", label: "Nome Fantasia" },
            { value: "contribuinte_nome_razao_social", label: "Razão Social" },
            { value: "id", label: "Código de baixa" },

        ]
        return (
            <div className={classes.container}>
                <Card>
                    <CardBody>
                        <Typography color="textSecondary" variant="caption">
                            Altere a data de vencimento da parcela de Refinanciamento
                        </Typography>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <AsyncSelect
                                    className={
                                        `form-control form-control-alternative form-select Select-menu-outer ${
                                        this.props.error ? "has-danger" : ""
                                        }`
                                    }
                                    value={this.state.id_refinanciamento}
                                    name="id_refinanciamento"
                                    onChange={this.handleChange}
                                    loadOptions={this.loadOptions}
                                    placeholder="Refinanciamento"
                                    message="Pesquise o Refinanciamento"
                                />
                                <SearchFields
                                    name="filterField"
                                    value={this.state.filterField || ""}
                                    onChangeFields={this.handleChange}
                                    fields={fields}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    required
                                    name="id_parcela"
                                    label="Parcelas"
                                    fullWidth
                                    value={this.state.id_parcela}
                                    onChange={this.handleChange}
                                    error={this.state.errors.parcela_id}
                                    helperText={this.state.errors.parcela_id}
                                    margin="normal"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    {this.state.parcelas.map((option, key) => {
                                        return (
                                            option.page ? "" :
                                                <MenuItem key={key} value={option.id}>
                                                    Parcela {option.numero_da_parcela} - Valor: {`R$ ${numeral(parseFloat(option.valor)).format(
                                                        "0.0,00"
                                                    )}`.slice(0, -2)} {' - Vencimento: ' + moment(option.vencimento).format("DD/MM/YYYY")} {option.pago ? ` - Pago em ${moment(option.baixa.data_pagamento).format("DD/MM/YYYY")}` : (option.vencido ? ' - Vencida' : '')}
                                                </MenuItem>
                                        )
                                    }
                                    )}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    id="date"
                                    label="Data Vencimento"
                                    margin="normal"
                                    variant="outlined"
                                    type="date"
                                    disabled
                                    fullWidth
                                    value={this.state.data_vencimentoAntiga}
                                    defaultValue={moment(this.state.data_vencimentoAntiga).format("DD/MM/YYYY")}
                                    name="data_vencimentoAntiga"
                                    onChange={this.handleChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    id="date"
                                    label="Nova data de vencimento"
                                    margin="normal"
                                    variant="outlined"
                                    type="date"
                                    fullWidth
                                    disabled={this.state.pago}
                                    value={moment(this.state.data_vencimentoNova).format("YYYY-MM-DD")}
                                    defaultValue={moment(this.state.data_vencimentoNova).format("YYYY-MM-DD")}
                                    helperText={this.state.errors.vencimento}
                                    error={this.state.errors.vencimento}
                                    name="data_vencimentoNova"
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        </Grid>

                    </CardBody>
                    <CardFooter>
                        <Grid container justify="center">
                            <Grid item lg={4}>
                                <Button
                                    block
                                    color={this.state.pago ? 'success' : 'info'}
                                    disabled={!(this.state.id_parcela) || (this.state.pago)}
                                    round
                                    onClick={this.handleSave}
                                >
                                    {(this.state.pago
                                    ) ? (
                                            <div>
                                                <div>
                                                    {this.state.pago ? <Done /> : <Warning />} {this.state.pago ? 'A parcela selecionada se encontra paga' : 'O refinanciamento selecionado se encontra vencido'}

                                                </div>
                                            </div>
                                        ) :
                                        'Alterar'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </CardFooter>
                </Card>
                <Dialog
                    id="body"
                    open={this.state.modal}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    style={{ zIndex: "901" }}
                >
                    <DialogTitle id="form-dialog-title">
                        Refinanciamento atualizado com sucesso!
                    </DialogTitle>
                    <DialogContent>

                        <Grid container spacing={16} justify="center">
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Button onClick={this.imprimirCarneRef} color="success" fullWidth>
                                    Imprimir Termo
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Button onClick={this.imprimirRefinanc} color="Info" fullWidth>
                                    Imprimir Carnê
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={16} justify="center">
                            <Button onClick={() => {
                                if (this.props.callBack) {
                                    this.props.callBack()
                                } else {
                                    this.handleClose()
                                }
                            }} color="danger" fullWidth>
                                Fechar
                            </Button>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default withStyles(secaoStyle)(AlterarVencimentoParcelaPag)
import React from "react"

import { withRouter } from 'react-router-dom';

import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../MenuDashboard"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../assets/css/theme"
import { connect } from "react-redux"
import { relatoriosGeraisTabChanged } from "../../actions"
import Assessment from "@material-ui/icons/Assessment"
import { bindActionCreators } from "redux"
import ArrecadacaoReceita from "./ArrecadacaoReceita";
import RelatorioLog from "./RelatorioLog";
import FechamentoArrecadacao from "./FechamentoArrecadacao";


const tab = [
  {
    name: "Arrecadação",
    icon: <Assessment />
  },
  {
    name: "Relatório de Log",
    icon: <Assessment />
  },
  {
    name: "Fechamento da Arrecadação",
    icon: <Assessment />
  },
]

class RelatoriosGerais extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      field: 0
    }
    this.handleFieldChange = this.handleFieldChange.bind(this)
  }

  handlePageClick(data) { }

  handleChange = event => {
    this.setState({ value: event.target.value })
  }

  handleChangePage = (event, page) => {
    if ((page || 1) !== this.state.page) {
      this.setState({ page }, () => { this.getData(this.state.page) })
    }
  }

  handleFieldChange(value) {
    this.props.relatoriosGeraisTabChanged(value)
  }
  render() {
    const { classes, field, roles } = this.props
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />
          {field === 0 && <ArrecadacaoReceita />}
          {(roles.filter(item => (item.id === 1)).length > 0) && (
            field === 1 && <RelatorioLog/>
          )}
          {
            field === 2 ? <FechamentoArrecadacao classes={classes}/>:null
          }
        </MuiThemeProvider>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    field: state.tabLayout.relatoriosGeraisTabSelected,
    roles: state.user.roles
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ relatoriosGeraisTabChanged }, dispatch)
}

RelatoriosGerais = withRouter(RelatoriosGerais)
RelatoriosGerais = connect(
  mapStateToProps,
  mapDispatchToProps
)(RelatoriosGerais)

export default withStyles(contribuinteStyle)(RelatoriosGerais)

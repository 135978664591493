import React from "react"
import {Link, withRouter} from "react-router-dom"

import {get, search} from "../../../util/lancamentosIPTU"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import LoadingContent from "../../../components/LoadingContent"
import Button from "../../../components/CustomButtons/Button"
import Search from "../../../components/Search"
import Table from "../../../components/Table/Table"
import logradouroStyle from "../../../assets/css/layout/logradouroStyle"
import {showNotification, showWaiting, hideWaiting} from "../../../util/Constants"
import {carne} from "../../../util/imovel"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import FileCopy from "@material-ui/icons/FileCopy"
import TablePagination from "@material-ui/core/TablePagination"
import {withStyles, Paper} from "@material-ui/core"
import ChromeReaderMode from "@material-ui/icons/ChromeReaderMode"
import housecall from "housecall"
import numeral from "../../../util/numeral"
import moment from "moment"
import Lens from "@material-ui/icons/Lens"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

class LancamentoIPTU extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSearch: false,

            lancamentos: [],
            pageCount: 0,
            perPage: 0,
            page: 0,
            searchText: "",
            filterField: "",
            parcelas: [],
            modal: false
        }
        this.getData = this.getData.bind(this)
        this.filter = this.filter.bind(this)
        this.queue = housecall({concurrency: 1, cooldown: 0})
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeFilters = this.handleChangeFilters.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.showParcelas = this.showParcelas.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.getCarne = this.getCarne.bind(this)
    }

    getCarne(index) {
        showWaiting()
        carne(
            index !== undefined ? this.state.lancamentos[index].imovel_id : "",
            "",
            "",
            "",
            index !== undefined ? this.state.lancamentos[index].id : "",
            (error, data) => {
                hideWaiting()
                if (error) {
                    const reader = new FileReader()
                    reader.addEventListener("loadend", e => {
                        const text = e.srcElement.result
                        try {
                            let error = JSON.parse(text)
                            let status = error.status
                            if (status === "pago" || status === "vencido" || status === "isento" || status === 'lancado_na_divida') {
                                showNotification("top", "center", error.message, "warning")
                            }
                        } catch (e) {
                            showNotification(
                                "top",
                                "center",
                                "Ocorreram erros, verifique sua conexão com a internet",
                                "danger"
                            )
                        }
                    })

                    reader.readAsText(error.response.data)
                } else {
                    if (index !== undefined) {
                        var url = URL.createObjectURL(data)
                        let tempLink = document.createElement("a")
                        tempLink.setAttribute("target", "_blank")
                        tempLink.setAttribute("href", `${url}`)
                        tempLink.setAttribute("style", "display:none;")
                        document.body.appendChild(tempLink)
                        tempLink.click()
                        document.body.removeChild(tempLink)
                    } else {
                        const reader = new FileReader()
                        reader.addEventListener("loadend", e => {
                            const text = e.srcElement.result
                            let json = JSON.parse(text)
                            showNotification(
                                "top",
                                "center",
                                `${json.message}`,
                                "success"
                            )
                        })
                        reader.readAsText(data)
                    }
                }
            }
        )
    }

    handleClose = () => {
        this.setState({modal: false})
    }

    componentWillMount() {
        this.getData()
    }

    componentDidMount() {
        this.queue.on("idle", this.toggleLoading)
    }

    handleChangeFilters = event => {
        this.setState({filterField: event.target.value}, () => {
            if (this.state.searchActive) {
                this.filter({target: {value: this.state.searchText}})
            }
        })
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    showParcelas(key) {
        console.log(this.state.lancamentos[key])
        this.setState({
            modal: true,
            parcelas: this.state.lancamentos[key].parcelas
        })
    }

    handleChangePage = (event, page) => {
        var pagina;
        pagina = page + 1;
        this.setState({page: page})
        if (page < 0) {
            return;
        }
        if (this.state.searchText.toString().trim() != "") {
            this.filter({target: {value: this.state.searchText}}, pagina, page)
            return;
        }
        this.getData(pagina)
    };

    filter(event, pagina) {
        let searchText = event.target.value
      let newState =  {
        isLoading: true,
        searchText: searchText,
        searchActive: searchText !== ""
      }
      if(pagina == null){
        newState["page"] = 0
      }
        this.setState(newState
           ,
            () => {
                if (this.state.searchActive) {

                    let filtersCheckbox = this.state.filterField || ""

                        search(searchText, filtersCheckbox, undefined, (error, data) => {
                          this.setState({isLoading:false})
                            if (error) {
                                showNotification(
                                    "top",
                                    "center",
                                    "Ocorreram erros, verifique sua conexão com a internet",
                                    "danger"
                                )
                            } else {
                                this.setState({
                                    lancamentos: data.data,
                                    pageCount: data.total,
                                    perPage: data.per_page,

                                })
                            }
                        }, pagina)

                } else {
                    this.getData()
                }
            }
        )
    }

    getData(page) {
        this.setState({isLoading: true})

        get(page, (error, data) => {
            this.setState({isLoading: false})
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {
                this.setState({
                    lancamentos: data.data,
                    pageCount: data.total,
                    perPage: data.per_page,
                })
            }
        })
    }

    render() {
        const {classes} = this.props
        const fields = [
            {value: "ano", label: "Ano do lançamento"},
            {
                value: "imovel_contribuinte_nome_razao_social",
                label: "Nome/Razão social do contribuinte"
            },
            {value: "imovel_contribuinte_id", label: "Cód. Contribuinte"},
            {value: "imovel_id", label: "Inscrição do Imóvel"}
        ]
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>Lançamentos de IPTU</h2>
                            </Grid>
                            <Grid item lg={2} xs={12}>
                                <Link
                                    to="/lancamento/create"
                                    style={{textDecoration: "none"}}
                                >
                                    <Button block color="success" round>
                                        <AddIcon/> Lançar IPTU
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Search
                            label="Pesquise aqui"
                            value={this.state.filterField}
                            onChangeText={this.filter}
                            onChangeFields={this.handleChangeFilters}
                            fields={fields}
                        />
                        <LoadingContent isLoading={this.state.isLoading}>
                            <Grid
                                className={classes.legend}
                                container
                                direction="row"
                                style={{marginBottom: "2%"}}
                            >
                                <Lens className={classes.dot + " " + classes.dotSuccess}/>
                                Lançamento Pago
                                <Lens className={classes.dot + " " + classes.dotInfo}/>
                                Lançamento Imune
                                <Lens className={classes.dot + " " + classes.dotWarning}/>
                                Lançamento Isento
                                <Lens className={classes.dot + " " + classes.dotDanger}/>
                                Lançamento na Dívida Ativa
                            </Grid>
                            <Paper className={classes.tableWrapper}>
                                <Table
                                    tableHeaderColor="primary"
                                    actions={[
                                        {
                                            title: "Visualizar Parcelas",
                                            icon: (
                                                <ChromeReaderMode
                                                    className={
                                                        classes.tableActionButtonIcon +
                                                        " " +
                                                        classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: key => this.showParcelas(key)
                                        },
                                        {
                                            title: "Imprimir Carnê",
                                            icon: (
                                                <FileCopy
                                                    className={
                                                        classes.tableActionButtonIcon +
                                                        " " +
                                                        classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: this.getCarne
                                        }
                                    ]}
                                    getRowColor={(key, classes) => {
                                        if (this.state.lancamentos[key].pago) {
                                            return classes.successTableRow
                                        } else if (this.state.lancamentos[key].lancado_na_divida) {
                                            return classes.dangerTableRow
                                        } else if (this.state.lancamentos[key].isento) {
                                            return classes.warningTableRow
                                        } else if (this.state.lancamentos[key].imune) {
                                            return classes.infoTableRow
                                        } else {
                                            return ""
                                        }
                                    }}
                                    tableHead={[
                                        "Ano do Lançamento",
                                        "Inscrição do Imóvel",
                                        "Cod. Contribuinte",
                                        "Contribuinte",
                                        "Alíquota",
                                        "Reajuste",
                                        "Valor do imposto"
                                    ]}
                                    tableData={this.state.lancamentos.map((item, key) => {
                                        return [
                                            item.ano + "",
                                            item.imovel_id + "",
                                            item.imovel.contribuinte_id ? item.imovel.contribuinte_id + "":"",
                                            item.imovel.contribuinte?item.imovel.contribuinte.nome_razao_social + "":"",
                                            `${numeral(parseFloat(item.aliquota)).format(
                                                "0.0,00"
                                            )}%`.slice(0, -2),
                                            `${numeral(parseFloat(item.reajuste)).format("0.0,00")}%`,
                                            numeral(parseFloat(item.valor_iptu))
                                                .format("0.0,00")
                                                .slice(0, -2),
                                            key
                                        ]
                                    })}
                                />
                                {(
                                    <TablePagination
                                        component="div"
                                        rowsPerPageOptions={[]}
                                        count={this.state.pageCount}
                                        rowsPerPage={this.state.perPage ? this.state.perPage : 0}
                                        page={this.state.page ? this.state.page : 0}
                                        backIconButtonProps={
                                            {"aria-label": "Previous Page"}}
                                        nextIconButtonProps={{
                                            "aria-label": "Next Page",
                                        }}
                                        SelectProps={{
                                            native: true
                                        }}

                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                )}
                            </Paper>
                        </LoadingContent>
                    </CardBody>
                </Card>

                <Dialog
                    open={this.state.modal}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    style={{zIndex: "3"}}
                >
                    <DialogTitle id="form-dialog-title">Parcelas</DialogTitle>
                    <DialogContent>
                        <Grid
                            className={classes.legend}
                            container
                            direction="row"
                            style={{marginBottom: "2%"}}
                        >
                            <Lens className={classes.dot + " " + classes.dotSuccess}/>
                            Parcela Paga
                            <Lens className={classes.dot + " " + classes.dotWarning}/>
                            Parcela Vencida
                        </Grid>
                        <form onSubmit={this.handleSaveParcela}>
                            <Paper className={classes.tableWrapper}>
                                <Table
                                    getRowColor={(key, classes) => {
                                        if (this.state.parcelas[key].pago) {
                                            return classes.successTableRow
                                        } else if (this.state.parcelas[key].vencido) {
                                            return classes.warningTableRow
                                        } else {
                                            return ""
                                        }
                                    }
                                    }

                                    tableHeaderColor="primary"
                                    fullWidth
                                    tableHead={[
                                        "#",
                                        "Descrição",
                                        "Vencimento",
                                        "Valor",
                                        "Data do pagamento"
                                    ]}
                                    tableData={this.state.parcelas.map((item, key) => {
                                        return [
                                            item.id + "",
                                            item.tipo_parcela.descricao,

                                            moment(item.vencimento).format("DD/MM/YYYY"),

                                            "R$" +
                                            numeral(parseFloat(item.valor))
                                                .format("0.0,00")
                                                .slice(0, -2),
                                            item.baixa
                                                ? moment(item.baixa.data_pagamento).format("DD/MM/YYYY")
                                                : "",
                                            key
                                        ]
                                    })}
                                />
                            </Paper>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

LancamentoIPTU = withRouter(LancamentoIPTU)
export default withStyles(logradouroStyle)(LancamentoIPTU)

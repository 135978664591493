import React from "react"

import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../assets/css/layout/contribuinteStyle"
import MenuDashboard from "../MenuDashboard"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../assets/css/theme"
import Build from "@material-ui/icons/Build"
import Autorenew from "@material-ui/icons/Autorenew"
import ProjetoMain from "./ProjetoMain"
import Class from "@material-ui/icons/Class"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { projetosTabChanged } from "../../actions"
import MudarVencimentoProjeto from "./MudarVencimentoProjeto"
import DamProjeto from "./DamProjeto"

const tab = [
  {
    name: "Cadastro",
    icon: <Build />
  },
  {
    name: "Alterar Vencimento",
    icon: <Autorenew />
  },
  {
    name: "DAM Projeto Civil",
    icon: <Class />
  }
]

class Projeto extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      field: 0
    }

    this.handleFieldChange = this.handleFieldChange.bind(this)
  }

  handleFieldChange(value) {
    this.props.projetosTabChanged(value)
  }

  render() {
    const { classes, field } = this.props
    return (
      <div className={classes.container}>
        <MuiThemeProvider theme={theme}>
          <MenuDashboard
            names={tab}
            onChange={this.handleFieldChange}
            value={field}
          />
          {field === 0 && <ProjetoMain />}
          {field === 1 && <MudarVencimentoProjeto />}
          {field === 2 && <DamProjeto />}
        </MuiThemeProvider>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    field: state.tabLayout.projetosTabSelected
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ projetosTabChanged }, dispatch)
}

Projeto = connect(
  mapStateToProps,
  mapDispatchToProps
)(Projeto)

export default withStyles(contribuinteStyle)(Projeto)

import React, {Component} from "react"
import PropTypes from "prop-types"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import Tooltip from "@material-ui/core/Tooltip"
import Button from "../CustomButtons/Button"
import Dotdotdot from "react-dotdotdot"
// core components
import tableStyle from "../../assets/css/components/tableStyle"

function sortBy(index, data, direction) {
    return [...data].sort((a, b) => {
        const aItemToCompare = a[index]
        const bItemToCompare = b[index]
        if (aItemToCompare > bItemToCompare) {
            return direction === "desc" ? -1 : 1
        }
        if (aItemToCompare < bItemToCompare) {
            return direction === "desc" ? 1 : -1
        }
        return 0
    })
}

class CustomTable extends Component {
    constructor() {
        super()
        this.state = {}
    }

    shouldComponentUpdate(nextProps, nextState) {
        var shouldUpdate = false
        Object.keys(nextProps).forEach(key => {
            var currentProp = this.props[key]
            var nextProp = nextProps[key]
            if (nextProp.constructor === Array) {
                shouldUpdate = !(nextProp.length === currentProp.length)

                if (shouldUpdate === false) {
                    shouldUpdate = !nextProp.every((e, index) => {
                        if (typeof e === "object") {
                            let equals = []
                            Object.keys(e).forEach(key => {
                                if (currentProp[index]) {
                                    equals.push(e[key] === currentProp[index][key])
                                }
                            })
                            return !equals.includes(false)
                        }
                        return currentProp.includes(e)
                    })
                    shouldUpdate =
                        (nextProp.length === 0 && currentProp.length > 0) || shouldUpdate
                }
            } else if (shouldUpdate === false) {
                shouldUpdate = !(nextProp === currentProp)
            }
        })
        const stateChanged = (nextState.orderByHeaderIndex !== this.state.orderByHeaderIndex) ||
            (nextState.orderDirection !== this.state.orderDirection)
        return shouldUpdate || stateChanged
    }

    sort(key) {
        const {orderByHeaderIndex, orderDirection} = this.state
        var direction = key === orderByHeaderIndex ? orderDirection : undefined
        if (!direction) {
            direction = "asc"
        } else if (direction === "asc") {
            direction = "desc"
        } else {
            direction = undefined
        }
        this.setState({
            orderByHeaderIndex: key,
            orderDirection: direction
        })
    }

    getActions = (key) => {
        if(this.props.actions == undefined){
            return []
        }
        let actions =   this.props.actions.filter(item => (item.id !== 10))
        if (this.props.filter) {
            let imovel = this.props.imoveis[key]
            if (imovel.cancelamento) {
                let cancelamentoActions = this.props.actions.filter(item => (item.id === 1 || item.id === 10))
                cancelamentoActions = cancelamentoActions.map(c=> {
                    if (c.id == 10) {
                        return {...c,title:imovel.cancelamento.motivo}
                    }
                    return c
                })
                return cancelamentoActions
            }
        }
        return actions
    }

    render() {
        const {
            classes,
            tableHead,
            tableData,
            tableHeaderColor,
            actions,
            getRowColor
        } = this.props
        const {orderByHeaderIndex, orderDirection} = this.state
        console.log(((orderByHeaderIndex !== undefined) && (orderDirection !== undefined)))
        const orderedTableData = ((orderByHeaderIndex !== undefined) && (orderDirection !== undefined)) ?
            sortBy(orderByHeaderIndex, tableData, orderDirection)
            : tableData
        return (
            <div className={classes.tableResponsive}>
                <Table className={classes.table}>
                    {tableHead !== undefined ? (
                        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                            <TableRow>
                                {tableHead.map((prop, key) => {
                                    return (
                                        <TableCell
                                            className={classes.tableCell + " " + classes.tableHeadCell}
                                            key={key}
                                        >
                                            {(key !== tableHead.length - 1) ? (<TableSortLabel
                                                active={orderByHeaderIndex === key && (orderDirection !== undefined)}
                                                direction={orderDirection}
                                                onClick={(e) => this.sort(key)}
                                            >
                                                {prop}
                                            </TableSortLabel>) : (prop)}

                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                    ) : null}
                    <TableBody>
                        {orderedTableData.map((prop, key) => {
                            return (
                                <TableRow
                                    key={key}
                                    className={
                                        classes.tableRow + " " +
                                        (getRowColor ? getRowColor(prop[prop.length - 1], classes) : "")
                                    }
                                > {
                                    prop.slice(0, prop.length - 1).map((prop, key) => {
                                        return (
                                            <TableCell className={classes.tableCell} key={key}>
                                                <Dotdotdot clamp={2}>{prop}</Dotdotdot>
                                            </TableCell>
                                        )
                                    })}

                                    <TableCell align="center" className={classes.tableActions}>

                                        {this.getActions(key).map((item, actionKey) => (
                                            <Tooltip
                                                key={actionKey}
                                                id="tooltip-top"
                                                title={item.title}
                                                placement="top"
                                                classes={{tooltip: classes.tooltip}}
                                            >
                                                <Button
                                                    color="transparent"
                                                    block
                                                    aria-label={item.title}
                                                    className={classes.tableActionButton}
                                                    onClick={e => item.onClick(prop[prop.length - 1])}
                                                >
                                                    {item.icon}
                                                </Button>
                                            </Tooltip>
                                        ))}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>)
    }
}

CustomTable.defaultProps = {
    tableHeaderColor: "gray"
}

CustomTable.propTypes = {
    classes: PropTypes.object.isRequired,
    tableHeaderColor: PropTypes.oneOf([
        "warning",
        "primary",
        "danger",
        "success",
        "info",
        "rose",
        "gray"
    ]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
}
export default withStyles(tableStyle)(CustomTable)

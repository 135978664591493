import React from "react"
import {Link, withRouter} from "react-router-dom"

import {get, search, cancelar, ativar, quitar, prescrever, protestar, executar, lancarCertidao} from "../../../util/dividaLancamento"
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn"
import Assignment from "@material-ui/icons/Assignment"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CheckBox from "@material-ui/icons/CheckBox"
import CardBody from "../../../components/Card/CardBody"
import LoadingContent from "../../../components/LoadingContent"
import Button from "../../../components/CustomButtons/Button"
import Search from "../../../components/Search"
import Table from "../../../components/Table/Table"
import Close from "@material-ui/icons/Close"
import logradouroStyle from "../../../assets/css/layout/logradouroStyle"
import TextField from "@material-ui/core/TextField"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import TablePagination from "@material-ui/core/TablePagination"
import {withStyles, Paper} from "@material-ui/core"
import housecall from "housecall"
import numeral from "../../../util/numeral"
import moment from "moment"
import Lens from "@material-ui/icons/Lens"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions"
import TrendingDown from "@material-ui/icons/TrendingDown"
import ReportProblem from "@material-ui/icons/ReportProblem"
import Description from "@material-ui/icons/Description"
import { print } from "../../../util/certidaodivida"
import {
    showNotification,
    hideWaiting,
    showWaiting,
    mapErrors
} from "../../../util/Constants"

const getModalName = (name) => {
  switch (name) {
    case "quitacao":
      return "quitar"
    case "prescricao":
      return "prescrever"
    case "execucao":
      return "executar"
    case "ativacao":
      return "ativar"
    case "protestacao":
      return "protestar"
    default: break
  }
}

const getModalNameSucsess = (name) => {
  switch (name) {
    case "quitacao":
      return "quitada"
    case "prescricao":
      return "prescrita"
    case "execucao":
      return "executada"
    case "ativacao":
      return "ativa"
    case "protestacao":
      return "protestada"
    default: break
  }
}

class DividaMain extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isSearch: false,
            lancamentos: [],
            pageCount: 0,
            perPage: 0,
            page: 0,
            searchText: "",
            filterField: "",
            parcelas: [],
            modal: false,
            modalRemover: false,
            modalOther: false,
            errors: {}
        }
        this.getData = this.getData.bind(this)
        this.filter = this.filter.bind(this)
        this.queue = housecall({concurrency: 1, cooldown: 0})
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeFilters = this.handleChangeFilters.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.showParcelas = this.showParcelas.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.setLoadingFalse = this.setLoadingFalse.bind(this)
        this.handleChange = this.handleChange.bind(this)

        this.lancarCertidao = this.lancarCertidao.bind(this)
        this.openLancarCertidao = this.openLancarCertidao.bind(this)
        this.closeLancarCertidao = this.closeLancarCertidao.bind(this)

        this.openOther = this.openOther.bind(this)
        this.closeOther = this.closeOther.bind(this)
        this.confirmar = this.confirmar.bind(this)

        this.openRemover = this.openRemover.bind(this)
        this.closeRemover = this.closeRemover.bind(this)
        this.cancelar = this.cancelar.bind(this)
    }

    confirmar() {
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                if (error.response.data.errors) {
                    this.setState({
                        errors: mapErrors(error)
                    })
                } else {
                    showNotification(
                        "top",
                        "center",
                        `Ocorreram erros! ${error.response.data.message}`,
                        "danger"
                    )
                }
            } else {
                showNotification(
                    "top",
                    "center",
                    `Dívida ${getModalNameSucsess(this.state.name)} com sucesso!`,
                    "success"
                )
                this.closeOther()
                this.getData()
            }
        }
        // this.state.name === "quitacao" ? quitar(this.state.id, response) : prescrever(this.state.id, response)
        switch (this.state.name) {
            case "quitacao":
              quitar(this.state.id, response)
                break
            case "prescricao":
              prescrever(this.state.id, response)
                break
            case "ativacao":
              ativar(this.state.id, response)
                break
            case "protestacao":
              protestar(this.state.id, response)
                break
            case "execucao":
              executar(this.state.id, response)
            break
            default: break
        }
    }

    openOther(key, name) {
        this.setState({
            modalOther: true,
            name: name,
            id: this.state.lancamentos[key].id
        })
    }

    closeOther() {
        this.setState({
            modalOther: false
        })
    }

    cancelar() {
        let params = {
            motivo: this.state.motivo
        }
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                if (error.response.data.errors) {
                    this.setState({
                        errors: mapErrors(error)
                    })
                } else {
                    showNotification(
                        "top",
                        "center",
                        `Ocorreram erros! ${error.response.data.message}`,
                        "danger"
                    )
                }
            } else {
                showNotification(
                    "top",
                    "center",
                    "Dívida cancelada com sucesso!",
                    "success"
                )
                this.closeRemover()
                this.getData()
            }
        }
        cancelar(this.state.id, params, response)
    }

    openRemover(key) {
        this.setState({
            modalRemover: true,
            motivo: "",
            id: this.state.lancamentos[key].id
        })
    }

    closeRemover() {
        this.setState({
            modalRemover: false
        })
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        errors[name] = undefined
        this.setState(
            {
                [name]: event.target.value,
                errors: errors
            }
        )
    }

    handleClose = () => {
        this.setState({modal: false})
    }

    componentWillMount() {
        this.getData()
    }

    componentDidMount() {
        this.queue.on("idle", this.setLoadingFalse)
    }

    handleChangeFilters = event => {
        this.setState({filterField: event.target.value}, () => {
            if (this.state.searchActive) {

                this.filter({target: {value: this.state.searchText}})
            }
        })
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    showParcelas(key) {
        this.setState({
            modal: true,
            parcelas: this.state.lancamentos[key].parcelas
        })
    }

    handleChangePage = (event, page) => {
        var pagina;
        pagina = page + 1;
        this.setState({page: page})
        if (page < 0) {
            return;
        }
        if (this.state.searchText.toString().trim() != "") {
            this.filter({target: {value: this.state.searchText}}, pagina, page)
            return;
        }
        this.getData(pagina)
    };

    setLoadingFalse() {
        this.setState({
            isLoading: false
        })
    }

    filter(event, pagina, page) {

        let searchText = event.target.value;
        let newState = {
            isLoading: true,
            searchText: searchText,
            searchActive: searchText !== ""
        }
        if(pagina == null){
            newState["page"] = 0
        }

        this.setState(newState
            ,
            () => {
                if (this.state.searchActive) {
                    // if (!this.state.isLoading) {
                    //   this.toggleLoading()
                    // }
                    let filtersCheckbox = this.state.filterField || ""
                    // this.queue.push(() =>
                    search(searchText, filtersCheckbox, undefined, (error, data) => {
                        this.setState({isLoading:false})
                        if (error) {
                            showNotification(
                                "top",
                                "center",
                                "Ocorreram erros, verifique sua conexão com a internet",
                                "danger"
                            )
                        } else {

                            this.setState({
                                lancamentos: data.data,
                                pageCount: data.total,
                                perPage: data.per_page,
                            })
                        }
                    }, pagina)
                    // )
                } else {
                    this.getData()
                }
            },
        )
    }

    getData(page) {
        this.setState({isLoading: true})
        get(page, (error, data) => {
            this.setState({isLoading: false})
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {
                this.setState({
                    lancamentos: data.data,
                    pageCount: data.total,
                    perPage: data.per_page,
                })
            }
        })
    }

    lancarCertidao() {

        showWaiting()
        lancarCertidao(this.state.id, this.state.ano, (error, data) => {
          if (error) {
            hideWaiting()
            if (error.response.data.errors) {
              this.setState({
                errors: mapErrors(error)
              })
            } else if (error.response.data.message) {
              showNotification(
                "top",
                "center",
                error.response.data.message,
                "danger"
              )
            } else {
              showNotification(
                "top",
                "center",
                "Ocorreram erros, verifique o formulário e tente novamente",
                "danger"
              )
            }
          } else {
            this.setState({
              certidaoId: data.id
            }, () => {
              this.imprimirCertidao()
            })
          }
        })
    }
    openLancarCertidao(key) {
        this.setState({
            modalLancarDivida: true,
            ano: "",
            id: this.state.lancamentos[key].id
        })
    }
    
    imprimirCertidao() {
        var certidaoId = this.state.certidaoId
        let response = (error, data) => {
            hideWaiting()
            if (error) {
            showNotification(
                "top",
                "center",
                "Ocorreram erros, verifique sua conexão e tente novamente",
                "danger"
            )
            } else {
            var url = URL.createObjectURL(data)
            let tempLink = document.createElement("a")
            tempLink.setAttribute("target", "_blank")
            tempLink.setAttribute("href", `${url}`)
            tempLink.setAttribute("style", "display:none;")
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            this.closeLancarCertidao()
            }
        }
        showWaiting()
        print(certidaoId, response)
    }
    
    closeLancarCertidao() {
        this.setState({
          modalLancarDivida: false,
        })
    }

    render() {
        const {classes} = this.props
        const fields = [
            {value: "id", label: "Código da Dívida"},
            {
                value: "contribuinte_id",
                label: "Código do Contribuinte"
            },
            {value: "contribuinte_nome_razao_social", label: "Nome/Razão Social Contribuinte"},
            {value: "origem", label: "Origem"},
            { value: "situacao", label: "Situação"}
        ]
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>Dívida Ativa</h2>
                            </Grid>
                            <Grid item lg={2} xs={12}>
                                <Link
                                    to="/lancamentodivida/create"
                                    style={{textDecoration: "none"}}
                                >
                                    <Button block color="success" round>
                                        <AddIcon/> Lançar Dívida
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Search
                            label="Pesquise aqui"
                            value={this.state.filterField}
                            onChangeText={this.filter}
                            onChangeFields={this.handleChangeFilters}
                            fields={fields}
                        />
                        <LoadingContent isLoading={this.state.isLoading}>
                            <Grid
                                className={classes.legend}
                                container
                                direction="row"
                                style={{marginBottom: "2%"}}
                            >
                                <Lens className={classes.dot + " " + classes.dotSuccess}/>
                                Paga
                                <Lens className={classes.dot + " " + classes.dotDanger}/>
                                Cancelada
                                <Lens className={classes.dot + " " + classes.dotPurple}/>
                                Em pagamento
                                <Lens className={classes.dot + " " + classes.dotInfo}/>
                                Em análise
                                <Lens className={classes.dot + " " + classes.dotWarning}/>
                                Inativa
                                <Lens className={classes.dot + " " + classes.dotPrimary}/>
                                Isento
                                <Lens className={classes.dot + " " + classes.dotGray}/>
                                Quitado
                                <Lens className={classes.dot + " " + classes.dotRose}/>
                                Prescrito
                                <Lens className={classes.dot + " " + classes.dotSecondary} />
                                Em execução
                                <Lens className={classes.dot + " " + classes.dotBlack} />
                                Em protesto
                            </Grid>
                            <Paper className={classes.tableWrapper}>
                                <Table
                                    getRowColor={(key, classes) => {
                                        const divida = this.state.lancamentos[key]
                                        switch (divida.situacao_id) {
                                            case 1:
                                                if (divida.pago) {
                                                    return classes.successTableRow
                                                }else if (divida.em_pagamento) {
                                                    return classes.purpleTableRow
                                                }else{
                                                    return ""
                                                }
                                            case 2:
                                                if (divida.pago) {
                                                    return classes.successTableRow
                                                }else if (divida.em_pagamento) {
                                                    return classes.purpleTableRow
                                                }else{
                                                    return classes.secondaryTableRow
                                                }
                                            case 3:
                                                return classes.infoTableRow
                                            case 4:
                                                return classes.warningTableRow
                                            case 5:
                                                return classes.dangerTableRow
                                            case 6:
                                                return classes.primaryTableRow
                                            case 7:
                                                return classes.grayTableRow
                                            case 8:
                                                return classes.roseTableRow
                                            case 9:
                                                return classes.blackTableRow
                                            default:
                                                if (divida.pago) {
                                                    return classes.successTableRow
                                                }
                                                if (divida.em_pagamento) {
                                                    return classes.purpleTableRow
                                                }
                                        }
                                        return ""

                                    }}
                                    tableHeaderColor="primary"
                                    tableHead={[
                                        "#",
                                        "Competência",
                                        "Contribuinte",
                                        "Código da Receita",
                                        "Valor",
                                        "Histórico",
                                        "Código de origem"
                                    ]}
                                    actions={[
                                        {
                                            title: "Protestar",
                                            icon: (
                                            <TrendingDown
                                                className={
                                                classes.tableActionButtonIcon + " " + classes.black
                                                }
                                            />
                                            ),
                                            onClick: key => this.openOther(key, "protestacao")
                                        },
                                        {
                                            title: "Prescrição",
                                            icon: (
                                                <Assignment
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: key => this.openOther(key, "prescricao")
                                        },
                                        {
                                            title: "Ativar",
                                            icon: (
                                                <CheckBox
                                                className={
                                                    classes.tableActionButtonIcon + " " + classes.dotSuccess
                                                }
                                                />
                                            ),
                                            onClick: key => this.openOther(key, "ativacao")
                                        },
                                        {
                                            title: "Executar",
                                            icon: (
                                                <ReportProblem
                                                className={
                                                    classes.tableActionButtonIcon + " " + classes.dotPurple
                                                }
                                                />
                                            ),
                                            onClick: key => this.openOther(key, "execucao")
                                        },
                                        {
                                            title: "Quitação",
                                            icon: (
                                                <AssignmentTurnedIn
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            ),
                                            onClick: key => this.openOther(key, "quitacao")
                                        },
                                        {
                                            title: "Cancelar Dívida",
                                            icon: (
                                                <Close
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.close
                                                    }
                                                />
                                            ),
                                            onClick: this.openRemover
                                        },
                                        {
                                            title: "Lançar certidão individual",
                                            icon: (
                                                <Description
                                                className={
                                                    classes.tableActionButtonIcon + " " + classes.close
                                                }
                                                />
                                            ),
                                            onClick: this.openLancarCertidao
                                        }
                                    ]}
                                    tableData={this.state.lancamentos.map((item, key) => {
                                        return [
                                            item.id + "",
                                            item.competencia + "",
                                            ( item.contribuinte_id?item.contribuinte_id+' - ':'')+ item.contribuinte.nome_razao_social,
                                            item.receita_id + "",
                                            `${numeral(parseFloat(item.valor)).format(
                                                "0.0,00"
                                            )}%`.slice(0, -2),
                                            item.historico,
                                            item.codigo_de_origem,
                                            key
                                        ]
                                    })}
                                />

                                {(
                                    <TablePagination

                                        rowsPerPageOptions={[]}
                                        component="div"
                                        count={this.state.pageCount}
                                        rowsPerPage={this.state.perPage ? this.state.perPage : 0}
                                        page={this.state.page ? this.state.page : 0}
                                        backIconButtonProps={{
                                            "aria-label": "Previous Page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "Next Page"
                                        }}
                                        SelectProps={{
                                            native: true
                                        }}

                                        onChangePage={this.handleChangePage}

                                    />
                                )}
                            </Paper>
                        </LoadingContent>
                    </CardBody>
                </Card>

                <Dialog
                    open={this.state.modal}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    style={{zIndex: "3"}}
                >
                    <DialogTitle id="form-dialog-title">Parcelas</DialogTitle>
                    <DialogContent>
                        <Grid
                            className={classes.legend}
                            container
                            direction="row"
                            style={{marginBottom: "2%"}}
                        >
                            <Lens className={classes.dot + " " + classes.dotSuccess}/>
                            Parcela Paga
                        </Grid>
                        <form onSubmit={this.handleSaveParcela}>
                            <Paper className={classes.tableWrapper}>
                                <Table
                                    getRowColor={(key, classes) =>
                                        this.state.parcelas[key].pago ? classes.successTableRow : ""
                                    }
                                    tableHeaderColor="primary"
                                    fullWidth
                                    tableHead={[
                                        "#",
                                        "Descrição",
                                        "Vencimento",
                                        "Valor",
                                        "Data do pagamento"
                                    ]}
                                    tableData={this.state.parcelas.map((item, key) => {
                                        return [
                                            item.id + "",
                                            item.tipo_parcela.descricao,

                                            moment(item.vencimento).format("DD/MM/YYYY"),

                                            "R$" +
                                            numeral(parseFloat(item.valor))
                                                .format("0.0,00")
                                                .slice(0, -2),
                                            item.baixa
                                                ? moment(item.baixa.data_pagamento).format("DD/MM/YYYY")
                                                : ""
                                        ]
                                    })}
                                />
                            </Paper>
                        </form>
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.modalRemover}
                    onClose={this.closeRemover}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{zIndex: "3"}}
                >
                    <DialogTitle id="alert-dialog-title">
                        Você tem certeza que deseja cancelar a dívida?</DialogTitle>
                    <DialogContent>
                        <Typography variant="h5" gutterBottom color="error">
                            Qual o motivo do cancelamento?
                        </Typography>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-name"
                                    label="Motivo"
                                    name="motivo"
                                    required
                                    fullWidth
                                    value={this.state.motivo}
                                    onChange={this.handleChange}
                                    error={this.state.errors.motivo}
                                    helperText={this.state.errors.motivo}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeRemover} color="danger">
                            Cancelar
                        </Button>
                        <Button onClick={this.cancelar} color="success">
                            Remover
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                open={this.state.modalLancarDivida}
                onClose={this.closeLancarCertidao}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ zIndex: "3" }}
                >
                <DialogTitle id="alert-dialog-title">
                    Você tem certeza que deseja lançar certidão apenas para essa dívida?</DialogTitle>
                <DialogContent>
                    <Typography variant="h5" gutterBottom color="error">
                    Qual o ano da certidão?
                    </Typography>
                    <Grid container direction="row" spacing={16}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                        id="outlined-name"
                        label="Ano da certidão"
                        name="ano"
                        required
                        fullWidth
                        value={this.state.ano}
                        onChange={this.handleChange}
                        error={this.state.errors.ano}
                        helperText={this.state.errors.ano}
                        margin="normal"
                        type="number"
                        variant="outlined"
                        />
                    </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeLancarCertidao} color="danger">
                    Cancelar
                    </Button>
                    <Button onClick={this.lancarCertidao} color="success">
                    Lançar
                    </Button>
                </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.modalOther}
                    onClose={this.closeOther}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{zIndex: "3"}}
                >
                    <DialogTitle id="alert-dialog-title">
                        Você tem certeza que deseja {getModalName(this.state.name)} a dívida?</DialogTitle>
                    <DialogActions>
                        <Button onClick={this.closeOther} color="danger">
                            Cancelar
                        </Button>
                        <Button onClick={this.confirmar} color="success">
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

DividaMain = withRouter(DividaMain)
export default withStyles(logradouroStyle)(DividaMain)

import React from 'react'

import { withStyles, MenuItem } from '@material-ui/core';
import secaoStyle from "../../../assets/css/layout/secaoStyle";

import Button from "../../../components/CustomButtons/Button";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LoadingContent from "../../../components/LoadingContent";

import { simpleSearch as searchContribuinte } from "../../../util/contribuinte"
import AsyncSelect from "../../../components/AsyncSelect"
import SearchFields from "../../../components/SearchFields"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { contribuinteChanged } from "../../../actions"
import { showNotification, mapErrors, showWaiting, hideWaiting } from '../../../util/Constants';

import { get } from "../../../util/refis";
import { restore } from "../../../util/refinanciamento"




class RefinanciamentoRestore extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            refis_id: '',
            isLoading: false,
            refis: [],
            errors: {}
        }
        this.handleChange = this.handleChange.bind(this)
        this.loadOptions = this.loadOptions.bind(this)
        this.init = this.init.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    componentWillMount(){
        this.init()
    }

    init(){
        let response = (error, data) => {

            if(error){

            }else{
                let newData = data.data.map(refis => {
                    return {id: refis.id, lei: refis.lei}
                })
                this.setState({refis : newData});
            }
        }

        get(null, response)
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        let value = event.target.value
    
        if (event.target.type === "checkbox") {
          value = event.target.checked
        }

        this.setState({
            [name] : value
        })
    }

    handleSave(event){
        event.preventDefault()
        
        let formData = {
            contribuinte_id: this.state.contribuinte_id || "",
            refis_id: this.state.refis_id
        }

        let response = (error, data) => {
            showWaiting()
            if(error){
                hideWaiting()
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
                
                console.log(this.setState({ errors : mapErrors(error)}))
            }else{
                hideWaiting()
                showNotification(
                    "top",
                    "center",
                    "Refinancimantos restonaram a dívida ativa.",
                    "success"
                )
            }
        }

        restore(formData, response)
    }

    loadOptions(inputValue, callback) {
        searchContribuinte(
          inputValue,
          this.state.filterField || "",
          undefined,
          (error, data) => {
            if (error) {
              showNotification(
                "top",
                "center",
                "Ocorreram erros, verifique sua conexão com a internet",
                "danger"
              )
              callback([])
            } else {
              let contribuintes = data.data.map(contribuinte => {
                return {
                  value: contribuinte.id,
                  label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                    ""} - ${contribuinte.cpf_cnpj || ""}`
                }
              })
              this.setState({
                contribuintes: data.data
              })
              callback(contribuintes)
            }
          }
        )
      }

    render() {
        const { classes } = this.props;
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código do contribuinte" },
            { value: "cpf_cnpj", label: "Documento" },
            { value: "endereco", label: "Endereço" },
            { value: "nome_fantasia", label: "Nome Fantasia" },
            { value: "nome_razao_social", label: "Razão Social" }
          ]
        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                <form onSubmit={this.handleSave}>
                <div>
                    <Card>
                        <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>
                                Retornar refinancimantos 
                                </h2>
                            </Grid>
                        </Grid>
                        </CardHeader>
                        <CardBody>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SearchFields
                                        name="filterField"
                                        value={this.state.filterField || ""}
                                        onChangeFields={this.handleChange}
                                        fields={fields}
                                    />
                                    <Typography color="textSecondary" variant="caption">
                                        Pesquise o Contribuinte
                                    </Typography>
                                    <AsyncSelect
                                        className={
                                        `form-control form-control-alternative form-select Select-menu-outer ${this.props.error ? "has-danger" : ""
                                        }`
                                        }
                                        value={this.state.contribuinte_id}
                                        onChange={this.handleChange}
                                        loadOptions={this.loadOptions}
                                        error={this.state.errors.contribuinte_id}
                                        helperText={this.state.errors.contribuinte_id}
                                        name="contribuinte_id"
                                        placeholder="Contribuinte"
                                        message="Pesquise o contribuinte"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item lg={12} xs={12}>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        name="refis_id"
                                        label="REFIS"
                                        fullWidth
                                        value={this.state.refis_id}
                                        error={this.state.errors.refis_id}
                                        helperText={this.state.errors.refis_id}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        required
                                    >
                                        {this.state.refis.map((option, key) => (
                                        <MenuItem key={key} value={option.id}>
                                            {option.lei}
                                        </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </CardBody>
                        <CardFooter>
                            <Grid container justify="center">
                            <Grid item lg={2} xs={12}>
                                <Button block color="info" round onClick={this.handleSave}>
                                    retornar
                                </Button>
                            </Grid>
                            </Grid>
                        </CardFooter>
                    </Card>
                </div>
                </form>
                </LoadingContent>
            </div>
        )
    }


}

export default withStyles(secaoStyle)(RefinanciamentoRestore);

import React from "react"
import { Link } from "react-router-dom"
import { withStyles, Paper, Typography, DialogTitle, Dialog, DialogContent, TextField, DialogActions } from "@material-ui/core"
import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle"
import Button from "../../../components/CustomButtons/Button"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import AddIcon from "@material-ui/icons/Add"
import Grid from "@material-ui/core/Grid"
import Table from "../../../components/Table/Table"
import TablePagination from "@material-ui/core/TablePagination"
import { get, search, print, put } from "../../../util/certidaodivida"
import LoadingContent from "../../../components/LoadingContent"
import Search from "../../../components/Search"
import {
  showNotification,
  hideWaiting,
  showWaiting
} from "../../../util/Constants"
import housecall from "housecall"
import Print from "@material-ui/icons/Print"
import Edit from "@material-ui/icons/Edit"
import AdBlockDetect from "react-ad-block-detect"
import PrintCertidoesModal from "../../PrintCertidoesModal"

class LancamentoCda extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      error: "",
      pageCount: 0,
      perPage: 0,
      page: 0,
      certidoes: [],
      certidao: {},
      searchText: "",
      field: 0,
      errors: {}
    }
    this.toggleUpdateModal = this.toggleUpdateModal.bind(this)
    this.updateCertidao = this.updateCertidao.bind(this)
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.getData = this.getData.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.filter = this.filter.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.setLoadingFalse = this.setLoadingFalse.bind(this)
    this.imprimirCertidao = this.imprimirCertidao.bind(this)
  }

  toggleUpdateModal() {
    this.setState(state => ({
      showUpdateModal: !state.showUpdateModal
    }))
  }

  componentWillMount() {
    this.getData()
  }

  handlePageClick(data) {
    //this.getData(data.selected + 1);
  }

  handleInputChange(e) {
    let name = e.target.name
    let value = e.target.value

    let errors = { ...this.state.errors }
    errors[name] = undefined
    this.setState({
      [name]: value
    })
  }

  handleChange = event => {
    this.setState({ filterField: event.target.value }, () => {
      if (this.state.searchActive) {
        this.filter({ target: { value: this.state.searchText } })
      }
    })
  }

  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  setLoadingFalse() {
    this.setState(
      {
        isLoading: false
      }
    )
  }
  componentDidMount() {
    this.queue.on("idle", this.setLoadingFalse)
  }
  filter(event, pagina) {
    let searchText = event.target.value
    let newState = {
      isLoading: true,
      searchText: searchText,
      searchActive: searchText !== ""
    }
    if (pagina == null) {
      newState["page"] = 0
    }
    this.setState(newState
      ,
      () => {
        if (this.state.searchActive) {

          let filtersCheckbox = this.state.filterField || ""

          search(searchText, filtersCheckbox, undefined, (error, data) => {
            this.setState({ isLoading: false })
            if (error) {
              showNotification(
                "top",
                "center",
                "Ocorreram erros, verifique sua conexão com a internet",
                "danger"
              )
            } else {
              if (this.state.searchText !== "") {
                this.setState({
                  certidoes: data.data,
                  pageCount: data.total,
                  perPage: data.per_page
                })
              }
            }
          }, pagina)

        } else {
          this.getData()
        }
      }
    )
  }
  getData(page) {
    this.setState({ isLoading: true })
    get(page, (error, data) => {

      this.setState({ isLoading: false })
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
      } else {
        this.setState(
          {
            certidoes: data.data,
            pageCount: data.total,
            perPage: data.per_page
          }
        )
      }
    })
  }
  handleChangePage = (event, page) => {
    var pagina;
    pagina = page + 1;
    this.setState({ page: page })
    if (page < 0) {
      return;
    }
    if (this.state.searchText.toString().trim() != "") {
      this.filter({ target: { value: this.state.searchText } }, pagina, page)
      return;
    }
    this.getData(pagina)
  };

  handleFieldChange(value) {
    this.setState({
      field: value
    })
  }
  toggleModal(name) {
    this.setState(state => ({
      [name]: !state[name]
    }))
  }
  imprimirCertidao(index) {
    var certidaoId = this.state.certidoes[index].id
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão e tente novamente",
          "danger"
        )
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    showWaiting()
    print(certidaoId, response)
  }
  updateCertidao(e) {
    e.preventDefault()
    const params = {
      numero_do_processo: this.state.numero_do_processo || this.state.certidao.numero_do_processo,
      acordao: this.state.acordao || this.state.certidao.acordao
    }
    showWaiting()
    put(this.state.certidao.id, params, (error) => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão e tente novamente",
          "danger"
        )
      } else {
        showNotification(
          "top",
          "center",
          "Certidão atualizada com sucesso",
          "success"
        )
        this.toggleUpdateModal()
        this.getData()
      }
    })
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código da certidão" },
      { value: "ano", label: "Ano" },
      { value: "numero", label: "Número da certidão" },
      { value: "contribuinte_id", label: "Código do contribuinte" },
      {
        value: "contribuinte_nome_razao_social",
        label: "Nome/Razão social do contribuinte"
      }
    ]
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container spacing={32} direction="row">
              <Grid item lg={7} xs={12}>
                <h2 className={classes.cardTitleWhite}>
                  Lançamento Certidão da Dívida Ativa
                </h2>
              </Grid>
              <Grid item lg={2} xs={12}>
                <Link to="/lanccda/create" style={{ textDecoration: "none" }}>
                  <Button block color="success" round>
                    <AddIcon /> Lançar
                  </Button>
                </Link>
              </Grid>
              <Grid item lg={3} xs={12}>
                <Button
                  block
                  color="info"
                  round
                  onClick={e => this.toggleModal("showModal")}
                >
                  <Print /> Imprimir certidões
                </Button>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Search
              label="Pesquise aqui"
              value={this.state.filterField}
              onChangeText={this.filter}
              onChangeFields={this.handleChange}
              fields={fields}
            />
            <LoadingContent isLoading={this.state.isLoading}>
              <Paper className={classes.tableWrapper}>
                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "Código",
                    "Ano",
                    "Número",
                    "Contribuinte",
                    "Nº Documento"
                  ]}
                  tableData={this.state.certidoes.map((item, key) => {
                    return [
                      item.id + "",
                      item.ano + "",
                      item.numero,
                      item.contribuinte.nome_razao_social,
                      item.contribuinte.cpf_cnpj,
                      key
                    ]
                  })}
                  actions={[
                    {
                      title: "Imprimir certidão",
                      icon: (
                        <Print
                          className={
                            classes.tableActionButtonIcon + " " + classes.ficha
                          }
                        />
                      ),
                      onClick: key => this.imprimirCertidao(key)
                    },
                    {
                      title: "Alterar número do processo",
                      icon: (
                        <Edit
                          className={
                            classes.tableActionButtonIcon + " " + classes.edit
                          }
                        />
                      ),
                      onClick: (key) => {
                        this.setState({
                          certidao: this.state.certidoes[key]
                        });
                        this.toggleUpdateModal()
                      }
                    }
                  ]}
                />
                {(
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[]}
                    count={this.state.pageCount}
                    rowsPerPage={this.state.perPage ? this.state.perPage : 0}
                    page={this.state.page ? this.state.page : 0}
                    backIconButtonProps={{
                      "aria-label": "Previous Page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page"
                    }}
                    SelectProps={{
                      native: true
                    }}

                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            </LoadingContent>
          </CardBody>
        </Card>
        <PrintCertidoesModal
          toggleModal={e => this.toggleModal("showModal")}
          showModal={this.state.showModal}
        />
        <AdBlockDetect>
          <Dialog open={true} aria-labelledby="form-dialog-title">
            <form onSubmit={this.handleSave}>
              <DialogContent>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Typography variant="h5" gutterBottom>
                      Por favor, desabilite o ADBlock para impressão de
                      certidões.
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
            </form>
          </Dialog>
        </AdBlockDetect>
        <Dialog open={this.state.showUpdateModal} aria-labelledby="form-dialog-title" style={{ zIndex: "3" }}>
          <form onSubmit={this.updateCertidao}>
            <DialogTitle>
              Alterar dados da certidão
          </DialogTitle>
            <DialogContent>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-select-currency"
                    name="numero_do_processo"
                    label="Número do processo"
                    required
                    fullWidth
                    error={this.state.errors.numero_do_processo}
                    helperText={this.state.errors.numero_do_processo}
                    defaultValue={this.state.certidao.numero_do_processo}
                    onChange={this.handleInputChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-select-currency"
                    name="acordao"
                    label="Acórdão"
                    required
                    fullWidth
                    error={this.state.errors.acordao}
                    helperText={this.state.errors.acordao}
                    defaultValue={this.state.certidao.acordao}
                    onChange={this.handleInputChange}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={this.toggleUpdateModal} color="danger">
                Cancelar
              </Button>
              <Button type="submit" color="success">
                Atualizar
            </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    )
  }
}

export default withStyles(contribuinteStyle)(LancamentoCda)

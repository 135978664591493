import axios from "axios"
import { defaultHeaders } from "./Constants"

export function get(page, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `secoes${page ? `?page=${page}` : ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getFields(callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "secoes/create";
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function post(params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "secoes"
  axios
    .post(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function put(id, params, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + "secoes/" + id
  axios
    .put(url, params, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function edit(id, callBack) {
  let config = {
    headers: defaultHeaders()
  }
  let url = process.env.REACT_APP_API + `secoes/${id}/edit`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function search(searchText, field, onStart, callBack,pagina) {
  let config = {
    headers: defaultHeaders(),
    data: { a: "a" }
  }
  let url =
    process.env.REACT_APP_API +
    `search/secoes?text=${searchText}&fields=${field}`

  if(pagina != null){
    url += `&page=${pagina}`
  }
  if (onStart) {
    onStart()
  }
  return axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

export function getRelatorio(secoes, tipo, label, value, order, callBack) {
  let config = {
    headers: defaultHeaders(),
    responseType: "blob"
  }
  let url = process.env.REACT_APP_API + `relatorios/secoes?secao=${secoes || ""}&tipo=${tipo || ""}&logradouro=${label || ""}&logradouro_id=${value || ""}&order=${order || ""}`
  axios
    .get(url, config)
    .then(response => {
      callBack(undefined, response.data)
    })
    .catch(error => {
      callBack(error)
    })
}

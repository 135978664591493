import React from "react"

import {
  showNotification,
  showWaiting,
  hideWaiting
} from "../../../util/Constants"
import logradouroStyle from "../../../assets/css/layout/logradouroStyle"
import { withStyles, MenuItem } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Button from "../../../components/CustomButtons/Button"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import Grid from "@material-ui/core/Grid"
import AdBlockDetect from "react-ad-block-detect"
import { search } from "../../../util/logradouro"
import { search as searchContribuinte } from "../../../util/contribuinte"
import AsyncSelect from "../../../components/AsyncSelect"
import SearchFields from "../../../components/SearchFields"
import { relatorioDeImoveis } from "../../../util/imovel"

import housecall from "housecall"


class ImoveisRelatorio extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      modal: false,
      nome: "",
      logradouros: [],
      id_logradouro: "",
      secoesLogradouro: [],
      secoes: [],
      cod_secao: "",
      group: "list",
      order: "secao"
    }
    this.handleChange = this.handleChange.bind(this)
    this.gerarRelatorio = this.gerarRelatorio.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.loadLogradouro = this.loadLogradouro.bind(this)
    this.getSecoesByLogradouro = this.getSecoesByLogradouro.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
  }
  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState({
      [name]: event.target.value,
      errors: errors
    }, () => {
      if (this.state.id_logradouro && name === "id_logradouro") {
        this.setState({
          secoes: this.getSecoesByLogradouro(this.state.id_logradouro.value)
        })
      }
    })
  }
  getSecoesByLogradouro(id) {
    return this.state.logradouros.filter(item => {
      return id === item.id
    })[0].secoes
  }
  gerarRelatorio(event) {
    event.preventDefault()
    showWaiting()
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
      }
    }
    relatorioDeImoveis((this.state.contribuinte_id || { value: undefined }).value, this.state.id_secao, (this.state.id_logradouro || { value: undefined }).value, this.state.natureza_id, response)
  }

  loadLogradouro(inputValue, callback) {
    this.queue.push(() =>
    search(inputValue, undefined, undefined, (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
        callback([])
      } else {
        let lograd = data.data.map(logradouro => {
          return { value: logradouro.id, label: logradouro.nome }
        })
        this.setState({
          logradouros: data.data
        }, () => {
          console.log("LOG: ", this.state.logradouros)
        })
        callback(lograd)
      }
    }) 
    )
  }

  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {
          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`
            }
          })
          this.setState({
            contribuintes: data.data
          })
          callback(contribuintes)
        }
      }
    )
  }

  render() {
    const { classes } = this.props
    
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" }
    ]
    return (
      <div className={classes.container}>
        <Card>
          <CardHeader color="primary">
            <Grid container direction="row">
              <Grid item lg={10} xs={12}>
                <h2 className={classes.cardTitleWhite}>
                  Relatório de Imóveis
                </h2>
              </Grid>
            </Grid>
          </CardHeader>
          <CardBody>
            <Grid container direction="row" style={{ marginTop: "2%" }}>
              <Divider />
              <Typography
                className={classes.dividerFullWidth}
                color="textSecondary"
                variant="caption"
              >
                Filtrar relatório por nome de Logradouros ou deixe vazio para a
                opção de TODOS
              </Typography>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <SearchFields
                  name="filterField"
                  value={this.state.filterField || ""}
                  onChangeFields={this.handleChange}
                  fields={fields}
                />
                <Typography color="textSecondary" variant="caption">
                  Pesquise o Contribuinte
                </Typography>
                <AsyncSelect
                  className={`form-control form-control-alternative form-select ${
                    this.props.error ? "has-danger" : ""
                    }${classes.teste}`}
                  value={this.state.contribuinte_id}
                  onChange={this.handleChange}
                  noResultsText="Nenhum resultado"
                  loadOptions={this.loadOptions}
                  loadingMessage={() => "Carregando..."}
                  error={this.state.errors.contribuinte_id}
                  helperText={this.state.errors.contribuinte_id}
                  name="contribuinte_id"
                  placeholder="Contribuinte"
                  message="Pesquise o contribuinte"
                />
                
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16} style={{marginTop: "2%"}}>
              <Typography color="textSecondary" variant="caption">
                  Pesquise o Logradouro
                </Typography>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AsyncSelect
                  className={
                    `form-control form-control-alternative form-select Select-menu-outer ${
                      this.props.error ? "has-danger" : ""
                      }`
                  }
                  noResultsText="Nenhum resultado"
                  loadingMessage={() => "Carregando..."}
                  error={this.state.errors.id_logradouro}
                  helperText={this.state.errors.id_logradouro}
                  value={this.state.id_logradouro}
                  onChange={this.handleChange}
                  loadOptions={this.loadLogradouro}
                  name="id_logradouro"
                  placeholder="Logradouro"
                  message="Pesquise o logradouro"
                />
              </Grid>
            </Grid>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  select
                  disabled={this.state.secoes.length === 0}
                  name="id_secao"
                  label={this.state.secoes.length === 0 ? "Selecione um logradouro para ver suas seções" : "Selecione uma seção ou deixe em deixe em branco para considerar TODOS"}
                  fullWidth
                  value={this.state.id_secao}
                  error={this.state.errors.secao_id}
                  helperText={this.state.errors.secao_id}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                >
                  {this.state.secoes.map((option, key) => (
                    <MenuItem key={key} value={option.id}>
                      {option.secao}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  select
                  name="natureza_id"
                  label={"Natureza"}
                  fullWidth
                  value={this.state.natureza_id}
                  error={this.state.errors.natureza_id}
                  helperText={this.state.errors.natureza_id}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                >
                    <MenuItem value={1}>
                      Predial
                    </MenuItem>
                    <MenuItem value={2}>
                      Territorial
                    </MenuItem>
                </TextField>
              </Grid>
            </Grid>

          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={2}>
                <Button block color="info" round onClick={this.gerarRelatorio}>
                  Gerar
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>
        {/* MODAL */}

        <AdBlockDetect>
          <Dialog open={true} aria-labelledby="form-dialog-title">
            <form onSubmit={this.handleSave}>
              <DialogContent>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="row"
                >
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Typography variant="h5" gutterBottom>
                      Por favor, desabilite o ADBlock para que o relatório seja
                      emitido.
                    </Typography>
                  </Grid>
                </Grid>
              </DialogContent>
            </form>
          </Dialog>
        </AdBlockDetect>
      </div>
    )
  }
}

export default withStyles(logradouroStyle)(ImoveisRelatorio)

import React from "react"
import { withRouter } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"
import Warning from "@material-ui/icons/Warning"
import Done from "@material-ui/icons/Done"
import AsyncSelect from "../../components/AsyncSelect"
import { search as searchOcupacao, alterarVencimento } from "../../util/ocupacao"
import { showNotification, mapErrors } from "../../util/Constants"
import { withStyles, MenuItem } from "@material-ui/core"
import Card from "../../components/Card/Card"
import CardBody from "../../components/Card/CardBody"
import secaoStyle from "../../assets/css/layout/secaoStyle"
import Button from "../../components/CustomButtons/Button"
import { showWaiting, hideWaiting } from "../../util/Constants"
import housecall from "housecall"
import moment from "moment"
import CardFooter from "../../components/Card/CardFooter"
import SearchFields from "../../components/SearchFields"

class AlterarVencDamOc extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      dams_ocupacao: [],
      dams: "",
      id_dam: "",
      id_dam_ocupacao: "",
      data_vencimentoNova: new Date(),
      data_vencimentoAntiga: new Date()
    }
    this.handleChange = this.handleChange.bind(this)
    this.loadOcup = this.loadOcup.bind(this)
    this.getDamById = this.getDamById.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let response = error => {
      if (error) {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        hideWaiting()
        showNotification(
          "top",
          "center",
          "Data de vencimento alterada realizada com sucesso",
          "success"
        )
        this.props.history.push("/ocupacao")
      }
    }
    alterarVencimento(
      this.state.id_dam_ocupacao,
      moment(this.state.data_vencimentoNova).format("YYYY-MM-DD"),
      response
    )
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined
    this.setState(
      {
        [name]: event.target.value,
        errors: errors
      },
      () => {
        if (this.state.id_dam && name === "id_dam") {
          let dam = this.getDamById(this.state.id_dam.value)
          this.setState({
            dams_ocupacao: dam.dams
          })
        }
        if (this.state.id_dam_ocupacao && name === "id_dam_ocupacao") {
          let ocupacao = this.getOcupacaoDam(this.state.id_dam_ocupacao)
          this.setState({
            data_vencimentoAntiga: ocupacao.vencimento,
            lancadoNaDivida: ocupacao.lancado_na_divida,
            pago: ocupacao.pago === true,
            divida_id: ocupacao.divida_id
          })
        }
      }
    )
  }
  getDamById(id) {
    return this.state.dams.filter(item => {
      return id === item.id
    })[0]
  }
  getOcupacaoDam(id) {
    return this.state.dams_ocupacao.filter(item => {
      return id === item.id
    })[0]
  }

  loadOcup(inputValue, callback) {
    this.queue.push(() =>
      searchOcupacao(
        inputValue,
        this.state.filterField || "",
        undefined,
        (error, data) => {
          if (error) {
            showNotification(
              "top",
              "center",
              "Ocorreram erros, verifique sua conexão com a internet",
              "danger"
            )
            callback([])
          } else {
            // let filterDam = data.data.filter(d => {
            //   return d.pago === false
            // })
            let dams = data.data.map(dam => {
              return {
                value: dam.id,
                label: `Código.:${dam.id} - Contribuinte: ${dam.contribuinte
                  .nome_razao_social || ""}, Tipo: ${dam.tipo.nome || ""}, Área: ${dam.area.nome || ""} - Desc: ${dam.descricao || ""}`
              }
            })
            this.setState({
              dams: data.data
            })
            callback(dams)
          }
        }
      )
    )
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do local" },
      { value: "descricao", label: "Nome do local" },
      { value: "contribuinte_id", label: "Código do Contribuinte" },
      { value: "contribuinte_cpf_cnpj", label: "Documento do Contribuinte" },
      { value: "contribuinte_nome_razao_social", label: "Nome/Razão Social do Contribuinte" }
    ]
    return (
      <div className={classes.container}>
        <Card>
          <CardBody>
            <Typography color="textSecondary" variant="caption">
              Altere a data de vencimento Dam de Ocupação de Imóvel
            </Typography>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AsyncSelect
                  className={
                    `form-control form-control-alternative form-select Select-menu-outer ${
                    this.props.error ? "has-danger" : ""
                    }`
                  }
                  value={this.state.id_dam}
                  name="id_dam"
                  onChange={this.handleChange}
                  loadOptions={this.loadOcup}
                  placeholder="Escolha a Ocupação"
                  message="Pesquise o DAM de Ocupação"
                />
                <SearchFields
                  name="filterField"
                  value={this.state.filterField || ""}
                  onChangeFields={this.handleChange}
                  fields={fields}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-select-currency"
                  select
                  name="id_dam_ocupacao"
                  label="DAM de Ocupação"
                  fullWidth
                  value={this.state.id_dam_ocupacao}
                  error={this.state.errors.id_dam_ocupacao}
                  helperText={this.state.errors.id_dam_ocupacao}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                >
                  {this.state.dams_ocupacao.map((option, key) => {
                    return (
                      option.pago ? "" :
                        <MenuItem key={key} value={option.id}>
                          Competência: {option.competencia} Valor: {option.valor}
                        </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="date"
                  label="Data do Vencimento"
                  margin="normal"
                  disabled
                  variant="outlined"
                  type="date"
                  value={moment(this.state.data_vencimentoAntiga).format("YYYY-MM-DD")}
                  fullWidth
                  helperText={this.state.errors.data_vencimentoAntiga}
                  error={this.state.errors.data_vencimentoAntiga}
                  name="data_vencimentoAntiga"
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="date"
                  label="Nova Data de Vencimento"
                  margin="normal"
                  variant="outlined"
                  type="date"
                  fullWidth
                  value={moment(this.state.data_vencimentoNova).format("YYYY-MM-DD")}
                  helperText={this.state.errors.vencimento}
                  error={this.state.errors.vencimento}
                  name="data_vencimentoNova"
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
          </CardBody>
          <CardFooter>
            <Grid container justify="center">
              <Grid item lg={(this.state.lancadoNaDivida || this.state.pago) ? 6 : 2}>
                <Button
                  block
                  color={this.state.pago ? "success" : (this.state.lancadoNaDivida ? "danger" : "info")}
                  round
                  disabled={this.state.pago || this.state.lancadoNaDivida}
                  onClick={this.handleSave}
                >
                  {(this.state.lancadoNaDivida || this.state.pago) ? (


                    this.state.pago ? (<div>
                      <Done /> O DAM Selecionado já está pago
                      </div>
                    ) : (<div>
                      <div>
                        < Warning /> O DAM Selecionado foi lançado na dívida
                      </div>
                      <div> Código da dívida ativa: {this.state.divida_id}</div>
                    </ div >)

                  ) : (
                      <div>
                        <AddIcon /> Alterar
                    </div>
                    )}
                </Button>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>
      </div>
    )
  }
}
AlterarVencDamOc = withRouter(AlterarVencDamOc)
export default withStyles(secaoStyle)(AlterarVencDamOc)
import {
  drawerWidth,
  transition,
  container
} from "../material-dashboard-react"
import bg from "../../img/bg.svg"
const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  cardIconFull: {
    float: "none !important",
    textAlign: "center",
    color:"white",
    width:"100%"
  },
  gridItem: {
    minHeight: 123
  },
  gridItemHeaderOnly: {
    minHeight: 200
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "10px",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    alignItems: "center",
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)"
  },
  container,
  map: {
    marginTop: "70px"
  },
  textCent: {
    justifyContent: "center"
  },
  textCenter: {
    textAlign: "center"
  },
  background: {
    background: `url(${bg}) no-repeat center center fixed`,
    backgroundSize: "cover",
    position: "fixed",
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    left: 0,
    right: 0
  }
})

export default appStyle

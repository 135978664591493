import {
  drawerWidth,
  transition,
  container,
  primaryColor,
  dangerColor,
  warningColor,
  successColor,
  defaultFont,
  roseColor,
  purpleColor,
  grayColor,
  infoColor,
  blackColor,
  secondaryColor
} from "../material-dashboard-react"
import tooltipStyle from "../tooltipStyle"
const appStyle = theme => ({
  ...tooltipStyle,
  warningTableRow: {
    background: "linear-gradient(to right , #ff9900, #FFAB2E)",
    color: "white",
    fontWeight: "bolder"
  },
  primaryTableRow: {
    backgroundColor: primaryColor,
    color: "white"
  },
  legend: {
    padding: "20px 0"
  },
  dangerTableRow: {
    backgroundColor: dangerColor,
    color: "white",
    fontWeight: "bolder"
  },
  successTableRow: {
    background: "linear-gradient(to right , #0ba360, #3cba92)",
    color: "white",
    fontWeight: "bolder"
  },
  infoTableRow: {
    backgroundColor: infoColor,
    color: "white"
  },
  roseTableRow: {
    backgroundColor: roseColor,
    color: "white"
  },
  blackTableRow: {
    backgroundColor: blackColor,
    color: "white"
  },
  secondaryTableRow: {
    backgroundColor: secondaryColor,
    color: "white"
  },
  grayTableRow: {
    backgroundColor: grayColor
  },
  purpleTableRow: {
    backgroundColor: purpleColor,
    color: "white"
  },
  flexInherit: {
    flex: "inherit !important"
  },
  datePicker: {
    display: "flex"
  },
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  root: {
    flexGrow: 1,
    width: "100%",
  },
  painel: {
    height: "100%",
    overflow: "auto"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    alignItems: "center",
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)"
  },
  container,
  map: {
    marginTop: "70px"
  },
  textWarning: {
    color: warningColor + " !important"
  },
  textCent: {
    justifyContent: "center"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "10px",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  tableWrapper: {
    overflowX: "auto"
  },
  edit: {
    backgroundColor: "transparent",
    color: primaryColor,
    boxShadow: "none"
  },
  close: {
    backgroundColor: "transparent",
    color: dangerColor,
    boxShadow: "none"
  },
  tableActions: {
    display: "flex",
    border: "none",
    padding: "12px 8px !important",
    verticalAlign: "middle"
  },
  tableCell: {
    ...defaultFont,
    padding: "8px",
    border: "none",
    verticalAlign: "middle",
    lineHeight: "1.42857143",
    fontSize: "14px"
  },
  tableActionButton: {
    width: "27px",
    height: "27px",
    padding: "0"
  },
  tableActionButtonIcon: {
    width: "17px",
    height: "17px"
  },
  tableRow: {
    position: "relative",
    borderBottom: "1px solid #dddddd"
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    fontSize: "1em"
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: "25%",
    left: "25%",
    margin: "auto",
    transform: "translate(-25%, -25%)",
    padding: theme.spacing.unit * 4
  },
  messageError: {
    color: "#ff5722"
  },
  pagoTema: {
    backgroundColor: successColor,
    color: "#FFFFFF",
    fontWeight: "500",
  },
  dividaTema: {
    backgroundColor: "#F27160",
    color: "#FFFFFF",
    fontWeight: "700",
  },
  andamentoTema: {
    backgroundColor: "#564D82",
    color: "#FFFFFF",
    fontWeight: "700",
  },
  dot: {
    margin: "0 10px",
  },
  dotSuccess: {
    color: successColor
  },
  dotDanger: {
    color: dangerColor
  },
  dotPurple: {
    color: purpleColor
  },
  dotPrimary: {
    color: primaryColor
  },
  dotGray: {
    color: grayColor
  },
  dotRose: {
    color: roseColor
  },
  dotWarning: {
    color: "#FFAB2E"
  },
  dotInfo: {
    color: infoColor
  },
  dotBlack: {
    color: blackColor
  },
  dotSecondary: {
    color: secondaryColor
  },
  nameInside: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    color: "#FFFFFF",
    fontWeight: "600",
  },
  nameTitle: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    color: "#FFFFFF",
    fontWeight: "800",
  },
  rootTab: {
    width: "100%",
    marginTop: 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  rootPaper: {
    flexGrow: 1
  }
})

export default appStyle

import React from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import loginStyle from "../../assets/css/layout/loginStyle"

import Button from "@material-ui/core/Button"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import GridContainer from "../../components/Grid/GridContainer"
import GridItem from "../../components/Grid/GridItem"
import CardIcon from "../../components/Card/CardIcon"
import FileCopy from "@material-ui/icons/FileCopy"
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn"
import { Typography } from "@material-ui/core"

export class Main extends React.Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.state = {
            username: "",
            password: "",
            error: "",
            errors: {}
        }
    }
    toggleLoad() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }
    handleChange(e) {
        let name = e.target.name
        let value = e.target.value
        this.setState({
            [name]: value,
            error: ""
        })
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.content}>
                <div className={classes.background} />
                <div>
                    <form onSubmit={this.ActionLink}>
                        <GridContainer alignItems="center" justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader className={classes.textCenter} color="primary">
                                        <h2 className={classes.cardTitleWhite}>
                                            Seja bem vindo contribuinte!
                                        </h2>
                                        <small className={classes.header}>
                                            O que você procura?
                                        </small>
                                    </CardHeader>
                                    <CardBody>
                                        <GridItem container direction="row" classes={{ grid: classes.gridItem }} >
                                            <GridItem xs={12} sm={12} md={3} >
                                                <Card>
                                                    <CardHeader className={classes.gridItem} icon stats>
                                                        <Button fullWidth onClick={(e) => {
                                                            this.props.history.push("/contribuinte/iptu")
                                                        }}>
                                                            <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="warning">
                                                                <FileCopy />
                                                                <h4 style={{ color: "white" }}>IPTU</h4>
                                                            </CardIcon>
                                                        </Button>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Typography variant="caption">Imprima seu carnê de IPTU</Typography>
                                                    </CardBody>
                                                </Card>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={3} >
                                                <Card>
                                                    <CardHeader className={classes.gridItem} icon stats>
                                                        <Button fullWidth onClick={(e) => {
                                                            this.props.history.push("/contribuinte/cnd")
                                                        }}>
                                                            <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="info">
                                                                <AssignmentTurnedIn />
                                                                <h4 style={{ color: "white" }}>CND</h4>
                                                            </CardIcon>
                                                        </Button>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Typography variant="caption">Emitir certidão negativa de débitos</Typography>
                                                    </CardBody>
                                                </Card>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={3} >
                                                <Card>
                                                    <CardHeader className={classes.gridItem} icon stats>
                                                        <Button fullWidth onClick={(e) => {
                                                            this.props.history.push("/contribuinte/cniptu")
                                                        }}>
                                                            <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="success">
                                                                <AssignmentTurnedIn />
                                                                <h4 style={{ color: "white" }}>CNIPTU</h4>
                                                            </CardIcon>
                                                        </Button>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Typography variant="caption">Emitir certidão negativa de IPTU</Typography>
                                                    </CardBody>
                                                </Card>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={3} >
                                                <Card>
                                                    <CardHeader className={classes.gridItem} icon stats>
                                                        <Button fullWidth onClick={(e) => {
                                                            this.props.history.push("/contribuinte/cpen")
                                                        }}>
                                                            <CardIcon className={" " + classes.cardIconFull + " " + classes.gridItem} color="rose">
                                                                <AssignmentTurnedIn />
                                                                <h4 style={{ color: "white" }}>CPEN</h4>
                                                            </CardIcon>
                                                        </Button>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Typography variant="caption">Emitir certidão positiva com efeito negativo</Typography>
                                                    </CardBody>
                                                </Card>
                                            </GridItem>
                                        </GridItem>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </form>
                </div>
            </div>
        )
    }
}

export default withStyles(loginStyle)(Main)

import axios from "axios"
import {defaultHeaders} from "./Constants"

export function get(page, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + `logradouros${page ? `?page=${page}` : ""}`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function post(params, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "logradouros"
    axios
        .post(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function put(id, params, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "logradouros/" + id
    axios
        .put(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function search(searchText, field, onStart, callBack, pagina) {
    let config = {
        headers: defaultHeaders(),
        data: {a: "a"}
    }
    let url = process.env.REACT_APP_API + `search/logradouros?text=${searchText}&fields=${field}`
    if (pagina != null) {
        url += `&page=${pagina}`
    }
    if (onStart) {
        onStart()
    }
    return axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function getRelatorio(logradouro, order, callBack) {
    let config = {
        headers: defaultHeaders(),
        responseType: "blob"
    }
    let url = process.env.REACT_APP_API + `relatorios/logradouros${order ? `?order=${order}` : ""}${logradouro ? `&text=${logradouro}` : ""}`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}
import React from "react"

import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import MenuItem from "@material-ui/core/MenuItem"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import Radio from "@material-ui/core/Radio"
import AddIcon from "@material-ui/icons/Add"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import AsyncSelect from "../../../components/AsyncSelect"
import MobileStepper from "@material-ui/core/MobileStepper"
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Card from "../../../components/Card/Card"
import CardBody from "../../../components/Card/CardBody"
import secaoStyle from "../../../assets/css/layout/secaoStyle"
import { withStyles, AppBar, Tabs, Tab } from "@material-ui/core"
import Button from "../../../components/CustomButtons/Button"
import { getFields, post, edit, put } from "../../../util/imovel"
import { showNotification, mapErrors, showWaiting, hideWaiting } from "../../../util/Constants"
import { search as searchContribuinte } from "../../../util/contribuinte"
import { search as searchLogradouro } from "../../../util/logradouro"
import { search as searchBairro } from "../../../util/bairro"
import { post as transfDivida } from "../../../util/dividaTrans"
import SearchFields from "../../../components/SearchFields"
import LoadingContent from "../../../components/LoadingContent"

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import theme from "../../../assets/css/theme"
import { getCep } from "../../../util/cep"

import InputMask from "react-input-mask"
import housecall from "housecall"

const tab = [{ name: "Início" }, { name: "Localização imóvel" }, { name: "Imóvel" }, { name: "Informações do Lote" }, { name: "Medidas do Imóvel" }, { name: "Edificação" }, { name: "Unidades" }, { name: "Anotações" }]

class ImoveisForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      value: 0,
      bairros: [],
      //
      distritos: [],
      id_distritos: "",
      //
      naturezas: [],
      id_naturezas: "",
      //
      tipos_de_patrimonio: [],
      id_tipos_de_patrimonio: "",
      //
      posicoes_fiscais: [],
      id_posicoes_fiscais: "",
      //
      adequacoes_para_ocupacao: [],
      id_adequacoes_para_ocupacao: "",
      //
      situacoes_de_lote: [],
      id_situacoes_de_lote: "",
      //
      topografias: [],
      id_topografias: "",
      //
      tipos_de_ocupacao: [],
      id_tipos_de_ocupacao: "",
      //
      tipos_de_edificacao: [],
      id_tipos_de_edificacao: "",
      //
      tipos_de_acabamento: [],
      id_tipos_de_acabamento: "",
      //
      situacao_edificacao: [],
      id_situacao_edificacao: "",
      //
      tipos_de_sanitarios: [],
      id_tipos_de_sanitarios: "",
      //
      tipos_de_estrutura: [],
      id_tipos_de_estrutura: "",
      //
      situacoes_dos_lados: [],
      id_situacoes_dos_lados: "",
      //
      tipos_de_cobertura: [],
      id_tipos_de_cobertura: "",
      //
      classificacoes_arquitetonicas: [],
      id_classificacoes_arquitetonicas: "",
      //
      tipos_de_utilizacao: [],
      id_tipos_de_utilizacao: "",
      //
      estados_de_conservacao: [],
      id_estados_de_conservacao: "",
      //
      tipos_de_piso: [],
      id_tipos_de_piso: "",
      //
      tipos_de_forro: [],
      id_tipos_de_forro: "",
      //
      tipos_de_instalacao_eletrica: [],
      id_tipos_de_instalacao_eletrica: "",
      //
      tipos_de_instalacao_sanitaria: [],
      id_tipos_de_instalacao_sanitaria: "",
      //
      //
      contribuintes: [],
      id_contribuintes: "",
      logradouro_contribuinte: "",
      bairro_contribuinte: "",
      cep_contribuinte: "",
      cidade_contribuinte: "",
      uf_contribuinte: "",
      //
      radioEndereco: "",
      secoesLogradouro: [],
      id_secoes: "",
      id_bairro: "",
      id_logradouro: "",
      logradouros: [],
      cep_imovel: "",
      // loteamentos: [],
      // id_loteamento: 0,
      quadra: "",
      lote: "",
      id_acabamentoInterno: "",
      //
      salas: 0,
      quartos: 0,
      banheiros: 0,
      cozinhas: 0,
      pontos_de_agua: 0,
      inscricao_cartografica: "",
      inscricao_anterior: "",
      anotacoes: "",
      complemento: "",
      testada_principal: "",
      fundos: "",
      area_lote: "",
      area_privativa: "",
      area_comum: "",
      area_total_edificada: "",
      numero: '',
      //id_atributos_especiais: [],
      //atributos_especiais: [],
      tipos_de_parede: [],
      id_tipos_de_parede: "",
      editar_observacao: false,

      modal: false,
      contribuinte_change: false,
      motivo_transf: "",
      shouldOpen: false,
      imov_edit: ""
    }
    this.getFields = this.getFields.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.loadLogradouro = this.loadLogradouro.bind(this)
    this.loadBairro = this.loadBairro.bind(this)
    this.getContribuinteById = this.getContribuinteById.bind(this)
    this.getLogradouroById = this.getLogradouroById.bind(this)
    this.setBooleanValue = this.setBooleanValue.bind(this)
    this.handleTab = this.handleTab.bind(this)
    this.getInfoCep = this.getInfoCep.bind(this)
    this.queue = housecall({ concurrency: 1, cooldown: 0 })
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.transfDivida = this.transfDivida.bind(this)

  }

  componentWillMount() {
    this.getFields()
  }

  handleOpen() {
    this.setState({ modal: true });
  };

  handleClose() {
    this.setState({ modal: false }, () => { this.props.history.push("/iptu/cadastro") });
  };

  handleNext = () => {
    this.setState(state => ({
      value: state.value + 1,
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      value: state.value - 1,
    }));
  };

  loadOptions(inputValue, callback) {
    searchContribuinte(
      inputValue,
      this.state.filterField || "",
      undefined,
      (error, data) => {
        if (error) {
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique sua conexão com a internet",
            "danger"
          )
          callback([])
        } else {

          let contribuintes = data.data.map(contribuinte => {
            return {
              value: contribuinte.id,
              label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                ""} - ${contribuinte.cpf_cnpj || ""}`
            }
          })
          this.setState({
            contribuintes: data.data
          }, () => {
            callback(contribuintes)
          })

        }
      }
    )
  }

  loadLogradouro(inputValue, callback) {
    searchLogradouro(inputValue, undefined, undefined, (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
        callback([])
      } else {
        let lograd = data.data.map(logradouro => {
          return { value: logradouro.id, label: logradouro.nome }
        })
        this.setState({
          logradouros: data.data
        })
        callback(lograd)
      }
    })
  }

  loadBairro(inputValue, callback) {
    searchBairro(inputValue, undefined, undefined, (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )
        callback([])
      } else {
        let bairros = data.data.map(bairro => {
          return {
            value: bairro.id,
            label: bairro.nome
          }
        })
        callback(bairros)
      }
    })
  }

  handleChange(event) {
    let name = event.target.name
    let errors = this.state.errors
    errors[name] = undefined

    this.setState(
      {
        [name]: event.target.value,
        errors: errors
      },
      () => {
        if (
          this.state.radioEndereco === "contribuinte" &&
          this.state.id_contribuintes
        ) {

          let contribuinte = this.getContribuinteById(
            this.state.id_contribuintes.value
          )
          if (contribuinte !== undefined) {

            this.setState({
              logradouro_contribuinte: contribuinte.endereco,
              bairro_contribuinte: contribuinte.bairro,
              cep_contribuinte: contribuinte.cep,
              cidade_contribuinte: contribuinte.cidade,
              uf_contribuinte: contribuinte.uf
            })
          }
        } else if (name === "radioEndereco") {
          this.setState({
            logradouro_contribuinte: "",
            bairro_contribuinte: "",
            cep_contribuinte: "",
            cidade_contribuinte: "",
            uf_contribuinte: ""
          })
        }
        if (this.state.id_logradouro && name === "id_logradouro") {
          let sec = this.getLogradouroById(this.state.id_logradouro.value)
          this.setState({
            secoesLogradouro: sec.secoes
          })
        }
        if (name === "cep_contribuinte") {
          (this.getInfoCep(this.state.cep_contribuinte))
        }
        if (this.props.match.params.id && name === "id_contribuintes") {
          this.setState({ contribuinte_change: true })
        }
      }
    )
  }

  handleTab(event, value) {
    this.setState({ value })
  }

  getContribuinteById(id) {
    return this.state.contribuintes.filter(item => {
      return id === item.id
    })[0]
  }

  getLogradouroById(id) {
    return this.state.logradouros.filter(item => {
      return id === item.id
    })[0]
  }


  mensagemError = (mensagem)=>{
    showNotification(
        "top",
        "center",
        mensagem,
        "danger")
  }

  handleSave(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      inscricao_cartografica: this.state.inscricao_cartografica,
      inscricao_anterior: this.state.inscricao_anterior,
      contribuinte_id: this.state.id_contribuintes.value,
      logradouro_correspondencia: this.state.logradouro_contribuinte,
      bairro_correspondencia: this.state.bairro_contribuinte,
      cep_correspondencia: this.state.cep_contribuinte,
      cidade_correspondencia: this.state.cidade_contribuinte,
      uf_correspondencia: this.state.uf_contribuinte,
      logradouro_id: this.state.id_logradouro.value,
      secao_id: this.state.id_secoes,
      bairro_id: this.state.id_bairro.value,
      distrito_id: this.state.id_distritos,
      quadra: this.state.quadra,
      lote: this.state.lote,
      numero: this.state.numero,
      natureza_id: this.state.id_naturezas,
      tipo_patrimonio_id: this.state.id_tipos_de_patrimonio,
      posicao_fiscal_id: this.state.id_posicoes_fiscais,
      adequacao_para_ocupacao_id: this.state.id_adequacoes_para_ocupacao,
      situacao_lote_id: this.state.id_situacoes_de_lote,
      topografia_id: this.state.id_topografias,
      ocupacao_id: this.state.id_tipos_de_ocupacao,
      testada_principal: this.state.testada_principal,
      fundos: this.state.fundos,
      area_lote: this.state.area_lote,
      area_privativa: this.state.area_privativa,
      area_comum: this.state.area_comum,
      area_total_edificada: this.state.area_total_edificada,
      situacao_edificacao_id: this.state.id_situacao_edificacao,
      tipo_estrutura_id: this.state.id_tipos_de_estrutura,
      situacao_lados_id: this.state.id_situacoes_dos_lados,
      tipo_cobertura_id: this.state.id_tipos_de_cobertura,
      classificacao_arquitetonica_id: this.state.id_classificacoes_arquitetonicas,
      tipo_utilizacao_id: this.state.id_tipos_de_utilizacao,
      estado_conservacao_id: this.state.id_estados_de_conservacao,
      tipo_piso_id: this.state.id_tipos_de_piso,
      tipo_instalacao_eletrica_id: this.state.id_tipos_de_instalacao_eletrica,
      tipo_instalacao_sanitaria_id: this.state.id_tipos_de_instalacao_sanitaria,
      tipo_forro_id: this.state.id_tipos_de_forro,
      tipo_de_parede_id: this.state.id_tipos_de_parede,
      anotacoes: this.state.anotacoes,
      complemento: this.state.complemento,
      complemento_correspondencia: this.state.complemento_correspondencia
    }
    let response = (error, data) => {
      var a = {
        "inscricao_cartografica": " Inscrição cartográfica",
        "contribuinte_id": " Contribuinte",
        "logradouro_correspondencia": " Logradouro da Correspondencia",
        "bairro_correspondencia": " Bairro da Correspondencia",
        "cep_correspondencia": " Cep da Correspondencia",
        "cidade_correspondencia": " Cidade da Correspondencia",
        "uf_correspondencia": " UF da Correspondencia",
        "logradouro_id": " Logradouro do Imovel",
        "numero": " Numero do Imovel",
        "secao_id": " Seção do Imovel",
        "bairro_id": " Bairro do Imovel",
        "distrito_id": " Distrito do Imovel",
        "quadra": " Quadra do Imovel",
        "lote": " Lote do Imovel",
        "natureza_id": " Naturezas do Imovel",
        "tipo_patrimonio_id": " Patrimonio do Imovel",
        "posicao_fiscal_id": " Posição fiscal do Imovel",
        "adequacao_para_ocupacao_id": " Adequeção para ocupação do Lote",
        "situacao_lote_id": " Situação do Lote",
        "topografia_id": " Topografia do Lote",
        "ocupacao_id": " Ocupação do Lote",
        "area_lote": " Área do Lote"
      }
      hideWaiting()
      if (error) {
        let ObjErrors = Object.keys(error.response.data.errors)
        ObjErrors.map(key=>{
          this.mensagemError(a[key] + " " + error.response.data.errors[key].join() )
        })
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          `Imóvel ${
          this.props.match.params.id ? "editado" : "incluído"
          } com sucesso`,
          "success"
        )
        let shouldTra = data.deve_transferir
        if (this.state.id_posicoes_fiscais === 3) {
          this.props.history.push("/iptu/cadastro")
        } else {
          if (this.props.match.params.id && this.state.contribuinte_change === true && shouldTra === true) {
            this.handleOpen()
          } else {
            this.props.history.push("/iptu/cadastro")
          }
        }


      }
    }
    this.props.match.params.id
      ? put(this.props.match.params.id, params, response)
      : post(params, response)
  }

  setBooleanValue(value) {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === "true") return true
      if (value.toLowerCase() === "false") return false
    }
    return value
  }
  toggleLoading() {
    this.setState(state => ({
      isLoading: !state.isLoading
    }))
  }
  getFields() {
    this.toggleLoading()
    let response = (error, data) => {
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique sua conexão com a internet",
          "danger"
        )

      } else {
        this.toggleLoading()
        if (this.props.match.params.id) {
          this.setState({
            editar_observacao: true
          })

          data.imovel.id_bairro = {
            value: data.imovel.bairro.id,
            label: data.imovel.bairro.nome
          }

          data.imovel.id_logradouro = {
            value: data.imovel.logradouro.id,
            label: data.imovel.logradouro.nome
          }

          if (data.imovel.contribuinte != null) {
            data.imovel.id_contribuintes = {
              value: data.imovel.contribuinte.id,
              label: data.imovel.contribuinte.nome_razao_social
            }
          }
          data.imovel.logradouro.secoesLogradouro =
            data.imovel.logradouro.secoes
          data.imovel.logradouro_contribuinte =
            data.imovel.logradouro_correspondencia
          data.imovel.bairro_contribuinte = data.imovel.bairro_correspondencia
          data.imovel.cep_contribuinte = data.imovel.cep_correspondencia
          data.imovel.cidade_contribuinte = data.imovel.cidade_correspondencia
          data.imovel.uf_contribuinte = data.imovel.uf_correspondencia
          data.imovel.id_secoes = data.imovel.secao_id
          data.imovel.id_distritos = data.imovel.distrito_id
          //data.imovel.id_loteamento = data.imovel.loteamento_id
          data.imovel.id_naturezas = data.imovel.natureza_id
          data.imovel.id_tipos_de_patrimonio = data.imovel.tipo_patrimonio_id
          data.imovel.id_tipos_de_ocupacao = data.imovel.ocupacao_id
          data.imovel.id_estados_de_conservacao =
            data.imovel.estado_conservacao_id
          data.imovel.id_tipos_de_piso = data.imovel.tipo_piso_id
          data.imovel.id_tipos_de_forro = data.imovel.tipo_forro_id
          data.imovel.id_situacao_edificacao =
            data.imovel.situacao_edificacao_id
          data.imovel.id_tipos_de_parede = data.imovel.tipo_de_parede_id
          data.imovel.secoesLogradouro = data.imovel.logradouro.secoes
          data.imovel.id_posicoes_fiscais = data.imovel.posicao_fiscal_id

          // delete data.imovel.atributos_especiais
          delete data.imovel.situacao_edificacao
          delete data.imovel.tipos_de_parede
        }

        this.setState({
          distritos: data.distritos,
          // loteamentos: data.loteamentos,
          naturezas: data.naturezas,
          numero: data.numero,
          topografias: data.topografias,
          tipos_de_ocupacao: data.tipos_de_ocupacao,
          situacoes_de_lote: data.situacoes_de_lote,
          situacoes_dos_lados: data.situacoes_dos_lados,
          tipos_de_estrutura: data.tipos_de_estrutura,
          tipos_de_edificacao: data.tipos_de_edificacao,
          tipos_de_acabamento: data.tipos_de_acabamento,
          situacao_edificacao: data.situacao_edificacao,
          tipos_de_parede: data.tipos_de_parede,
          tipos_de_cobertura: data.tipos_de_cobertura,
          posicoes_fiscais: data.posicoes_fiscais,
          tipos_de_patrimonio: data.tipos_de_patrimonio,
          adequacoes_para_ocupacao: data.adequacoes_para_ocupacao,
          classificacoes_arquitetonicas: data.classificacoes_arquitetonicas,
          tipos_de_utilizacao: data.tipos_de_utilizacao,
          estados_de_conservacao: data.estados_de_conservacao,
          tipos_de_piso: data.tipos_de_piso,
          tipos_de_forro: data.tipos_de_forro,
          tipos_de_instalacao_eletrica: data.tipos_de_instalacao_eletrica,
          tipos_de_instalacao_sanitaria: data.tipos_de_instalacao_sanitaria
        })

        let newState = {
          ...data.imovel
        }

        this.setState(newState)
      }
    }
    if (this.props.match.params.id) {
      edit(this.props.match.params.id, response)
    } else {
      getFields(response)
    }
  }

  getInfoCep(cep) {
    getCep(cep, (error, data) => {
      if (data) {
        this.setState({
          logradouro_contribuinte: data.logradouro,
          bairro_contribuinte: data.bairro,
          uf_contribuinte: data.uf,
          complemento: data.complemento,
          cidade_contribuinte: data.localidade
        })
      }
    })
  }

  transfDivida(event) {
    event.preventDefault()
    showWaiting()
    let params = {
      imovel_id: this.props.match.params.id,
      antigo_id: this.state.id_contribuintes.value,
      motivo: this.state.motivo_transf
    }
    let response = error => {
      hideWaiting()
      if (error) {
        showNotification(
          "top",
          "center",
          "Ocorreram erros, verifique o formulário e tente novamente",
          "danger"
        )
        this.setState({
          errors: mapErrors(error)
        })
      } else {
        showNotification(
          "top",
          "center",
          `Dívidas Transferidas com sucesso`,
          "success"
        )
        this.handleClose()

      }
    }
    transfDivida(params, response)
  }

  render() {
    const { classes } = this.props
    const fields = [
      { value: "", label: "Todos os campos" },
      { value: "id", label: "Código do contribuinte" },
      { value: "cpf_cnpj", label: "Documento" },
      { value: "endereco", label: "Endereço" },
      { value: "nome_fantasia", label: "Nome Fantasia" },
      { value: "nome_razao_social", label: "Razão Social" }
    ]
    return (
      <div className={classes.container}>
        <LoadingContent isLoading={this.state.isLoading}>
          <Card>
            <MuiThemeProvider theme={theme}>
              <AppBar position="static">
                <Tabs
                  value={this.state.value}
                  onChange={this.handleTab}
                  variant="scrollable"
                  scrollButtons="auto">
                  {tab.map(item => (
                    <Tab label={item.name} />
                  ))}
                </Tabs>
              </AppBar>
              <CardBody>
                <form onSubmit={this.handleSave}>
                  {this.state.value === 0 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <InputMask
                            mask="99999999999999"
                            value={this.state.inscricao_cartografica}
                            onChange={this.handleChange}
                          >
                            {() => (
                              <TextField
                                id="outlined-name"
                                label="Inscrição Cartográfica"
                                name="inscricao_cartografica"
                                required
                                fullWidth
                                value={this.state.inscricao_cartografica}
                                onChange={this.handleChange}
                                error={this.state.errors.inscricao_cartografica}
                                helperText={this.state.errors.inscricao_cartografica}
                                margin="normal"
                                variant="outlined"
                              />
                            )}
                          </InputMask>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="outlined-name"
                            label="Inscrição Anterior"
                            name="inscricao_anterior"
                            type="number"
                            fullWidth
                            value={this.state.inscricao_anterior}
                            onChange={this.handleChange}
                            error={this.state.errors.inscricao_anterior}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                        <TextField
                          id="outlined-select-currency"
                          select
                          required
                          name="id_status"
                          label="Situação"
                          fullWidth
                          value={this.state.id_status}
                          onChange={this.handleChange}
                          margin="normal"
                          variant="outlined"
                        >
                          {this.state.status.map((option, key) => (
                            <MenuItem key={key} value={option.id}>
                              {option.nome}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid> */}
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography color="textSecondary" variant="caption">
                            Pesquise o Contribuinte
                        </Typography>
                          <SearchFields
                            name="filterField"
                            value={this.state.filterField || ""}
                            onChangeFields={this.handleChange}
                            fields={fields}
                          />
                          <AsyncSelect
                            className={
                              `form-control form-control-alternative form-select Select-menu-outer ${
                              this.props.error ? "has-danger" : ""
                              }`
                            }
                            value={this.state.id_contribuintes}
                            onChange={this.handleChange}
                            loadOptions={this.loadOptions}
                            name="id_contribuintes"
                            placeholder="Contribuinte"
                            message="Pesquise o contribuinte"
                            error={this.state.errors.contribuinte_id}
                            helperText={this.state.errors.contribuinte_id}
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Divider />
                        <Typography color="textSecondary" variant="caption">
                          Endereço de correspondência
                      </Typography>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <RadioGroup
                            aria-label="position"
                            name="radioEndereco"
                            value={this.state.radioEndereco}
                            onChange={this.handleChange}
                            row
                          >
                            <FormControlLabel
                              value="contribuinte"
                              control={<Radio color="primary" />}
                              label="Contribuinte"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="imovel"
                              control={<Radio color="primary" />}
                              label="Imóvel"
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="outlined-name"
                            label="Logradouro"
                            name="logradouro_contribuinte"
                            required
                            fullWidth
                            value={this.state.logradouro_contribuinte}
                            onChange={this.handleChange}
                            error={this.state.errors.logradouro_correspondencia}
                            helperText={this.state.errors.logradouro_correspondencia}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="outlined-name"
                            label="Bairro"
                            name="bairro_contribuinte"
                            required
                            fullWidth
                            value={this.state.bairro_contribuinte}
                            onChange={this.handleChange}
                            error={this.state.errors.bairro_correspondencia}
                            helperText={this.state.errors.bairro_correspondencia}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-name"
                            label="CEP"
                            name="cep_contribuinte"
                            required
                            fullWidth
                            value={this.state.cep_contribuinte}
                            onChange={this.handleChange}
                            error={this.state.errors.cep_correspondencia}
                            helperText={this.state.errors.cep_correspondencia}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-name"
                            label="Cidade"
                            name="cidade_contribuinte"
                            required
                            fullWidth
                            value={this.state.cidade_contribuinte}
                            onChange={this.handleChange}
                            error={this.state.errors.cidade_correspondencia}
                            helperText={this.state.errors.cidade_correspondencia}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <TextField
                            id="outlined-name"
                            label="UF"
                            name="uf_contribuinte"
                            required
                            fullWidth
                            value={this.state.uf_contribuinte}
                            onChange={this.handleChange}
                            error={this.state.errors.uf_correspondencia}
                            helperText={this.state.errors.uf_correspondencia}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <TextField
                            id="outlined-name"
                            label="Complemento"
                            name="complemento"
                            fullWidth
                            value={this.state.complemento || ""}
                            onChange={this.handleChange}
                            error={this.state.errors.complemento}
                            helperText={this.state.errors.complemento}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {this.state.value === 1 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <AsyncSelect
                            className={`form-control form-control-alternative form-select Select-menu-outer ${
                              this.props.error ? "has-danger" : ""
                              }`}
                            style={{ position: "relative" }}
                            value={this.state.id_logradouro}
                            onChange={this.handleChange}
                            loadOptions={this.loadLogradouro}
                            error={this.state.errors.logradouro_id}
                            helperText={this.state.errors.logradouro_id}
                            name="id_logradouro"
                            placeholder="Logradouro"
                            message="Pesquise o logradouro"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <AsyncSelect
                            className={
                              `form-control form-control-alternative form-select Select-menu-outer ${
                              this.props.error ? "has-danger" : ""
                              }`
                            }
                            value={this.state.id_bairro}
                            onChange={this.handleChange}
                            loadOptions={this.loadBairro}
                            name="id_bairro"
                            helperText={this.state.errors.bairro_id}
                            error={this.state.errors.bairro_id}
                            placeholder="Bairro"
                            message="Pesquise o bairro"
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_secoes"
                            label="Seção"
                            fullWidth
                            disabled={!(this.state.id_logradouro)}
                            value={this.state.id_secoes}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                            error={this.state.errors.secao_id}
                            helperText={this.state.errors.secao_id}
                          >
                            {this.state.secoesLogradouro.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.secao}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="cep_imovel"
                            label="CEP"
                            maxLength={8}
                            fullWidth
                            value={this.state.cep_imovel}
                            error={this.state.errors.cep}
                            helperText={this.state.errors.cep}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_distritos"
                            label="Distrito"
                            fullWidth
                            value={this.state.id_distritos}
                            error={this.state.errors.distrito_id}
                            helperText={this.state.errors.distrito_id}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.distritos.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="quadra"
                            label="Quadra"
                            type="number"
                            fullWidth
                            value={this.state.quadra}
                            error={this.state.errors.quadra}
                            helperText={this.state.errors.quadra}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="numero"
                            label="Nº"
                            fullWidth
                            value={this.state.numero}
                            onChange={this.handleChange}
                            error={this.state.errors.numero}
                            helperText={this.state.errors.numero}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="lote"
                            label="Lote"
                            type="number"
                            fullWidth
                            value={this.state.lote}
                            onChange={this.handleChange}
                            error={this.state.errors.lote}
                            helperText={this.state.errors.lote}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {this.state.value === 2 && (
                    <div>

                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_naturezas"
                            label="Naturezas"
                            error={this.state.errors.natureza_id}
                            helperText={this.state.errors.natureza_id}
                            fullWidth
                            value={this.state.id_naturezas}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.naturezas.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_patrimonio"
                            label="Patrimônio"
                            fullWidth
                            error={this.state.errors.tipo_patrimonio_id}
                            helperText={this.state.errors.tipo_patrimonio_id}
                            value={this.state.id_tipos_de_patrimonio}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_patrimonio.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_posicoes_fiscais"
                            label="Posição Fiscal"
                            fullWidth
                            error={this.state.errors.posicao_fiscal_id}
                            helperText={this.state.errors.posicao_fiscal_id}
                            value={this.state.id_posicoes_fiscais}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.posicoes_fiscais.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {this.state.value === 3 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_adequacoes_para_ocupacao"
                            label="Adequações p/ Ocupação"
                            fullWidth
                            error={this.state.errors.adequacao_para_ocupacao_id}
                            helperText={this.state.errors.adequacao_para_ocupacao_id}
                            value={this.state.id_adequacoes_para_ocupacao}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.adequacoes_para_ocupacao.map(
                              (option, key) => (
                                <MenuItem key={key} value={option.id}>
                                  {option.nome || "Nome não cadastrado"}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_situacoes_de_lote"
                            label="Situação do Lote"
                            fullWidth
                            error={this.state.errors.situacao_lote_id}
                            helperText={this.state.errors.situacao_lote_id}
                            value={this.state.id_situacoes_de_lote}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.situacoes_de_lote.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_topografias"
                            label="Topografias"
                            fullWidth
                            error={this.state.errors.topografia_id}
                            helperText={this.state.errors.topografia_id}
                            value={this.state.id_topografias}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.topografias.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_ocupacao"
                            label="Ocupação"
                            fullWidth
                            error={this.state.errors.ocupacao_id}
                            helperText={this.state.errors.ocupacao_id}
                            value={this.state.id_tipos_de_ocupacao}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_ocupacao.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {this.state.value === 4 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="testada_principal"
                            label="Testada Principal"
                            type="number"
                            fullWidth
                            error={this.state.errors.testada_principal}
                            value={parseFloat(this.state.testada_principal).toFixed(0)}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="fundos"
                            label="Fundos"
                            type="number"
                            fullWidth
                            error={this.state.errors.fundos}
                            helperText={this.state.errors.fundos}
                            value={parseFloat(this.state.fundos).toFixed(0)}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="area_lote"
                            label="Área do Lote"
                            type="number"
                            fullWidth
                            error={this.state.errors.area_lote}
                            helperText={this.state.errors.area_lote}
                            value={parseFloat(this.state.area_lote).toFixed(2)}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="area_privativa"
                            label="Área Privativa"
                            fullWidth
                            type="number"
                            error={this.state.errors.area_privativa}
                            helperText={this.state.errors.area_privativa}
                            value={parseFloat(this.state.area_privativa).toFixed(2)}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="area_comum"
                            label="Área Comum"
                            type="number"
                            fullWidth
                            error={this.state.errors.area_comum}
                            helperText={this.state.errors.area_comum}
                            value={parseFloat(this.state.area_comum).toFixed(2)}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            required
                            name="area_total_edificada"
                            label="Área Total Edificada"
                            type="number"
                            fullWidth
                            error={this.state.errors.area_total_edificada}
                            helperText={this.state.errors.area_total_edificada}
                            value={parseFloat(this.state.area_total_edificada).toFixed(2)}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {this.state.value === 5 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_situacao_edificacao"
                            label="Situação da Edificação"
                            fullWidth
                            error={this.state.errors.situacao_edificacao_id}
                            helperText={this.state.errors.situacao_edificacao_id}
                            value={this.state.id_situacao_edificacao}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.situacao_edificacao.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}

                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_situacoes_dos_lados"
                            label="Tipos (lados)"
                            fullWidth
                            error={this.state.errors.situacao_lados_id}
                            helperText={this.state.errors.situacao_lados_id}
                            value={this.state.id_situacoes_dos_lados}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.situacoes_dos_lados.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_estrutura"
                            label="Estrutura"
                            fullWidth
                            error={this.state.errors.tipo_estrutura_id}
                            helperText={this.state.errors.tipo_estrutura_id}
                            value={this.state.id_tipos_de_estrutura}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_estrutura.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_cobertura"
                            label="Cobertura"
                            fullWidth
                            error={this.state.errors.tipo_cobertura_id}
                            helperText={this.state.errors.tipo_cobertura_id}
                            value={this.state.id_tipos_de_cobertura}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_cobertura.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_parede"
                            label="Tipos de parede"
                            fullWidth
                            error={this.state.errors.tipo_de_parede_id}
                            helperText={this.state.errors.tipo_de_parede_id}
                            value={this.state.id_tipos_de_parede}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_parede.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {this.state.value === 6 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_classificacoes_arquitetonicas"
                            label="Classif. Arquitetônica"
                            fullWidth
                            helperText={this.state.errors.classificacao_arquitetonica_id}
                            error={
                              this.state.errors.classificacao_arquitetonica_id
                            }
                            value={this.state.id_classificacoes_arquitetonicas}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.classificacoes_arquitetonicas.map(
                              (option, key) => (
                                <MenuItem key={key} value={option.id}>
                                  {option.nome || "Nome não cadastrado"}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_utilizacao"
                            label="Utilização"
                            fullWidth
                            error={this.state.errors.tipo_utilizacao_id}
                            helperText={this.state.errors.tipo_utilizacao_id}
                            value={this.state.id_tipos_de_utilizacao}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_utilizacao.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_estados_de_conservacao"
                            label="Conservação"
                            fullWidth
                            error={this.state.errors.estado_conservacao_id}
                            helperText={this.state.errors.estado_conservacao_id}
                            value={this.state.id_estados_de_conservacao}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.estados_de_conservacao.map(
                              (option, key) => (
                                <MenuItem key={key} value={option.id}>
                                  {option.nome || "Nome não cadastrado"}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                      </Grid>
                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_piso"
                            label="Piso"
                            fullWidth
                            error={this.state.errors.tipo_piso_id}
                            helperText={this.state.errors.tipo_piso_id}
                            value={this.state.id_tipos_de_piso}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_piso.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_forro"
                            label="Forro"
                            fullWidth
                            error={this.state.errors.tipo_forro_id}
                            helperText={this.state.errors.tipo_forro_id}
                            value={this.state.id_tipos_de_forro}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_forro.map((option, key) => (
                              <MenuItem key={key} value={option.id}>
                                {option.nome || "Nome não cadastrado"}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" spacing={16}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_instalacao_eletrica"
                            label="Instalação Elétrica"
                            fullWidth
                            error={
                              this.state.errors.tipo_instalacao_eletrica_id
                            }
                            helperText={
                              this.state.errors.tipo_instalacao_eletrica_id
                            }
                            value={this.state.id_tipos_de_instalacao_eletrica}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_instalacao_eletrica.map(
                              (option, key) => (
                                <MenuItem key={key} value={option.id}>
                                  {option.nome || "Nome não cadastrado"}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            required
                            name="id_tipos_de_instalacao_sanitaria"
                            label="Instalação Sanitária"
                            fullWidth
                            error={
                              this.state.errors.tipo_instalacao_sanitaria_id
                            }
                            helperText={
                              this.state.errors.tipo_instalacao_sanitaria_id
                            }
                            value={this.state.id_tipos_de_instalacao_sanitaria}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          >
                            {this.state.tipos_de_instalacao_sanitaria.map(
                              (option, key) => (
                                <MenuItem key={key} value={option.id}>
                                  {option.nome || "Nome não cadastrado"}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                        </Grid>
                      </Grid>

                    </div>
                  )}
                  {this.state.value === 7 && (
                    <div>
                      <Grid container direction="row" spacing={16}>
                        <Typography
                          style={{ marginTop: "2%" }}
                          color="textSecondary"
                          variant="caption"
                        >
                          Realize aqui suas anotações
                      </Typography>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <TextField
                            id="outlined-select-currency"
                            multiline
                            required={this.state.editar_observacao}
                            name="anotacoes"
                            label="Anotações"
                            fullWidth
                            rowsMax={7}
                            error={this.state.errors.anotacoes}
                            value={this.state.anotacoes}
                            onChange={this.handleChange}
                            margin="normal"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center" justify="center">
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Button
                            block
                            color="info"
                            round
                            type="submit"
                          // onClick={this.handleSave}
                          >
                            <AddIcon />{" "}
                            {this.props.match.params.id
                              ? "Atualizar"
                              : "Finalizar"}
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  <MobileStepper
                    variant="progress"
                    steps={8}
                    position="static"
                    activeStep={this.state.value}
                    theme={theme}
                    nextButton={
                      <Button size="small" onClick={this.handleNext} disabled={this.state.value === 7} color="primary">
                        Próximo
              </Button>
                    }
                    backButton={
                      <Button size="small" onClick={this.handleBack} disabled={this.state.value === 0} color="primary">
                        Anterior
              </Button>
                    }
                  />
                </form>
              </CardBody>
            </MuiThemeProvider>
          </Card>
        </LoadingContent>

        <Dialog
          open={this.state.modal}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={this.transfDivida}>
            <DialogTitle id="form-dialog-title">
              Deseja transferir as dívidas para o novo contribuinte?
            </DialogTitle>
            <DialogContent>
              <TextField
                id="outlined-select-currency"
                name="motivo_transf"
                label="Motivo"
                required
                fullWidth
                error={this.state.errors.motivo}
                helperText={this.state.errors.motivo}
                multiline
                rowsMax="3"
                value={this.state.motivo_transf}
                onChange={this.handleChange}
                margin="normal"
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="danger">
                Cancelar
              </Button>
              <Button type="submit" color="success">
                Transferir
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    )
  }
}
export default withStyles(secaoStyle)(ImoveisForm)

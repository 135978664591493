import React from "react"

import {
  showWaiting, 
  hideWaiting, 
  mapErrors, 
  showNotification
} from "../../../util/Constants";

import { 
  withStyles, 
  Typography, 
  Divider 
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import logradouroStyle from "../../../assets/css/layout/logradouroStyle";

import Button from "../../../components/CustomButtons/Button";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import SelectCustom from "../../../components/SelectCustom";
import SearchFields from "../../../components/SearchFields"
import AsyncSelect from "../../../components/AsyncSelect";
import InputMask from "react-input-mask";

import { post } from "../../../util/certidaodivida";
import { getField } from "../../../util/damavulso";
import { search as searchContribuinte } from "../../../util/contribuinte"

class LancamentoCdaForm extends React.Component {
    constructor(props) {
        super(props) 
        this.state = {
            ano: "",
            competencia_inicial: "",
            competencia_final: "",
            errors: {}
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.getReceitas = this.getReceitas.bind(this);
    }

    componentDidMount()
    {
      this.getReceitas()
    }

    getReceitas() {

      let response = (error, data) => {
          if (error) {
              showNotification(
                  "top",
                  "center",
                  "Ocorreram erros, verifique sua conexão com a internet",
                  "danger"
              )
          } else {
              let rec = data.receitas.map(receita => {
                  return {
                      value: receita.id,
                      label: `Nº Receita: ${receita.id} - ${receita.nome}`
                  }
              })
              this.setState({
                  receitas: rec
              })
          }
      }
      getField(response)
    }

    loadOptions = (inputValue, callback) => {

      searchContribuinte(
        inputValue,
        this.state.filterField || "",
        undefined,
        (error, data) => {
          if (error) {
              showNotification(
                  "top",
                  "center",
                  "Ocorreram erros, verifique sua conexão com a internet",
                  "danger"
              )
              callback([])
          } else {
              let contribuintes = data.data.map(contribuinte => {
                  return {
                      value: contribuinte.id,
                      label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                      ""} - ${contribuinte.cpf_cnpj || ""}`
                  }
              })

              this.setState({
                  contribuintes: data.data
              }, () => {

              })
              callback(contribuintes)
          }
        }
      )
    } 

    handleChange(event) {
        let name = event.target.name
        
        let errors = this.state.errors
        errors[name] = undefined
    
        this.setState({
          [name]: event.target.value,
          errors: errors
        })
      }

    handleSave(event) {
      event.preventDefault()
      showWaiting()
      let params = {
          ano: this.state.ano,
          competencia_inicial: this.state.competencia_inicial,
          competencia_final: this.state.competencia_final,
          numero: this.state.numero,
          contribuinte_id: this.state.contribuinte_id ? this.state.contribuinte_id.value : undefined,
          receita_id: this.state.receita_id ? this.state.receita_id.value : undefined,
      }
      
      let response = error => {
      
        if (error) {
          hideWaiting()
          showNotification(
            "top",
            "center",
            "Ocorreram erros, verifique o formulário e tente novamente",
            "danger"
          )
          this.setState({
            errors: mapErrors(error)
          })
        } else {
          hideWaiting()
          showNotification(
            "top",
            "center",
            "Lançamento da Certidão realizada com sucesso",
            "success"
          )
          this.props.history.push("/divida/processamento")
        }
      }
      post(params, response);
    }

    render() {
      const fields = [
          { value: "", label: "Todos os campos" },
          { value: "id", label: "Código do contribuinte" },
          { value: "cpf_cnpj", label: "Documento" },
          { value: "endereco", label: "Endereço" },
          { value: "nome_fantasia", label: "Nome Fantasia" },
          { value: "nome_razao_social", label: "Razão Social" }
      ];
      const { classes } = this.props;
      return (
        <div className={classes.container}>
          <Card>
            <CardHeader color="primary">
              <Grid container direction="row">
                <Grid item lg={10} xs={12}>
                  <h2 className={classes.cardTitleWhite}>
                    Lançamento da Certidão da Dívida Ativa
                  </h2>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <InputMask
                    mask="9999"
                    value={this.state.ano}
                    onChange={this.handleChange}
                  >
                    {() => (
                      <TextField
                        id="outlined-select-currency"
                        name="ano"
                        label="Ano"
                        fullWidth
                        error={this.state.errors.ano}
                        helperText={this.state.errors.ano}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <InputMask
                    mask="9999"
                    value={this.state.competencia_inicial}
                    onChange={this.handleChange}
                  >
                    {() => (
                      <TextField
                        id="outlined-select-currency"
                        name="competencia_inicial"
                        label="Competência Inicial"
                        fullWidth
                        error={this.state.errors.competencia_inicial}
                        helperText={this.state.errors.competencia_inicial}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <InputMask
                    mask="9999"
                    value={this.state.competencia_final}
                    onChange={this.handleChange}
                  >
                    {() => (
                      <TextField
                        id="outlined-select-currency"
                        name="competencia_final"
                        label="Competência Final"
                        fullWidth
                        error={this.state.errors.competencia_final}
                        helperText={this.state.errors.competencia_final}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2}>
                    <TextField
                        id="outlined-select-currency"
                        name="numero"
                        label="Número do livro"
                        fullWidth
                        error={this.state.errors.numero}
                        helperText={this.state.errors.numero}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <SelectCustom
                        value={this.state.receita_id}
                        name="receita_id"
                        isMulti={false}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.handleChange}
                        error={this.state.errors.receita_id}
                        helperText={this.state.errors.receita_id}
                        options={this.state.receitas}
                        placeholder="Receita"
                        message="Pesquise a Receita ou deixe vazio para contemplar todas"
                    />
                </Grid>
              </Grid>
              <Grid container direction="row" style={{ marginTop: "2%" }}>
                  <Divider />
                  <Typography
                      className={classes.dividerFullWidth}
                      color="textSecondary"
                      variant="caption"
                  >
                      Filtro por Contribuinte
                  </Typography>
              </Grid>
              <Grid container direction="row" spacing={16}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <SearchFields
                      name="filterField"
                      value={this.state.filterField || ""}
                      onChangeFields={this.handleChange}
                      fields={fields}
                    />
                    <AsyncSelect
                      className={
                          `form-control form-control-alternative form-select ${
                              this.props.error ? "has-danger" : ""
                          }`
                      }
                      value={this.state.contribuinte_id}
                      onChange={this.handleChange}
                      noResultsText="Nenhum resultado"
                      loadOptions={this.loadOptions}
                      loadingMessage={() => "Carregando..."}
                      error={this.state.errors.contribuinte_id}
                      helperText={this.state.errors.contribuinte_id}
                      name="contribuinte_id"
                      placeholder="Contribuinte"
                      message="Pesquise o contribuinte"
                    />
                </Grid>
              </Grid>
            </CardBody>
            <CardFooter>
            <Grid container justify="center">
              <Grid item lg={2}>
                <Button block color="info" round onClick={this.handleSave}>
                  Lançar
                </Button>
              </Grid>
            </Grid>
            </CardFooter>
          </Card>
        </div>
      )
    }
}

export default withStyles(logradouroStyle)(LancamentoCdaForm)
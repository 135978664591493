export default class Mask {
    static CpfCnpjMask(v) {
        if (v.length < 15) {
            v = v.replace(/\D/g, "")
            v = v.replace(/(\d{3})(\d)/, "$1.$2")
            v = v.replace(/(\d{3})(\d)/, "$1.$2")
            v = v.replace(/(\d{3})(\d{1,2})/, "$1-$2")
            v = v.replace(/(-\d{2})\d+?$/, "$1")
        } else {
            v = v.replace(/\D/g, "")
            v=v.replace(/^(\d{2})(\d)/,"$1.$2")
            v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
            v=v.replace(/\.(\d{3})(\d)/,".$1/$2")
            v=v.replace(/(\d{4})(\d)/,"$1-$2")
            v = v.replace(/(-\d{2})\d+?$/, "$1")
        }
        return v
    }

    static limparMask(val) {
        val = val.replace( " " , "")
        val = val.replace( "." , "")
        val = val.replace( "." , "")
        val = val.replace( "/" , "")
        val = val.replace( "-" , "")
        return val
    }
}
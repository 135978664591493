import React, { Component } from "react"
import { printMany } from "../util/certidaodivida"
import { TextField } from "@material-ui/core"
import {
  hideWaiting,
  showWaiting,
  mapErrors,
  showNotification
} from "../util/Constants"
import Grid from "@material-ui/core/Grid"
import Button from "../components/CustomButtons/Button"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import Dialog from "@material-ui/core/Dialog"
import NumberFormatCustom from "../components/NumberFormatCustom"

export default class PrintCertidoesModal extends Component {
  constructor() {
    super()
    this.handleChange = this.handleChange.bind(this)
    this.printManyCertidoes = this.printManyCertidoes.bind(this)
    this.state = {
      errors: {}
    }
  }
  handleChange(e) {
    let value = e.target.value
    let name = e.target.name
    let errors = { ...this.state.errors }
    delete errors[name]
    this.setState({
      [name]: value,
      errors: errors
    })
  }
  printManyCertidoes(e) {
    e.preventDefault()
    var ano = this.state.ano
    var numeroInicial = this.state.numero_inicial
    var numeroFinal = !this.state.numero_final ? "" : this.state.numero_final
    var valorMinimo = !this.state.valor_minimo ? "" : this.state.valor_minimo
    var valorMaximo = !this.state.valor_maximo ? "" : this.state.valor_maximo
    var livro = this.state.livro
    let response = (error, data) => {
      if (error) {
        hideWaiting()
        const reader = new FileReader()
        reader.addEventListener("loadend", e => {
          const text = e.srcElement.result
          try {
            let error = JSON.parse(text)
            this.setState({
              errors: mapErrors({ response: { data: error } })
            })
          } catch (error) {
            showNotification(
              "top",
              "center",
              "Ocorreram erros, verifique sua conexão e tente novamente",
              "danger"
            )
          }
        })
        reader.readAsText(error.response.data)
      } else {
        hideWaiting()
        var url = URL.createObjectURL(data)
        let tempLink = document.createElement("a")
        tempLink.setAttribute("target", "_blank")
        tempLink.setAttribute("href", `${url}`)
        tempLink.setAttribute("style", "display:none;")
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        this.props.toggleModal()
      }
    }
    showWaiting()
    printMany(ano, numeroInicial, numeroFinal, valorMinimo, valorMaximo, livro, response)
  }
  render() {
    return (
      <Dialog
        style={{ zIndex: "4" }}
        open={this.props.showModal || false}
        onClose={this.props.toggleModal}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={e => this.printManyCertidoes(e)}>
          <DialogTitle id="form-dialog-title">
            Digite o ano e o numero da certidão. PS:Deixar o numero vazio para
            imprimir todas do ano selecionado
          </DialogTitle>
          <DialogContent>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="ano"
                  label="Ano das certidões"
                  required
                  fullWidth
                  error={this.state.errors.ano}
                  helperText={this.state.errors.ano}
                  rowsMax="3"
                  type="numeric"
                  value={this.state.ano || ""}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="livro"
                  label="Livro"
                  required
                  fullWidth
                  error={this.state.errors.livro}
                  helperText={this.state.errors.livro}
                  rowsMax="3"
                  type="numeric"
                  value={this.state.livro || ""}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="numero_inicial"
                  label="Numero inicial"
                  fullWidth
                  required
                  error={this.state.errors.numero_inicial}
                  helperText={this.state.errors.numero_inicial}
                  rowsMax="3"
                  type="numeric"
                  value={this.state.numero_inicial || ""}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="outlined-select-currency"
                  name="numero_final"
                  label="Numero final"
                  fullWidth
                  error={this.state.errors.numero_final}
                  helperText={this.state.errors.numero_final}
                  rowsMax="3"
                  type="numeric"
                  value={this.state.numero_final || ""}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={16}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  required
                  name="valor_minimo"
                  margin="normal"
                  variant="outlined"
                  label="Valor Mínimo"
                  type="text"
                  error={this.state.errors.valor_minimo}
                  helperText={this.state.errors.valor_minimo}
                  rowsMax="3"
                  value={this.state.valor_minimo || ""}
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  // required
                  name="valor_maximo"
                  margin="normal"
                  variant="outlined"
                  label="Valor Máximo"
                  error={this.state.errors.valor_maximo}
                  helperText={this.state.errors.valor_maximo}
                  rowsMax="3"
                  value={this.state.valor_maximo || ""}
                  onChange={this.handleChange}
                  type="text"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.toggleModal} color="danger">
              Cancelar
            </Button>
            <Button type="submit" color="success">
              Gerar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

import React from "react"
import PropTypes from "prop-types"
import IntlCurrencyInput from "react-intl-currency-input"

function getConfig() {
  return {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      }
    }
  }
}
function getConfigSemPrefix() {
  var defaultConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        USD: {
          style: "currency",
          currency: "USdD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      }
    }
  }
  return defaultConfig
}
export function numberSemPrefix(props) {
  const { onChange, defaultValue, name, value, ...other } = props
  return (
      <IntlCurrencyInput

          {...other}

          defaultValue={defaultValue || 0}
          config={{...getConfigSemPrefix()}}
          currency="BRL"
          value={value || 0}
          onChange={(e, value) =>
              onChange({
                target: { value: value, name: name }
              })
          }


      />
  )
}
function NumberFormatCustom(props) {
  const { onChange, defaultValue, name, value, ...other } = props
  return (
    <IntlCurrencyInput
      {...other}
      defaultValue={defaultValue || 0}
      config={getConfig()}
      currency="BRL"
      value={value || 0}
      onChange={(e, value) =>
        onChange({
          target: { value: value, name: name }
        })
      }
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

export default NumberFormatCustom

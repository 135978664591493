import React from "react"

import { relatorioCadastro } from "../../../util/alvara"
import { get } from "../../../util/taxa"
import { search as searchContribuinte } from "../../../util/contribuinte"
import { showNotification, showWaiting, hideWaiting, mapErrors } from "../../../util/Constants"
import housecall from "housecall"
import Button from "../../../components/CustomButtons/Button"
import { withStyles } from "@material-ui/core"
import contribuinteStyle from "../../../assets/css/layout/contribuinteStyle"
import Grid from "@material-ui/core/Grid"
import SearchFields from "../../../components/SearchFields"
import Card from "../../../components/Card/Card"
import CardHeader from "../../../components/Card/CardHeader"
import CardBody from "../../../components/Card/CardBody"
import CardFooter from "../../../components/Card/CardFooter"
import TextField from "@material-ui/core/TextField"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import AsyncSelect from "../../../components/AsyncSelect"
import SelectCustom from "../../../components/SelectCustom"
import LoadingContent from "../../../components/LoadingContent"

class RelatorioCadastro extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            contribuintes: [],
            contribuinte_id: "",
            errors: {},
            options: false,
            taxas: [],
            taxa_id: "",
            endereco: ""
        }
        this.handleChange = this.handleChange.bind(this)
        this.loadOptions = this.loadOptions.bind(this)
        this.getTaxas = this.getTaxas.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.queue = housecall({ concurrency: 1, cooldown: 0 })
    }

    handleChange(event) {
        let name = event.target.name
        let errors = this.state.errors
        let value = event.target.value
        errors[name] = undefined
        if (name === "options") {
            value = event.target.checked
        }

        this.setState(state => ({
            [name]: value,
            errors: { ...state.errors, [name]: undefined }
        }))
    }

    loadOptions(inputValue, callback) {
        searchContribuinte(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let contribuintes = data.data.map(contribuinte => {
                        return {
                            value: contribuinte.id,
                            label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                                ""} - ${contribuinte.cpf_cnpj || ""}`
                        }
                    })

                    this.setState({
                        contribuintes: data.data
                    }, () => {

                    })
                    callback(contribuintes)
                }
            }
        )
    }

    componentWillMount() {
        this.getTaxas()
    }

    getTaxas() {
        this.toggleLoading()
        let response = (error, data) => {
            this.toggleLoading()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
                this.setState({
                    errors: mapErrors(error)
                })
            } else {
                console.log("data rec ", data)
                let tax = data.map(taxa => {
                    return {
                        value: taxa.id,
                        label: `Nº : ${taxa.id} - ${taxa.descricao}`
                    }
                })
                this.setState({
                    taxas: tax
                }, () => { console.log("taxa: ", this.state.taxas) })
            }
        }
        get(1, response)
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    handleSave(event) {
        event.preventDefault()
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique o formulário e tente novamente",
                    "danger"
                )
            } else {
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        relatorioCadastro(this.state.taxa_id ? this.state.taxa_id.value : undefined, this.state.contribuinte_id ? this.state.contribuinte_id.value : undefined, this.state.endereco ? this.state.endereco : undefined, response)
    }

    render() {
        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código do contribuinte" },
            { value: "cpf_cnpj", label: "Documento" },
            { value: "endereco", label: "Endereço" },
            { value: "nome_fantasia", label: "Nome Fantasia" },
            { value: "nome_razao_social", label: "Razão Social" }
        ]
        return (
            <div className={classes.container}>
                <LoadingContent isLoading={this.state.isLoading}>
                    <Card>
                        <CardHeader color="primary">
                            <Grid container direction="row">
                                <Grid item lg={10} xs={12}>
                                    <h2 className={classes.cardTitleWhite}>Relatório de Cadastro de Alvará</h2>
                                </Grid>
                            </Grid>
                        </CardHeader>
                        <CardBody>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.state.options}
                                            name="options"
                                            onChange={this.handleChange}
                                            value={true}
                                        />
                                    }
                                    label="Ativar Filtros"
                                />
                            </FormGroup>
                            <Grid container direction="row" style={{ marginTop: "2%" }}>
                                <Divider />
                                <Typography
                                    className={classes.dividerFullWidth}
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Para relatório geral, não adicione os filtros
                            </Typography>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SelectCustom
                                        name="taxa_id"
                                        disabled={!this.state.options}
                                        isMulti={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={this.handleChange}
                                        error={this.state.errors.taxa_id}
                                        helperText={this.state.errors.taxa_id}
                                        options={this.state.taxas}
                                        placeholder="Taxas"
                                        message="Pesquise a Taxa"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" style={{ marginTop: "2%" }}>
                                <Divider />
                                <Typography
                                    className={classes.dividerFullWidth}
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Filtro por endereço
                            </Typography>
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        disabled={!this.state.options}
                                        name="endereco"
                                        label="Endereço"
                                        fullWidth
                                        value={this.state.endereco}
                                        error={this.state.errors.endereco}
                                        helperText={this.state.errors.endereco}
                                        onChange={this.handleChange}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction="row" style={{ marginTop: "2%" }}>
                                <Divider />
                                <Typography
                                    className={classes.dividerFullWidth}
                                    color="textSecondary"
                                    variant="caption"
                                >
                                    Filtro por Contribuinte
                            </Typography>
                            </Grid>
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SearchFields
                                        name="filterField"
                                        value={this.state.filterField || ""}
                                        onChangeFields={this.handleChange}
                                        fields={fields}
                                    />
                                    <AsyncSelect
                                        className={
                                            `form-control form-control-alternative form-select ${
                                            this.props.error ? "has-danger" : ""
                                            }`
                                        }
                                        disabled={!this.state.options}
                                        value={this.state.contribuinte_id}
                                        onChange={this.handleChange}
                                        noResultsText="Nenhum resultado"
                                        loadOptions={this.loadOptions}
                                        loadingMessage={() => "Carregando..."}
                                        error={this.state.errors.contribuinte_id}
                                        helperText={this.state.errors.contribuinte_id}
                                        name="contribuinte_id"
                                        placeholder="Contribuinte"
                                        message="Pesquise o contribuinte"
                                    />
                                </Grid>
                            </Grid>
                        </CardBody>
                        <CardFooter>
                            <Grid container justify="center">
                                <Grid item lg={2}>
                                    <Button block color="info" round onClick={this.handleSave}>
                                        Gerar
                                </Button>
                                </Grid>
                            </Grid>
                        </CardFooter>
                    </Card>
                </LoadingContent>
            </div>
        )
    }
}

export default withStyles(contribuinteStyle)(RelatorioCadastro)
import React from "react"
import { search as searchContribuinte } from "../../util/contribuinte"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import AsyncSelect from "../../components/AsyncSelect"
import SearchFields from "../../components/SearchFields"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Card from "../../components/Card/Card"
import CardHeader from "../../components/Card/CardHeader"
import CardBody from "../../components/Card/CardBody"
import CardFooter from "../../components/Card/CardFooter"
import Button from "../../components/CustomButtons/Button"
import { getField } from "../../util/damavulso"
import FormLabel from "@material-ui/core/FormLabel"
import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import moment from "moment"
import SelectCustom from "../../components/SelectCustom"
import secaoStyle from "../../assets/css/layout/secaoStyle"
import { withStyles } from "@material-ui/core"
import {getFiltros, relatorioArrecadacao} from "../../util/relatoriogeral"
import housecall from "housecall"
import {
    showNotification,
    mapErrors,
    showWaiting,
    hideWaiting
} from "../../util/Constants"

class ArrecadacaoReceita extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data_inicial: new Date(),
            data_final: new Date(),
            receitas: [],
            agentes_recebedores:[],
            agente_recebedor:"",
            receita_id: "",
            filter_by: "data_deposito",
            pagamento: false,
            deposito: true,
            modelo: "resumo",
            errors: {}
        }
        this.getData = this.getData.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.toggleLoading = this.toggleLoading.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.loadOptions = this.loadOptions.bind(this)
        this.queue = housecall({ concurrency: 1, cooldown: 0 })
    }

    getData() {
        this.toggleLoading()
        let response = (error, data) => {
            this.toggleLoading()
            if (error) {
                showNotification(
                    "top",
                    "center",
                    "Ocorreram erros, verifique sua conexão com a internet",
                    "danger"
                )
            } else {
                let rec = data.receitas.map(receita => {
                    return {
                        value: receita.id,
                        label: `Nº Receita: ${receita.id} - ${receita.nome}`
                    }
                })
                let agentesRecebedores = data.agentes_recebedores.map(agente => {
                    return {
                        value: agente.id,
                        label: agente.nome
                    }
                })
                this.setState({
                    receitas: rec,
                    agentes_recebedores:agentesRecebedores
                })
            }
        }
        getFiltros(response)
    }
    loadOptions(inputValue, callback) {
        searchContribuinte(
            inputValue,
            this.state.filterField || "",
            undefined,
            (error, data) => {
                if (error) {
                    showNotification(
                        "top",
                        "center",
                        "Ocorreram erros, verifique sua conexão com a internet",
                        "danger"
                    )
                    callback([])
                } else {
                    let contribuintes = data.data.map(contribuinte => {
                        return {
                            value: contribuinte.id,
                            label: `${contribuinte.id} - ${contribuinte.nome_razao_social ||
                                ""} - ${contribuinte.cpf_cnpj || ""}`
                        }
                    })

                    this.setState({
                        contribuintes: data.data
                    }, () => {

                    })
                    callback(contribuintes)
                }
            }
        )
    }
    handleChange(event) {
        let name = event.target.name
        let value = event.target.value
        this.setState(state => ({
            [name]: value,
            errors: { ...state.errors, [name]: undefined }
        }))

    }

    componentWillMount() {
        this.getData()
    }

    toggleLoading() {
        this.setState(state => ({
            isLoading: !state.isLoading
        }))
    }

    handleSave(event) {
        event.preventDefault()
        showWaiting()
        let response = (error, data) => {
            hideWaiting()
            if (error) {
                const reader = new FileReader()
                reader.addEventListener("loadend", e => {
                    const text = e.srcElement.result
                    try {
                        let error = JSON.parse(text)
                        var ob = {
                            response: {
                                data: error
                            }
                        }
                        this.setState({
                            errors: mapErrors(ob)
                        })
                    } catch (e) {
                        console.log(e)
                        showNotification(
                            "top",
                            "center",
                            `Ocorreram erros! ${error.message}`,
                            "danger"
                        )
                    }
                })
                reader.readAsText(error.response.data)

            } else {
                var url = URL.createObjectURL(data)
                let tempLink = document.createElement("a")
                tempLink.setAttribute("target", "_blank")
                tempLink.setAttribute("href", `${url}`)
                tempLink.setAttribute("style", "display:none;")
                document.body.appendChild(tempLink)
                tempLink.click()
                document.body.removeChild(tempLink)
            }
        }
        relatorioArrecadacao(
            moment(this.state.data_inicial).format("YYYY-MM-DD"),
            moment(this.state.data_final).format("YYYY-MM-DD"),
            this.state.receita_id ? this.state.receita_id.value : undefined,
            this.state.agente_recebedor_id ? this.state.agente_recebedor_id.value : "",
            this.state.contribuinte_id ? this.state.contribuinte_id.value : undefined,

            this.state.filter_by,
            this.state.modelo,
            response)
    }

    render() {
        const { classes } = this.props
        const fields = [
            { value: "", label: "Todos os campos" },
            { value: "id", label: "Código do contribuinte" },
            { value: "cpf_cnpj", label: "Documento" },
            { value: "endereco", label: "Endereço" },
            { value: "nome_fantasia", label: "Nome Fantasia" },
            { value: "nome_razao_social", label: "Razão Social" }
        ]
        return (
            <div className={classes.container}>
                <Card>
                    <CardHeader color="primary">
                        <Grid container direction="row">
                            <Grid item lg={10} xs={12}>
                                <h2 className={classes.cardTitleWhite}>
                                    Arrecadação
                                </h2>
                            </Grid>
                        </Grid>
                    </CardHeader>
                    <CardBody>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormLabel component="legend">
                                    Selecione o Modelo para relatório
                                    </FormLabel>
                                <FormControl
                                    component="fieldset"
                                    className={classes.formControl}
                                >
                                    <FormGroup row>
                                        <RadioGroup
                                            aria-label="position"
                                            name="modelo"
                                            value={this.state.modelo}
                                            onChange={this.handleChange}
                                            row
                                        >
                                            <FormControlLabel
                                                value="resumo"
                                                control={<Radio color="primary" />}
                                                label="Resumo"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="receita"
                                                control={<Radio color="primary" />}
                                                label="Agrupado por Receita"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="agente"
                                                control={<Radio color="primary" />}
                                                label="Agrupado por Agente"
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="contribuinte"
                                                control={<Radio color="primary" />}
                                                label="Agrupado por Contribuinte"
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormLabel component="legend">
                                    Selecione o filtro para relatório
                                    </FormLabel>
                                <FormControl
                                    component="fieldset"
                                    className={classes.formControl}
                                >
                                    <FormGroup row>
                                        <RadioGroup
                                            aria-label="position"
                                            name="filter_by"
                                            value={this.state.filter_by}
                                            onChange={this.handleChange}
                                            row
                                        >
                                            <FormControlLabel
                                                value="data_deposito"
                                                control={<Radio color="primary" />}
                                                label="Data Depósito"
                                                error={this.state.errors.filter_by}
                                                helperText={this.state.errors.filter_by}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="data_pagamento"
                                                control={<Radio color="primary" />}
                                                label="Data Pagamento"
                                                labelPlacement="end"
                                            />
                                        </RadioGroup>
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" style={{ marginTop: "2%" }}>
                            <Divider />
                            <Typography
                                className={classes.dividerFullWidth}
                                color="textSecondary"
                                variant="caption"
                            >
                                Filtro por Receita e Agente Recebedor
                            </Typography>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <SelectCustom
                                    value={this.state.id_imovel}
                                    name="receita_id"
                                    isMulti={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={this.handleChange}
                                    error={this.state.errors.receita_id}
                                    helperText={this.state.errors.receita_id}
                                    options={this.state.receitas}
                                    placeholder="Receita"
                                    message="Pesquise a Receita"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <SelectCustom
                                    value={this.state.agente_recebedor_id}
                                    name="agente_recebedor_id"
                                    isMulti={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={this.handleChange}
                                    error={this.state.errors.agente_recebedor_id}
                                    helperText={this.state.errors.agente_recebedor_id}
                                    options={this.state.agentes_recebedores}
                                    placeholder="Agente Recebedor"
                                    message="Pesquise o agente recebedor"
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" style={{ marginTop: "2%" }}>
                            <Divider />
                            <Typography
                                className={classes.dividerFullWidth}
                                color="textSecondary"
                                variant="caption"
                            >
                                Filtro por Contribuinte
                            </Typography>
                        </Grid>
                        <Grid container direction="row" spacing={16}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <SearchFields
                                    name="filterField"
                                    value={this.state.filterField || ""}
                                    onChangeFields={this.handleChange}
                                    fields={fields}
                                />
                                <AsyncSelect
                                    className={
                                        `form-control form-control-alternative form-select ${
                                        this.props.error ? "has-danger" : ""
                                        }`
                                    }
                                    value={this.state.contribuinte_id}
                                    onChange={this.handleChange}
                                    noResultsText="Nenhum resultado"
                                    loadOptions={this.loadOptions}
                                    loadingMessage={() => "Carregando..."}
                                    error={this.state.errors.contribuinte_id}
                                    helperText={this.state.errors.contribuinte_id}
                                    name="contribuinte_id"
                                    placeholder="Contribuinte"
                                    message="Pesquise o contribuinte"
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row" style={{ marginTop: "2%" }} spacing={16}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    type="date"
                                    name="data_inicial"
                                    label="Data Inicial"
                                    fullWidth
                                    error={this.state.errors.data_inicial}
                                    helperText={this.state.errors.data_inicial}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue={moment(this.state.data_inicial).format("YYYY-MM-DD")}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <TextField
                                    id="outlined-select-currency"
                                    type="date"
                                    name="data_final"
                                    label="Data Final"
                                    fullWidth
                                    error={this.state.errors.data_final}
                                    helperText={this.state.errors.data_final}
                                    onChange={this.handleChange}
                                    margin="normal"
                                    variant="outlined"
                                    defaultValue={moment(this.state.data_final).format("YYYY-MM-DD")}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                    </CardBody>
                    <CardFooter>
                        <Grid container justify="center">
                            <Grid item lg={2}>
                                <Button block color="info" round onClick={this.handleSave}>
                                    Gerar
                            </Button>
                            </Grid>
                        </Grid>
                    </CardFooter>
                </Card>
            </div>
        )
    }
}

export default withStyles(secaoStyle)(ArrecadacaoReceita)

import axios from "axios"
import {defaultHeaders} from "./Constants"

export function get(page, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + `pendencias${page ? `?page=${page}` : ""}`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}


export function put(id, params, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + "pendencias/" + id
    axios
        .put(url, params, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function edit(id, callBack) {
    let config = {
        headers: defaultHeaders()
    }
    let url = process.env.REACT_APP_API + `pendencias/${id}/edit`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}

export function search(searchText, field, onStart, callBack, pagina) {
    let config = {
        headers: defaultHeaders(),
        data: {a: "a"}
    }
    let url =
        process.env.REACT_APP_API +
        `search/pendencias?text=${searchText}&fields=${field}`
    if (pagina != null) {
        url += `&page=${pagina}`
    }
    if (onStart) {
        onStart()
    }
    return axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}


export function getRelatorio(dataInicial, dataFinal, exibirResolvidos, callBack) {
    let config = {
        headers: defaultHeaders(),
        responseType: "blob"
    }
    let url = process.env.REACT_APP_API + `relatorios/pendencias?exibir_resolvidos=${exibirResolvidos}${dataInicial ? `&data_inicial=${dataInicial}` : ""}${dataFinal ? `&data_final=${dataFinal}` : ""}`
    axios
        .get(url, config)
        .then(response => {
            callBack(undefined, response.data)
        })
        .catch(error => {
            callBack(error)
        })
}
import axios from "axios"
import { defaultHeaders } from "./Constants"

export function postAgua(id, observacao, callBack) {
    let headers = defaultHeaders()
    let config = {
      headers: headers,
      responseType: "blob"
    }
    let url = process.env.REACT_APP_API + `certidaoDeAgua?contribuinte_id=${id}&observacao=${observacao}` 
    axios
      .get(url, config)
      .then(response => {
        callBack(undefined, response.data)
      })
      .catch(error => {
        callBack(error)
      })
  }